import Joi from 'joi';

const postTemplateSchema = Joi.object({
  title: Joi.string().required(),
  content: Joi.string().required(),
  weight: Joi.number().required(),
  example: Joi.string().allow(''), // Allow the string to be empty
  article: Joi.boolean(),
  published: Joi.boolean(),
  socialNetworks: Joi.object().pattern(Joi.string(), Joi.boolean()).required(), // New field for social networks
});

export { postTemplateSchema };
