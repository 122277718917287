import { TopicItem } from './TopicItem';
import { TOPIC_ITEM_HEIGHT, TOPIC_GAP } from './constants';
import { AnimatePresence, motion } from 'framer-motion';
import { useMemo } from 'react';
import { useTopicSelectionContext } from './TopicSelectionContext';
import { CheckCircle, Circle, Trash2 } from 'lucide-react';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';

/**
 * Props for the TopicsList component
 * @interface TopicsListProps
 * @property {boolean} isInitialRender - Controls initial animation behavior
 * @property {number} maxSelectedTopics - Maximum number of allowed topics
 */
type TopicsListProps = {
  isInitialRender: boolean;
  maxSelectedTopics: number;
};

/**
 * TopicsList Component
 *
 * Displays and manages a dynamic list of selected topics with animations,
 * accessibility features, and bulk actions.
 *
 * Features:
 * - Animated topic entries and exits
 * - Dynamic height adjustments
 * - Empty state handling
 * - Bulk removal capability
 * - ARIA live region for accessibility
 * - Topic limit indicator
 *
 * @component
 * @param {TopicsListProps} props - Component props
 * @returns {JSX.Element} Rendered TopicsList component
 *
 * @example
 * ```tsx
 * <TopicsList
 *   isInitialRender={false}
 *   maxSelectedTopics={5}
 * />
 * ```
 */
export function TopicsList({ isInitialRender, maxSelectedTopics }: TopicsListProps) {
  const { selectedTopics, removeSelectedTopic, removeAllSelectedTopics } = useTopicSelectionContext();

  const containerHeight = useMemo(() => {
    return selectedTopics.length * TOPIC_ITEM_HEIGHT - TOPIC_GAP;
  }, [selectedTopics]);

  const memoizedItems = useMemo(() => {
    const timestamp = Date.now();
    return selectedTopics.map((item, index) => (
      <TopicItem
        key={`${item._id || 'new'}-${item.name}-${index}-${timestamp}`}
        item={item}
        index={index}
        onRemoveSelectedTopic={removeSelectedTopic}
        isInitialRender={isInitialRender}
        maxSelectedTopics={maxSelectedTopics}
      />
    ));
  }, [selectedTopics, isInitialRender, maxSelectedTopics, removeSelectedTopic]);

  return (
    <section className="space-y-6">
      {/* Selected Topics Section - Now with aria-live region */}
      <div
        aria-live="polite"
        className={cn(
          'border rounded-lg p-4',
          selectedTopics.length > 0 ? 'bg-green-50/50 border-green-200' : 'bg-gray-50 border-gray-200',
          'transition-all duration-300',
        )}
      >
        <div className="flex items-center justify-between mb-3">
          <h3 className="text-sm font-medium flex items-center gap-2">
            {selectedTopics.length > 0 ? (
              <CheckCircle className="w-4 h-4 text-green-600" />
            ) : (
              <Circle className="w-4 h-4 text-gray-400" />
            )}
            <span>
              Your Selected Topics ({selectedTopics.length}/{maxSelectedTopics})
            </span>
          </h3>
          {selectedTopics.length > 0 && (
            <Button
              variant="ghost"
              size="sm"
              className="h-8 text-red-600 hover:text-red-700 hover:bg-red-50"
              onClick={removeAllSelectedTopics}
            >
              <Trash2 className="w-4 h-4 mr-2" />
              Remove All
            </Button>
          )}
        </div>
        {selectedTopics.length === 0 ? (
          <p className="text-sm text-muted-foreground">
            Get started by generating some topics based on your ideal audience, or add your own topics directly.
          </p>
        ) : (
          <motion.div
            className="relative"
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: containerHeight, opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{
              duration: 0.3,
              ease: [0.4, 0, 0.2, 1],
              opacity: { duration: 0.2 },
            }}
            layout="size"
          >
            <AnimatePresence initial={false} mode="popLayout">
              {memoizedItems}
            </AnimatePresence>
          </motion.div>
        )}
      </div>
    </section>
  );
}
