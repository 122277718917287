import { Pencil } from 'lucide-react';
import { cn } from '@/lib/utils';

export function TopicSelectionHeader({
  assistantName,
  onNameChange,
}: {
  assistantName?: string;
  onNameChange?: (newName: string) => void;
}) {
  const isEditable = !!onNameChange;

  return (
    <header className={cn('space-y-4')}>
      <div className={cn('relative', 'w-[90%]')}>
        {isEditable && (
          <Pencil
            className={cn(
              'absolute',
              'left-2',
              'top-1/2',
              'h-5',
              'w-5',
              '-translate-y-1/2',
              'text-muted-foreground',
              'opacity-50',
              'pointer-events-none',
            )}
          />
        )}
        <input
          value={assistantName ?? ''}
          onChange={(e) => onNameChange?.(e.target.value)}
          readOnly={!isEditable}
          className={cn(
            'text-3xl',
            'font-bold',
            'tracking-tight',
            'sm:text-4xl',
            'bg-transparent',
            'border-none',
            'focus:ring-0',
            'p-0',
            '[&:not(:read-only)]:border-b',
            '[&:not(:read-only)]:border-dashed',
            '[&:not(:read-only)]:border-gray-300',
            'focus:[&:not(:read-only)]:border-gray-500',
            'w-full',
            isEditable && 'pl-8',
          )}
          placeholder="Type your assistant's name"
        />
      </div>
      <p className={cn('text-lg', 'text-muted-foreground')}>
        Specify your target audience and select key topics to generate engaging LinkedIn post ideas.
      </p>
    </header>
  );
}
