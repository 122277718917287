import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import PTypography from './PTypography';
import { ModeEditOutlineSharp } from '@mui/icons-material';
import PTextfield from './PTextfield';
import { useDispatch } from 'react-redux';
import { updateAssistantName } from '@/redux/slices/Assistants/assistantsSlice';

const PEditableHeader = React.memo(({ text, onChange, onAction, id }) => {
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef(null);
  const initialTextRef = useRef(text);
  const dispatch = useDispatch();

  const handleEditClick = useCallback(() => {
    initialTextRef.current = text;
    setIsEditing(true);
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  }, [text]);

  const handleTextChange = useCallback(
    (e) => {
      const newText = e.target.value;
      onChange(newText);
    },
    [onChange],
  );

  const handleSave = useCallback(() => {
    setIsEditing(false);
    if (text !== initialTextRef.current) {
      onAction(text);
      if (id) {
        dispatch(updateAssistantName({ id, name: text }));
      }
    }
  }, [text, onAction, id, dispatch]);

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        handleSave();
      }
    },
    [handleSave],
  );

  return (
    <Box onClick={!isEditing ? handleEditClick : undefined} sx={{ cursor: 'pointer' }}>
      {isEditing ? (
        <Box sx={{ width: '500px' }} margin="0px">
          <PTextfield
            type="text"
            value={text}
            onChange={handleTextChange}
            onBlur={handleSave}
            onKeyDown={handleKeyDown}
            paperStyle={{ mx: 0, my: 0 }}
            inputRef={inputRef}
          />
        </Box>
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <PTypography weight="regular" size="h1">
            {text || 'Untitled'}
          </PTypography>
          <ModeEditOutlineSharp fontSize="small" />
        </Box>
      )}
    </Box>
  );
});

PEditableHeader.propTypes = {
  text: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
  id: PropTypes.string,
};

export default PEditableHeader;
