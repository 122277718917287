import { useEffect, useState, useMemo } from 'react';
import { Users, Linkedin, Puzzle, Apple, List, Check, ExternalLink, ChevronUp, ChevronDown } from 'lucide-react';
import { cn } from '@/lib/utils';
import { useDispatch, useSelector } from 'react-redux';
import { setPage, setSettingsPageOverride } from '@/redux/authSlice';
import { useChromeExtensionInstalled } from '@/hooks/useChromeExtensionInstalled';
import { ContactlessOutlined } from '@mui/icons-material';
import useTutorialMode from '@/v0/Tutorial/useTutorialMode';

const SetupStep = ({
  icon: Icon,
  text,
  completed,
  onClick,
  external,
}: {
  icon: any;
  text: string;
  completed: boolean;
  onClick?: () => void;
  external?: boolean;
}) => (
  <div
    className={cn(
      'flex items-center gap-2 rounded px-2 py-1',
      !completed && onClick && 'cursor-pointer hover:bg-gray-50 transition-colors',
    )}
    onClick={() => !completed && onClick?.()}
    data-onboarding-step={text}
  >
    <Icon className="h-3.5 w-3.5 text-gray-400 shrink-0" />
    <span className={cn('flex-1 text-xs', completed ? 'line-through text-gray-400' : 'text-gray-600')}>{text}</span>
    {completed ? (
      <Check className="h-3.5 w-3.5 text-emerald-500 shrink-0" />
    ) : external ? (
      <ExternalLink className="h-3.5 w-3.5 text-gray-400 shrink-0" />
    ) : null}
  </div>
);

export function VZeroOnboardingCompletionPanel() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const isExtensionInstalled = useChromeExtensionInstalled({ delay: 200, maxAttempts: 50 });
  const [isExpanded, setIsExpanded] = useState(true);

  const isLinkedInConnected = user.providers?.some((provider) => provider.provider === 'linkedin' && provider.active);
  const { isTutorialMode } = useTutorialMode();

  const progressItems = useMemo(
    () => [
      {
        text: 'Account created',
        completed: true,
        icon: Users,
      },
      {
        text: 'LinkedIn connected',
        completed: isLinkedInConnected,
        icon: Linkedin,
        onClick: () => {
          dispatch(setSettingsPageOverride('Social'));
          dispatch(setPage({ payload: 'settings' }));
        },
      },
      {
        text: 'Chrome extension',
        completed: isExtensionInstalled && user.setupSteps?.connectLinkedInExtension,
        icon: Puzzle,
        onClick: () => {
          window.open(
            'https://chromewebstore.google.com/detail/postilize-digital-assista/eheabolgahkaiemegedcpfeklmdmmbgb',
            '_blank',
          );
        },
        external: true,
      },
      {
        text: 'iOS app',
        completed: user.setupSteps?.connectMobileApp,
        icon: Apple,
        onClick: () => {
          window.open('https://apps.apple.com/ph/app/postilize/id6503707086', '_blank');
        },
        external: true,
      },
    ],
    [isLinkedInConnected, user.setupSteps, isExtensionInstalled, dispatch],
  );

  const progress = useMemo(() => {
    const completedItems = progressItems.filter((item) => item.completed).length;
    return Math.round((completedItems / progressItems.length) * 100);
  }, [progressItems]);

  // Consolidated effect for expansion/collapse logic
  useEffect(() => {
    if (!user.createdAt) return;

    const oneWeekMs = 7 * 24 * 60 * 60 * 1000;
    const timeSinceCreation = new Date().getTime() - new Date(user.createdAt).getTime();
    const isWithinWeek = timeSinceCreation < oneWeekMs;

    if (progress === 100 || !isWithinWeek) {
      setIsExpanded(isTutorialMode ? true : false);
    } else if (progress < 100 && isWithinWeek) {
      setIsExpanded(true);
    }
  }, [progress, user.createdAt, isTutorialMode]);

  if (progress === 100 && !isExpanded) {
    return null;
  }

  return (
    <div className="overflow-hidden rounded-lg border border-gray-200 bg-white shadow-sm">
      <div
        className={cn(
          'flex items-center justify-between px-3 py-2 cursor-pointer hover:bg-gray-50 transition-colors',
          isExpanded && 'border-b border-gray-200',
        )}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className="flex items-center gap-2">
          <span className="font-medium text-gray-900">Setup Progress</span>
          {isExpanded ? (
            <ChevronUp className="h-3.5 w-3.5 text-gray-500" />
          ) : (
            <ChevronDown className="h-3.5 w-3.5 text-gray-500" />
          )}
        </div>
        <span className="font-medium text-gray-900">{progress}%</span>
      </div>

      <div className="px-3 py-2">
        <div className="w-full h-1 bg-gray-100 rounded-full">
          <div
            className="h-full bg-gray-900 rounded-full transition-all duration-300"
            style={{ width: `${progress}%` }}
          />
        </div>
      </div>

      {isExpanded && (
        <div className="px-3 pb-3">
          <div className="text-gray-600 mb-1.5 text-xs">Required Steps:</div>
          <div className="space-y-1">
            {progressItems.map((item, index) => (
              <SetupStep
                key={index}
                icon={item.icon}
                text={item.text}
                completed={item.completed}
                onClick={item.onClick}
                external={item.external}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
