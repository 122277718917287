export const INITIAL_LOCALE = {
  last: 'Finish',
  skip: 'Not now',
  next: 'Continue',
  back: 'Previous',
};

export const WELCOME_MESSAGE = {
  newWelcome: {
    target: 'body',
    title: 'Welcome to Postilize',
    content: "Let's get you started!",
    placement: 'center',
    disableBeacon: true,
    spotlightClicks: false,
  },
  welcomeBack: {
    target: 'body',
    title: 'Welcome back!',
    content: "Let's finish your setup.",
    placement: 'center',
    disableBeacon: true,
    spotlightClicks: false,
  },
};

export const TUTORIAL_STEPS = {
  settings: {
    target: '[data-menu-item="Settings"]',
    title: 'Settings',
    content: 'Click here to configure your account.',
    placement: 'top',
    disableBeacon: true,
    getDynamicProps: () => ({
      disableOverlayClose: true,
      hideFooter: true,
      spotlightClicks: true,
    }),
  },
  topics: {
    target: '[data-settings-menu-item="Topics"]',
    title: 'Topics',
    content: 'Click here to create a list of topics for your social media assistant.',
    placement: 'right',
    disableBeacon: true,
    getDynamicProps: () => ({
      disableOverlayClose: true,
      hideFooter: true,
      spotlightClicks: true,
    }),
  },
  fillTopics: {
    target: '[data-topics-page="Topics"]',
    title: 'Topics',
    content: 'Try adding at least 3 topics to get started.',
    placement: 'left',
    disableBeacon: true,
    getDynamicProps: () => ({
      disableOverlayClose: true,
      spotlightClicks: true,
    }),
  },
  social: {
    target: '[data-settings-menu-item="Social"]',
    title: 'Social',
    content: 'Click here to connect your LinkedIn account.',
    placement: 'right',
    disableBeacon: true,
    getDynamicProps: () => ({
      disableOverlayClose: true,
      hideFooter: true,
      spotlightClicks: true,
    }),
  },
  connectLinkedIn: {
    target: '[data-social-page="Social"]',
    title: 'Connect LinkedIn',
    content: 'We recommend connecting your LinkedIn through the Chrome browser.',
    placement: 'left',
    disableBeacon: true,
    getDynamicProps: () => ({
      disableOverlayClose: true,
      spotlightClicks: true,
    }),
  },
  chromeExtension: {
    target: '[data-onboarding-step="Chrome extension"]',
    title: 'Chrome Extension',
    content: 'Install our Chrome extension to seamlessly integrate Postilize with LinkedIn.',
    placement: 'right',
    disableBeacon: true,
    getDynamicProps: () => ({
      disableOverlayClose: true,
      spotlightClicks: true,
    }),
  },
  mobileApp: {
    target: '[data-onboarding-step="iOS app"]',
    title: 'iOS app',
    content: 'Install our iOS app to quickly manage your connections.',
    placement: 'right',
    disableBeacon: true,
    getDynamicProps: () => ({
      disableOverlayClose: true,
      spotlightClicks: true,
    }),
  },
};
