// useStrategies.js
import { useState, useEffect } from 'react';
import Http from '../http/httpClient';

export const useStrategies = () => {
  const [strategies, setStrategies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStrategies = async () => {
      try {
        setLoading(true);
        const response = await Http.getData('strategy/all');
        if (response?.status && Array.isArray(response.strategies)) {
          setStrategies(response.strategies);
        } else {
          setStrategies([]);
        }
      } catch (error) {
        console.error('Error fetching strategies:', error);
        setError(error);
        setStrategies([]);
      } finally {
        setLoading(false);
      }
    };

    fetchStrategies();
  }, []);

  return {
    strategies,
    setStrategies,
    loading,
    error,
  };
};
