import * as React from 'react';
import { Plus, Loader2 } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import type { Client } from './ClientTable';

interface FloatingMenuProps {
  selectedCount: number;
  selectedClients: Client[];
  onMerge: (targetClientId: string | { newClientName: string }) => void;
  mergeStatus: {
    isMerging: boolean;
    message: string;
  };
}

const MAX_SELECTED_CLIENTS = 5;
const MIN_SELECTED_CLIENTS = 2;

export function FloatingMenu({ selectedCount, selectedClients, onMerge, mergeStatus }: FloatingMenuProps) {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [selectedMergeTarget, setSelectedMergeTarget] = React.useState<string>('');
  const [isCreatingNew, setIsCreatingNew] = React.useState(false);
  const [newClientName, setNewClientName] = React.useState('');
  const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);

  if (!mergeStatus.message && selectedCount === 0) return null;

  if (mergeStatus.message) {
    return (
      <Card className="fixed top-4 left-1/2 -translate-x-1/2 z-50 shadow-lg animate-in fade-in slide-in-from-top-4 duration-200">
        <div className="p-4 flex items-center space-x-2">
          {mergeStatus.isMerging && <Loader2 className="h-4 w-4 animate-spin" />}
          <span className="text-sm">{mergeStatus.message}</span>
        </div>
      </Card>
    );
  }

  const handleMergeConfirm = () => {
    setShowConfirmDialog(true);
  };

  const handleMergeExecute = () => {
    if (isCreatingNew && newClientName) {
      onMerge({ newClientName: newClientName });
    } else if (selectedMergeTarget) {
      onMerge(selectedMergeTarget);
    }
    setIsExpanded(false);
    setSelectedMergeTarget('');
    setIsCreatingNew(false);
    setNewClientName('');
    setShowConfirmDialog(false);
  };

  const toggleCreateNew = () => {
    setIsCreatingNew(!isCreatingNew);
    if (isCreatingNew) {
      setNewClientName('');
    }
    setSelectedMergeTarget('');
  };

  return (
    <Card className="fixed top-4 left-1/2 -translate-x-1/2 z-50 shadow-lg animate-in fade-in slide-in-from-top-4 duration-200">
      <div className="flex flex-col">
        <div className="flex items-center gap-4 p-4">
          <span className="text-sm text-muted-foreground">
            {selectedCount} {selectedCount === 1 ? 'client' : 'clients'} selected
          </span>
          <div className="flex items-center gap-2">
            {selectedCount > MAX_SELECTED_CLIENTS ? (
              <span className="text-sm text-red-500">Please select {MAX_SELECTED_CLIENTS} or fewer clients</span>
            ) : selectedCount >= MIN_SELECTED_CLIENTS ? (
              <Button variant="outline" size="sm" onClick={() => setIsExpanded(true)}>
                Merge Clients
              </Button>
            ) : null}
          </div>
        </div>

        {isExpanded && selectedCount >= MIN_SELECTED_CLIENTS && selectedCount <= MAX_SELECTED_CLIENTS && (
          <div className="border-t p-4 space-y-4 animate-in fade-in slide-in-from-top-2 duration-200">
            <div className="space-y-2">
              <h3 className="text-sm font-medium">Select combined client name:</h3>
              <div className="space-y-2">
                <RadioGroup
                  value={selectedMergeTarget}
                  onValueChange={(value) => {
                    setSelectedMergeTarget(value);
                    setIsCreatingNew(false);
                  }}
                  className="space-y-2"
                >
                  {selectedClients.map((client) => {
                    // In the case of duplicate names, we want to show the relationship score beside the name for differentiation between the clients
                    const duplicateNames = selectedClients.filter((c) => c.name === client.name).length > 1;
                    return (
                      <div key={client._id} className="flex items-center space-x-2">
                        <RadioGroupItem value={client._id} id={client._id} />
                        <Label htmlFor={client._id} className="text-sm">
                          {client.name}
                          {duplicateNames && <span className="text-xs font-light"> ({client.relationshipScore})</span>}
                        </Label>
                      </div>
                    );
                  })}
                </RadioGroup>
                <div className="pt-2">
                  <Button
                    variant={isCreatingNew ? 'secondary' : 'outline'}
                    size="sm"
                    onClick={toggleCreateNew}
                    className="w-full justify-start"
                  >
                    <Plus className="h-4 w-4 mr-2" />
                    Create New
                  </Button>
                </div>
              </div>
            </div>
            {isCreatingNew && (
              <div className="space-y-2 animate-in fade-in slide-in-from-top-2 duration-200">
                <Input
                  id="newClientName"
                  value={newClientName}
                  onChange={(e) => setNewClientName(e.target.value)}
                  placeholder="Enter new client name"
                />
              </div>
            )}
            <div className="flex justify-end gap-2">
              <Button
                variant="outline"
                size="sm"
                onClick={() => {
                  setIsExpanded(false);
                  setSelectedMergeTarget('');
                  setIsCreatingNew(false);
                  setNewClientName('');
                }}
              >
                Cancel
              </Button>
              <Button
                size="sm"
                onClick={handleMergeConfirm}
                disabled={(!selectedMergeTarget && !isCreatingNew) || (isCreatingNew && !newClientName)}
              >
                Confirm Merge
              </Button>
            </div>
          </div>
        )}
      </div>

      <AlertDialog open={showConfirmDialog} onOpenChange={setShowConfirmDialog}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure you want to merge these clients?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. The selected clients will be merged into
              {isCreatingNew ? (
                <>
                  {' '}
                  a new client named <strong>{newClientName}</strong>.
                </>
              ) : (
                <>
                  {' '}
                  the client <strong>{selectedClients.find((c) => c._id === selectedMergeTarget)?.name}</strong>.
                </>
              )}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={handleMergeExecute}>Yes, Merge Clients</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Card>
  );
}
