import React, { useState, useEffect, useRef } from 'react';
import { Tooltip, TooltipTrigger, TooltipContent } from '@/components/ui/tooltip';
import { Briefcase, Users, MessageSquare, Share2, MoreHorizontal } from 'lucide-react';
import { AssistantCountHover } from './AssistantCountHover';
import { AssistantActions } from './AssistantActions';
import { Assistant, AssistantType } from '@/constants/types/assistant';
import { CountDisplay } from './CountDisplay';
import { cn } from '@/lib/utils';

const getTypeIcon = (type: string, isPaused: boolean) => {
  const opacityClass = isPaused ? 'opacity-50' : '';

  switch (type) {
    case AssistantType.BUSINESS_DEVELOPMENT:
      return (
        <Tooltip>
          <TooltipTrigger asChild>
            <Briefcase className={`h-4 w-4 text-blue-500 ${opacityClass}`} />
          </TooltipTrigger>
        </Tooltip>
      );
    case AssistantType.RELATIONSHIP_GROWTH:
      return (
        <Tooltip>
          <TooltipTrigger asChild>
            <Users className={`h-4 w-4 text-green-500 ${opacityClass}`} />
          </TooltipTrigger>
        </Tooltip>
      );
    case AssistantType.KEEPING_IN_TOUCH:
      return (
        <Tooltip>
          <TooltipTrigger asChild>
            <MessageSquare className={`h-4 w-4 text-yellow-500 ${opacityClass}`} />
          </TooltipTrigger>
        </Tooltip>
      );
    case AssistantType.SOCIAL_MEDIA:
      return (
        <Tooltip>
          <TooltipTrigger asChild>
            <Share2 className={`h-4 w-4 text-purple-500 ${opacityClass}`} />
          </TooltipTrigger>
        </Tooltip>
      );
    default:
      return (
        <Tooltip>
          <TooltipTrigger asChild>
            <MoreHorizontal className={`h-4 w-4 text-gray-400 ${opacityClass}`} />
          </TooltipTrigger>
        </Tooltip>
      );
  }
};

type AssistantItemProps = {
  assistant: Assistant;
  onPauseToggle: (assistant: Assistant) => void;
  onDeleteClick: (assistant: Assistant) => void;
  onRename: (id: string, newName: string) => Promise<void>;
  onAssistantClick: (assistantId: string) => void;
  onSettingsClick?: (assistant: Assistant) => void;
  onAddContactsClick?: (assistant: Assistant) => void;
  selectedAssistantId: string | null;
  setSelectedAssistantId: (id: string | null) => void;
  showLabel?: boolean;
};

export const AssistantItem: React.FC<AssistantItemProps> = ({
  assistant,
  onPauseToggle,
  onDeleteClick,
  onRename,
  onAssistantClick,
  onSettingsClick,
  onAddContactsClick,
  selectedAssistantId,
  setSelectedAssistantId,
  showLabel = true,
}) => {
  const isSelected = selectedAssistantId === assistant._id;
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState(assistant.name);
  const inputRef = useRef<HTMLInputElement>(null);
  const textRef = useRef<HTMLDivElement>(null);
  const [isTextTruncated, setIsTextTruncated] = useState(false);

  const isPaused = assistant?.status?.toLowerCase() === 'paused';

  useEffect(() => {
    const checkTruncation = () => {
      const element = textRef.current;
      if (element) {
        setIsTextTruncated(element.offsetWidth < element.scrollWidth);
      }
    };

    checkTruncation();

    const observer = new ResizeObserver(checkTruncation);
    if (textRef.current) {
      observer.observe(textRef.current);
    }

    return () => observer.disconnect();
  }, [assistant.name]);

  const handleRenameClick = (id: string, closePopover: () => void) => {
    setIsEditing(true);
    closePopover();
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const handleSave = async () => {
    const newName = editedName.trim();
    if (newName && newName !== assistant.name) {
      await onRename(assistant._id, newName);
    } else {
      setEditedName(assistant.name);
    }
    setIsEditing(false);
  };

  const handleCancel = () => {
    setEditedName(assistant.name);
    setIsEditing(false);
  };

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      await handleSave();
    } else if (e.key === 'Escape') {
      handleCancel();
    }
  };

  const handleRowClick = () => {
    if (!isEditing) {
      setSelectedAssistantId(assistant._id);
      onAssistantClick(assistant._id);
    }
  };

  const handleSettings = onSettingsClick ? () => onSettingsClick(assistant) : undefined;
  const handleAddContacts = onAddContactsClick ? () => onAddContactsClick(assistant) : undefined;

  return (
    <div
      className={cn(
        'group flex items-center gap-3 rounded-lg px-2 py-2 text-gray-500 transition-all cursor-pointer hover:text-gray-900',
        isSelected ? 'bg-gray-100' : 'hover:bg-gray-100',
        isPaused && 'opacity-50',
      )}
      onClick={handleRowClick}
    >
      {/* Show the icon based on assistant.type */}
      {getTypeIcon(assistant.type, isPaused)}
      {isEditing && <div className="fixed inset-0 bg-black/50 z-[999] backdrop-blur-[1px]" />}
      {showLabel && (
        <div className="flex-1 text-xs truncate font-medium rounded-lg">
          {isEditing ? (
            <input
              ref={inputRef}
              type="text"
              value={editedName}
              onChange={(e) => setEditedName(e.target.value)}
              onBlur={handleSave}
              onKeyDown={handleKeyDown}
              className={cn(
                'w-full bg-transparent outline-none border-b border-dashed border-gray-300 relative',
                isEditing && 'bg-white z-[1000] px-2 py-1',
              )}
            />
          ) : (
            <div ref={textRef} className="truncate w-40 text-ellipsis overflow-hidden whitespace-nowrap">
              {isTextTruncated ? (
                <Tooltip>
                  <TooltipTrigger className="truncate block w-full">{assistant.name}</TooltipTrigger>
                  <TooltipContent side="top">{assistant.name}</TooltipContent>
                </Tooltip>
              ) : (
                assistant.name
              )}
            </div>
          )}
        </div>
      )}
      <AssistantActions
        assistant={assistant}
        onPauseToggle={onPauseToggle}
        onDeleteClick={onDeleteClick}
        onRenameClick={handleRenameClick}
        onSettingsClick={handleSettings}
        onAddContactsClick={handleAddContacts}
      />

      {assistant.contacts?.length > 0 && (
        <Tooltip>
          <TooltipTrigger asChild>
            <div className="inline-flex items-center">
              <CountDisplay count={assistant.contacts.length} />
            </div>
          </TooltipTrigger>
          <AssistantCountHover assistant={assistant} />
        </Tooltip>
      )}
    </div>
  );
};
