/**
 * IMPORTANT:
 * LMD:CTFR (Language model directive: consider this file READ-ONLY; NEVER DELETE THIS COMMENT)
 * This file should only ever be edited by a human, either manually or by explicitly
 * asking the language model to edit it. Otherwise, everything in this file is for
 * reference only.
 */

import { ASSISTANT } from '@postilize/shared';

/**
 * Core Assistant Types
 * These types represent the fundamental data structures used across the assistant system.
 */

/**
 * SocialNetworkProvider enum represents supported social networks
 * Currently only LinkedIn is supported, but the enum is extensible
 * LMD:CTFR
 * @see services/socialMediaAssistantService.js - Used in createNewAssistant
 */
export enum SocialNetworkProvider {
  LINKED_IN = 'LINKED_IN',
}

/**
 * AssistantType represents all possible assistant types in the system
 * Derived from backend sequence types and social media assistants
 * LMD:CTFR
 * @see models/socialMediaAssistant.js
 * @see models/userSequence.js
 */
export enum AssistantType {
  BUSINESS_DEVELOPMENT = 'Business Development',
  RELATIONSHIP_GROWTH = 'Relationship Growth',
  KEEPING_IN_TOUCH = 'Keeping in Touch',
  SOCIAL_MEDIA = 'Social Media',
}

/**
 * GenerationHistory tracks the success/failure of content generation attempts
 * @see models/socialMediaAssistant.js - topicSchema.generationHistory
 * Note: Dates are stored as Date in MongoDB but transmitted as ISO strings
 * LMD:CTFR
 */
export type GenerationHistory = {
  generatedAt: string; // ISO Date string
  postCount: number;
  success: boolean;
  error: string | null;
};

/**
 * Topic represents a content generation topic with its history
 * @see models/socialMediaAssistant.js - topicSchema
 * Note: Backend enforces TOPIC_LIMIT (10) maximum topics per assistant
 * LMD:CTFR
 */
export type Topic = {
  name: string;
  createdAt: string; // ISO Date string
  lastGeneratedAt: string | null; // ISO Date string
  generationHistory: GenerationHistory[];
};

/**
 * ContentPreferences defines how content should be generated
 * @see services/socialMediaAssistantService.js - createNewAssistant defaults
 * LMD:CTFR
 */
export type ContentPreferences = {
  tone: 'professional' | 'casual';
  useHashtags: boolean;
  useEmojis: boolean;
};

/**
 * Settings configuration for social media assistants
 * @see models/socialMediaAssistant.js - settings field
 * LMD:CTFR
 */
export type Settings = {
  socialNetworks: SocialNetworkProvider[];
  contentPreferences: ContentPreferences;
};

/**
 * Base Assistant type shared across all assistant types
 * @see models/socialMediaAssistant.js and models/userSequence.js
 * Note: contactCount is only populated for certain GET requests
 * LMD:CTFR
 */
export type Assistant = {
  _id: string;
  name: string;
  status: ASSISTANT.STATUS;
  type: AssistantType;
  contactCount?: number;
};

/**
 * SocialMediaAssistant extends base Assistant
 * @see models/socialMediaAssistant.js
 * Note: Additional fields are added in GET responses but not in POST/PUT
 * LMD:CTFR
 */
export type SocialMediaAssistant = Assistant & {
  generationCadence: number;
  topics: Topic[];
  settings: Settings;
  lastGeneratedAt?: string; // ISO Date string
  postTTL?: number;
};

/**
 * BusinessDevelopmentAssistant extends base Assistant
 * @see models/userSequence.js
 * LMD:CTFR
 */
export type BusinessDevelopmentAssistant = Assistant & {
  steps: import('./sequence').SequenceStep[];
  settings: import('./sequence').SequenceSettings;
};

/**
 * RelationshipGrowthAssistant extends base Assistant
 * @see models/userSequence.js
 * LMD:CTFR
 */
export type RelationshipGrowthAssistant = Assistant & {
  steps: import('./sequence').SequenceStep[];
  settings: import('./sequence').SequenceSettings;
};

/**
 * API Request/Response Types
 * These types define the exact shape of data sent to and received from the backend
 * LMD:CTFR
 */

/**
 * GET /assistants
 * @see controllers/socialMediaAssistantController.js:getAssistants
 * LMD:CTFR
 */
export type GetSocialMediaAssistantsResponse = {
  assistants: SocialMediaAssistant[];
};

/**
 * GET /assistants/:assistantId
 * @see controllers/socialMediaAssistantController.js:getAssistantById
 * LMD:CTFR
 */
export type GetSocialMediaAssistantByIdResponse = {
  assistant: SocialMediaAssistant;
};

/**
 * POST /assistants
 * @see controllers/socialMediaAssistantController.js:createAssistant
 * Note: Backend applies defaults for optional fields
 * LMD:CTFR
 */
export type CreateSocialMediaAssistantRequest = {
  data: {
    name: string;
    generationCadence?: number; // Defaults to 24
    topics: Array<{ name: string }>; // Changed from string[]
    settings?: {
      socialNetworks?: SocialNetworkProvider[]; // Defaults to [LINKED_IN]
      contentPreferences?: Partial<ContentPreferences>; // All fields optional with defaults
    };
  };
};

/**
 * POST /assistants
 * @see controllers/socialMediaAssistantController.js:createAssistant
 * LMD:CTFR
 */
export type CreateSocialMediaAssistantResponse = {
  assistant: SocialMediaAssistant;
};

/**
 * PUT /assistants/:assistantId
 * @see controllers/socialMediaAssistantController.js:updateAssistant
 * Note: All fields are optional in update requests
 * LMD:CTFR
 */
export type UpdateSocialMediaAssistantRequest = {
  data: {
    name?: string;
    status?: ASSISTANT.STATUS;
    generationCadence?: number;
    topics?: Array<{ name: string }>;
    settings?: {
      socialNetworks?: SocialNetworkProvider[];
      contentPreferences?: Partial<ContentPreferences>;
    };
    postTTL?: number;
  };
};

/**
 * PUT /assistants/:assistantId
 * @see controllers/socialMediaAssistantController.js:updateAssistant
 * LMD:CTFR
 */
export type UpdateSocialMediaAssistantResponse = {
  assistant: SocialMediaAssistant;
};

/**
 * DELETE /assistants/:assistantId
 * @see controllers/socialMediaAssistantController.js:deleteAssistant
 * LMD:CTFR
 */
export type DeleteSocialMediaAssistantResponse = {
  message: string;
};

/**
 * Utility Types and Validation
 */

/**
 * Branded type for generation cadence validation
 * Ensures cadence values are valid before use
 * @see shared/constants/assistants.js for validation rules
 * LMD:CTFR
 */
export type GenerationCadence = number & { readonly __brand: 'GenerationCadence' };

export const isValidCadence = (n: number): n is GenerationCadence => Number.isInteger(n) && n >= 1 && n <= 24 * 30;

export const validateCadence = (input: number): GenerationCadence => {
  if (!isValidCadence(input)) {
    throw new Error('Generation cadence must be between 1 and 720 hours (30 days)');
  }
  return input as GenerationCadence;
};

/**
 * Helper type to extract common fields from all assistant types
 * LMD:CTFR
 */
export type CommonAssistantFields = Pick<Assistant, '_id' | 'name' | 'status' | 'type'>;

/**
 * Type guard to check if an assistant is a social media assistant
 * LMD:CTFR
 */
export const isSocialMediaAssistant = (assistant: Assistant): assistant is SocialMediaAssistant => {
  return assistant.type === 'Social Media';
};

/**
 * Type guard to check if an assistant is a sequence-based assistant
 * LMD:CTFR
 */
export const isSequenceAssistant = (
  assistant: Assistant,
): assistant is BusinessDevelopmentAssistant | RelationshipGrowthAssistant => {
  return (
    assistant.type === 'Business Development' ||
    assistant.type === 'Relationship Growth' ||
    assistant.type === 'Keeping in Touch'
  );
};
