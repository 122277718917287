import { useState, useEffect } from 'react';
import featureFlagService from '../services/featureFlagService';
import { useSelector } from 'react-redux';

export const useFeatureFlags = () => {
  const [featureFlags, setFeatureFlags] = useState([]);
  const cachedFeatureFlags = useSelector((state) => state.auth.featureFlags);

  useEffect(() => {
    const fetchFeatureFlags = async () => {
      if (cachedFeatureFlags.length > 0) {
        setFeatureFlags(cachedFeatureFlags);
        return;
      }

      try {
        const flags = await featureFlagService.getFeatureFlags();
        setFeatureFlags(flags);
      } catch (error) {
        console.error(error);
      }
    };

    fetchFeatureFlags();
  }, []);

  return featureFlags;
};

export const useAdminFeatureFlags = () => {
  console.log(`useAdminFeatureFlags hook`);
  const [featureFlags, setFeatureFlags] = useState([]);

  useEffect(() => {
    const fetchFeatureFlags = async () => {
      try {
        const flags = await featureFlagService.getAdminFeatureFlags();
        setFeatureFlags(flags);
      } catch (error) {
        console.error(error);
      }
    };

    fetchFeatureFlags();
  }, []);

  return featureFlags ? [featureFlags, setFeatureFlags] : [[], setFeatureFlags];
};

export const useIsFeatureFlagEnabled = (featureName) => {
  try {
    const featureFlags = useSelector((state) => state.auth.featureFlags);
    const featureFlag = featureFlags.find((flag) => flag.name === featureName);
    return featureFlag ? featureFlag.isFeatureEnabled : false;
  } catch (error) {
    console.error(error);
    return false;
  }
};
