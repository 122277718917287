import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useSnackbar } from '@/contexts/SnackbarContext';
import {
  useGetSocialMediaAssistantsQuery,
  useUpdateSocialMediaAssistantMutation,
} from '@/helper/APIs/SocialMediaAssistantsApi';
import { TopicSelection } from '@/pages/CommonScreens/TopicSelection/TopicSelection';
import { Topic, SocialMediaAssistant } from '@/constants/types/assistant';
import { useSelector } from 'react-redux';
import { areStringsEqualIgnoreCase } from '@postilize/shared';

export default function SocialMediaAssistantView({ assistantId }: { assistantId: string }) {
  const { addSnackbar } = useSnackbar();
  const delegationAccessId = useSelector((state: any) => state.auth.delegationAccessId);
  const [draftAssistant, setDraftAssistant] = useState<SocialMediaAssistant | null>(null);

  const { data: bulkData, isLoading } = useGetSocialMediaAssistantsQuery({ accessId: delegationAccessId });
  const assistantData = useMemo(
    () => bulkData?.assistants?.find((a) => a._id === assistantId),
    [bulkData?.assistants, assistantId],
  );

  const [updateAssistant] = useUpdateSocialMediaAssistantMutation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (assistantData) {
      setDraftAssistant(structuredClone(assistantData));
    }
  }, [assistantData, assistantId]);

  const handleTopicsChange = useCallback((updatedTopics: Topic[]) => {
    setDraftAssistant((prev) => (prev ? { ...prev, topics: updatedTopics } : prev));
  }, []);

  const handleNameChange = useCallback((newName: string) => {
    setDraftAssistant((prev) => (prev ? { ...prev, name: newName } : prev));
  }, []);

  const handleSave = useCallback(async () => {
    setLoading(true);
    try {
      if (!assistantId || !draftAssistant || !assistantData) {
        addSnackbar({
          title: 'Missing Data',
          message:
            'Assistant data is missing. Please refresh and try again. If this issue persists, please contact support.',
          pVariant: 'destructive',
        });
        return;
      }

      const formattedTopics = draftAssistant.topics
        .filter((t) => t.name.trim())
        .map((topic) => {
          const originalTopic = assistantData.topics.find((orig) => areStringsEqualIgnoreCase(orig.name, topic.name));
          return originalTopic ? { name: topic.name, _id: originalTopic._id } : { name: topic.name };
        });

      await updateAssistant({
        _id: assistantId,
        data: {
          name: draftAssistant.name,
          topics: formattedTopics,
        },
        accessId: delegationAccessId,
      });
    } finally {
      setLoading(false);
    }
  }, [assistantId, draftAssistant, updateAssistant, delegationAccessId, addSnackbar, assistantData]);

  const hasUnsavedChanges = useMemo(() => {
    if (!assistantData || !draftAssistant) return false;
    return (
      draftAssistant.name !== assistantData.name ||
      JSON.stringify(draftAssistant.topics) !== JSON.stringify(assistantData.topics)
    );
  }, [assistantData, draftAssistant]);

  if (isLoading) {
    return <div className="flex items-center justify-center h-full">Loading...</div>;
  }

  if (!assistantData) {
    return <div className="text-red-500">Assistant not found</div>;
  }

  return (
    <div className="relative w-full">
      <button
        onClick={handleSave}
        disabled={loading || !hasUnsavedChanges || draftAssistant?.topics.length === 0}
        className="absolute top-4 right-4 bg-blue-500 text-white px-4 py-2 rounded-md disabled:opacity-50"
      >
        {loading ? (
          <div className="h-4 w-4 animate-spin rounded-full border-2 border-t-transparent border-white"></div>
        ) : (
          'Save'
        )}
      </button>
      <div className="p-3">
        <TopicSelection
          selectedTopics={draftAssistant?.topics || []}
          isLoading={isLoading}
          onTopicsChange={handleTopicsChange}
          assistantName={draftAssistant?.name}
          onNameChange={handleNameChange}
        />
      </div>
    </div>
  );
}
