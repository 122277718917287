/**
 * Core Assistant Constants
 * These constants represent the fundamental data structures used across the assistant system.
 * Combines legacy sequences with new social media assistants.
 */

const ASSISTANT = {
  /**
   * Assistant status enum matches the backend schema status field
   * @see models/socialMediaAssistant.js - status field in schema
   * @see routes/socialMediaAssistant/socialMediaAssistantRoutes.js - validation schema
   * LMD:CTFR
   */
  STATUS: Object.freeze({
    ACTIVE: 'Active',
    PAUSED: 'Paused',
    DELETED: 'Deleted',
  }),

  SOCIAL_NETWORKS: Object.freeze(['linkedin', 'twitter']),

  CONTENT_PREFERENCES: Object.freeze({
    TONES: Object.freeze(['professional', 'casual', 'enthusiastic']),
    DEFAULTS: Object.freeze({
      tone: 'professional',
      useHashtags: false,
      useEmojis: false,
    }),
  }),

  GOAL_ID: {
    ACQUIRE: 'acquire',
    RETAIN: 'retain',
    GROW: 'grow',
  },

  NAME_MAX_LENGTH: 128,

  TOPIC_LIMIT: 10,
  POST_TTL_HOURS: 48,
};

module.exports = {
  ASSISTANT,
};
