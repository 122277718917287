import React, { useState, useEffect, useCallback } from 'react';
import { motion } from 'framer-motion';
import { ChevronDown, Plus } from 'lucide-react';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { TooltipProvider, Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from 'components/ui/alert-dialog';
import { cn } from 'lib/utils';
import SequenceModal from 'pages/ClientAcquisition/components/addSequenceModal/sequenceModal.js';
import EditModal from 'pages/ClientAcquisition/components/sequenceSettingsModal/edit.js';
import AddContactsModal from 'pages/ClientAcquisition/components/addContactsModal';
import { useAssistants } from '@/hooks/useAssistants';
import { Assistant, AssistantType, SocialMediaAssistant } from '@/constants/types/assistant';
import {
  useUpdateSocialMediaAssistantMutation,
  useDeleteSocialMediaAssistantMutation,
} from '@/helper/APIs/SocialMediaAssistantsApi';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedAssistantId, selectSelectedAssistantId } from '@/redux/slices/Assistants/assistantsSlice';
import { useSnackbar } from '@/contexts/SnackbarContext';

import { AssistantItem } from './components/AssistantItem';
import { ASSISTANT } from '@postilize/shared';

/**
 * Checks if the assistant is active (i.e., the status equals ASSISTANT.STATUS.ACTIVE
 * in a case-insensitive manner).
 *
 * @param {Assistant | null | undefined} assistant - The assistant to check.
 * @returns {boolean} True if the assistant is active; otherwise, false.
 */
function isAssistantActive(assistant?: Assistant | null): boolean {
  return assistant?.status?.toLowerCase() === ASSISTANT.STATUS.ACTIVE.toLowerCase();
}

type AssistantsSectionProps = {
  assistants: Assistant[];
  socialMediaAssistants: SocialMediaAssistant[];
  isSequencesEnabled: boolean;
  onPauseToggle: (assistant: Assistant) => Promise<void>;
  onDelete: (id: string) => Promise<void>;
  onRename: (id: string, newName: string) => Promise<void>;
  onAssistantClick: (assistantId: string) => void;
  currentPage: string;
  onRefreshClientAcquisition?: () => void; // from HEAD
  // Prop from feature branch:
  postOnClick?: () => void;
  typeLabel: string;
  typeIcon: React.ComponentType<any>;
  desiredType?: string;
  strategy: any;
};

// Animations from HEAD
const chevronAnimation = {
  expanded: { rotate: 0, transition: { duration: 0.3 } },
  collapsed: { rotate: 180, transition: { duration: 0.3 } },
};

const contentAnimation = {
  expanded: { opacity: 1, height: 'auto', transition: { duration: 0.3 } },
  collapsed: { opacity: 0, height: 0, transition: { duration: 0.3 } },
};

function getGoalIdFromType(assistantType: string): string {
  switch (assistantType) {
    case AssistantType.BUSINESS_DEVELOPMENT:
      return 'acquire';
    case AssistantType.RELATIONSHIP_GROWTH:
      return 'retain';
    case AssistantType.SOCIAL_MEDIA:
      return 'grow';
    default:
      return 'acquire';
  }
}

const getAssistantTypeDescription = (type: string) => {
  switch (type) {
    case 'Business Development':
      return `Create a new assistant to help you acquire new leads`;
    case 'Relationship Growth':
      return `Create a new assistant to help you grow existing relationships`;
    case 'Social Media':
      return `Create a new assistant to help you build your social presence`;
    default:
      return 'Create a new assistant';
  }
};

const AssistantsSection: React.FC<AssistantsSectionProps> = ({
  assistants = [],
  isSequencesEnabled,
  onPauseToggle,
  onDelete,
  onRename,
  onAssistantClick,
  currentPage,
  onRefreshClientAcquisition,
  typeLabel,
  typeIcon: TypeIcon,
  desiredType,
  strategy,
}) => {
  const {
    modalOpen,
    closeSequenceModal,
    openSequenceModal,
    createdSequenceId,
    setCreatedSequenceId,
    defaultTemplates,
    stepText,
    strategies,
    targetAudience,
    availableList,
    initialLoading,
    strategyLoading,
    initialListLoading,
    fetchAssistantsData,
    handleAssistantEdit,
    socialMediaAssistants,
  } = useAssistants();

  const [mainAccordionValue, setMainAccordionValue] = useState<string[]>(['assistants']);
  const [assistantToDelete, setAssistantToDelete] = useState<Assistant | null>(null);
  const [assistantToToggle, setAssistantToToggle] = useState<Assistant | null>(null);

  const [editOpen, setEditOpen] = useState(false);
  const [assistantToEdit, setAssistantToEdit] = useState<Assistant | null>(null);
  const [addContactsOpen, setAddContactsOpen] = useState(false);
  const [assistantForContacts, setAssistantForContacts] = useState<Assistant | null>(null);

  const [updateAssistant] = useUpdateSocialMediaAssistantMutation();
  const [deleteSocialMediaAssistant] = useDeleteSocialMediaAssistantMutation();
  const delegationAccessId = useSelector((state: any) => state.auth.delegationAccessId);
  const dispatch = useDispatch();
  const { addSnackbar } = useSnackbar();

  const selectedAssistantId = useSelector(selectSelectedAssistantId);

  useEffect(() => {
    const storedMain = localStorage.getItem(`assistantsMainAccordion_${typeLabel}`);
    if (storedMain) {
      try {
        const val = JSON.parse(storedMain);
        if (Array.isArray(val)) {
          setMainAccordionValue(val);
        }
      } catch (error) {
        console.error('Failed to parse assistantsMainAccordion:', error);
      }
    }
  }, [typeLabel]);

  const handleSettingsClick = useCallback(
    async (assistant: Assistant) => {
      try {
        let full = assistant;
        if (!assistant.template) {
          const fetched = await handleAssistantEdit(assistant._id);
          if (fetched) full = fetched;
        }
        setAssistantToEdit(full);
        setEditOpen(true);
      } catch (error) {
        console.error('Error handleSettingsClick:', error);
      }
    },
    [handleAssistantEdit],
  );

  const handleAddContactsClick = useCallback((assistant: Assistant) => {
    setAssistantForContacts(assistant);
    setAddContactsOpen(true);
  }, []);

  const refreshAfterChange = useCallback(
    async (id?: string) => {
      try {
        await fetchAssistantsData();
      } catch (error) {
        console.error('Failed to fetch assistants:', error);
      }
      if (id) onAssistantClick(id);
      if (onRefreshClientAcquisition) {
        onRefreshClientAcquisition();
      }
    },
    [fetchAssistantsData, onAssistantClick, onRefreshClientAcquisition],
  );

  // Reset selected assistant when page changes
  useEffect(() => {
    if (currentPage !== 'acquisition') {
      dispatch(setSelectedAssistantId(null));
    }
  }, [currentPage, dispatch]);

  // When a new assistant is created
  useEffect(() => {
    if (createdSequenceId && assistants.length > 0) {
      const newAsst = assistants.find((a) => a._id === createdSequenceId);
      if (newAsst) {
        dispatch(setSelectedAssistantId(newAsst._id));
        onAssistantClick(newAsst._id);
      }
    }
  }, [createdSequenceId, assistants, onAssistantClick, setCreatedSequenceId, dispatch]);

  const handleAssistantClick = useCallback(
    (assistantId: string) => {
      dispatch(setSelectedAssistantId(assistantId));
      onAssistantClick(assistantId);
    },
    [dispatch, onAssistantClick],
  );

  if (!isSequencesEnabled) return null;

  /**
   * Updates accordion state in localStorage.
   * @param {string} key - The key under which the state is saved.
   * @param {string[]} value - The array to be stored.
   */
  const updateAccordionLocalStorage = (key: string, value: string[]) =>
    localStorage.setItem(key, JSON.stringify(value));

  // HEAD code for storing expanded accordion
  const handleMainAccordionChange = (value: string[]) => {
    setMainAccordionValue(value);
    updateAccordionLocalStorage(`assistantsMainAccordion_${typeLabel}`, value);
  };

  const handleDeleteClick = (assistant: Assistant) => {
    setAssistantToDelete(assistant);
  };

  const handleConfirmDelete = async () => {
    if (assistantToDelete) {
      try {
        if (assistantToDelete.type === AssistantType.SOCIAL_MEDIA) {
          await deleteSocialMediaAssistant({
            id: assistantToDelete._id,
            accessId: delegationAccessId,
          });
        } else {
          await onDelete(assistantToDelete._id);

          // TODO: Replace with RTK api notification middleware
          addSnackbar({
            title: 'Assistant Deleted',
            message: 'Assistant was successfully deleted.',
            pVariant: 'success',
          });
        }
        dispatch(setSelectedAssistantId(null));
      } catch (error: any) {
        console.error('Error deleting assistant:', error);
        // TODO: Add snackbar for when sequence-based assistant can't be deleted (RTK api notification middleware)
      }
      setAssistantToDelete(null);
      await refreshAfterChange();
    }
  };

  const handleCancelDelete = () => {
    setAssistantToDelete(null);
  };

  // Handle Pause/Resume
  const handlePauseToggleClick = (assistant: Assistant) => {
    setAssistantToToggle(assistant);
  };

  const handleConfirmPauseToggle = async () => {
    if (assistantToToggle) {
      const isActive = isAssistantActive(assistantToToggle);
      try {
        if (assistantToToggle.type === AssistantType.SOCIAL_MEDIA) {
          await updateAssistant({
            _id: assistantToToggle._id,
            data: { status: isActive ? ASSISTANT.STATUS.PAUSED : ASSISTANT.STATUS.ACTIVE },
            accessId: delegationAccessId,
          });
        } else {
          await onPauseToggle(assistantToToggle);
          setAssistantToToggle(null);
          await refreshAfterChange();

          // TODO: Replace with RTK api notification middleware
          addSnackbar({
            title: `Assistant ${isActive ? 'Paused' : 'Resumed'}`,
            message: `Assistant was successfully ${isActive ? 'paused' : 'resumed'}.`,
            pVariant: 'success',
          });
        }
      } catch (error) {
        console.error('Failed to toggle assistant status:', error);
        // TODO: Add snackbar for when sequence-based assistant can't be paused (RTK api notification middleware)
      }
    }
  };

  const handleCancelPauseToggle = () => {
    setAssistantToToggle(null);
  };

  const handleCreateNewAssistant = (e: React.MouseEvent) => {
    e.stopPropagation();
    const strategyGoalId = getGoalIdFromType(desiredType);
    openSequenceModal(strategyGoalId);
  };

  const handleRename = async (id: string, newName: string) => {
    try {
      const isSocialMedia = socialMediaAssistants.some((a) => a._id === id);
      if (isSocialMedia) {
        await updateAssistant({
          _id: id,
          data: { name: newName },
          accessId: delegationAccessId,
        });
      } else {
        await onRename(id, newName);

        // TODO: Replace with RTK api notification middleware
        addSnackbar({
          title: 'Assistant Renamed',
          message: 'Assistant name was successfully updated.',
          pVariant: 'success',
        });
      }
    } catch (error: any) {
      console.error('Error renaming assistant:', error);
      // TODO: add snackbar for when sequence-based assistant can't be renamed (RTK api notification middleware)
    }
  };

  // Compute pause/resume dialog labels once to avoid repeated logic below.
  const pauseToggleIsActive = assistantToToggle ? isAssistantActive(assistantToToggle) : true;
  const pauseToggleConfirmTitle = pauseToggleIsActive ? 'Confirm Pause' : 'Confirm Resume';
  const pauseToggleActionLabel = pauseToggleIsActive ? 'Pause' : 'Resume';
  const pauseToggleDescription = pauseToggleIsActive ? (
    <>
      Are you sure you want to pause the assistant <strong>{assistantToToggle?.name}</strong>?
    </>
  ) : (
    <>
      Are you sure you want to resume the assistant <strong>{assistantToToggle?.name}</strong>?
    </>
  );

  return (
    <TooltipProvider>
      <div>
        {/* Delete Confirmation */}
        <AlertDialog
          open={assistantToDelete !== null}
          onOpenChange={(open) => {
            if (!open) setAssistantToDelete(null);
          }}
        >
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Are you sure?</AlertDialogTitle>
              <AlertDialogDescription>
                This action cannot be undone. This will permanently delete the assistant{' '}
                <strong>{assistantToDelete?.name}</strong>.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel onClick={handleCancelDelete}>Cancel</AlertDialogCancel>
              <AlertDialogAction onClick={handleConfirmDelete}>Delete</AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>

        {/* Pause/Resume Confirmation */}
        <AlertDialog
          open={assistantToToggle !== null}
          onOpenChange={(open) => {
            if (!open) setAssistantToToggle(null);
          }}
        >
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>{pauseToggleConfirmTitle}</AlertDialogTitle>
              <AlertDialogDescription>{pauseToggleDescription}</AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel onClick={handleCancelPauseToggle}>Cancel</AlertDialogCancel>
              <AlertDialogAction onClick={handleConfirmPauseToggle}>{pauseToggleActionLabel}</AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>

        {/* Main Accordion (HEAD style) */}
        <AccordionPrimitive.Root type="multiple" value={mainAccordionValue} onValueChange={handleMainAccordionChange}>
          <AccordionPrimitive.Item value="assistants">
            <AccordionPrimitive.Header className="flex">
              <AccordionPrimitive.Trigger
                className={cn(
                  'flex flex-1 items-center justify-between gap-l-2 rounded-lg px-4 py-[14px] text-sm hover:bg-gray-100',
                  'text-gray-600',
                )}
              >
                <div className="flex items-center gap-2">
                  <TypeIcon className="h-[18px] w-[18px]" />
                  <span className="text-sm font-bold">{typeLabel}</span>
                  <motion.div
                    animate={mainAccordionValue.includes('assistants') ? 'expanded' : 'collapsed'}
                    variants={chevronAnimation}
                    className="shrink-0"
                  >
                    <ChevronDown className="h-5 w-5 transition-transform duration-200" />
                  </motion.div>
                </div>
                <div className="flex items-center gap-2">
                  {/* AssistantsFilter from HEAD */}
                  {/* <div onClick={(e) => e.stopPropagation()} className="my-2 flex items-end justify-end">
                    <AssistantsFilter />
                  </div> */}
                  {/* + Button to create a new Assistant */}
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div className="hover:bg-gray-100 transition-colors">
                        <Plus
                          className="h-5 w-5 cursor-pointer text-gray-500 hover:text-gray-900"
                          onClick={handleCreateNewAssistant}
                        />
                      </div>
                    </TooltipTrigger>
                    <TooltipContent>{getAssistantTypeDescription(desiredType)}</TooltipContent>
                  </Tooltip>
                </div>
              </AccordionPrimitive.Trigger>
            </AccordionPrimitive.Header>
            <AccordionPrimitive.Content forceMount asChild>
              <motion.div
                initial="collapsed"
                animate={mainAccordionValue.includes('assistants') ? 'expanded' : 'collapsed'}
                variants={contentAnimation}
                className="overflow-hidden"
              >
                <div className="ml-4 space-y-2">
                  <div>
                    {(desiredType === AssistantType.SOCIAL_MEDIA
                      ? assistants.filter((assistant) => assistant?.type === desiredType)
                      : assistants
                    )?.map((assistant) => (
                      <AssistantItem
                        key={assistant._id}
                        assistant={assistant}
                        onPauseToggle={handlePauseToggleClick}
                        onDeleteClick={handleDeleteClick}
                        onRename={handleRename}
                        onAssistantClick={handleAssistantClick}
                        onSettingsClick={() => handleSettingsClick(assistant)}
                        onAddContactsClick={() => handleAddContactsClick(assistant)}
                        selectedAssistantId={selectedAssistantId}
                        setSelectedAssistantId={(id: string | null) => dispatch(setSelectedAssistantId(id))}
                      />
                    ))}
                  </div>
                </div>
              </motion.div>
            </AccordionPrimitive.Content>
          </AccordionPrimitive.Item>
        </AccordionPrimitive.Root>

        {/* Sequence Modal (HEAD) */}
        {!initialLoading && !strategyLoading && !initialListLoading && (
          <SequenceModal
            open={modalOpen}
            onClose={closeSequenceModal}
            setCreatedSequenceId={setCreatedSequenceId}
            templates={defaultTemplates}
            templateSteps={stepText}
            strategies={strategies}
            targetAudience={targetAudience}
            availableList={availableList}
            selectedGoal={{ goal: { id: getGoalIdFromType(desiredType) } }}
            strategy={strategy}
          />
        )}

        {/* Edit Steps Modal (HEAD) */}
        {editOpen && assistantToEdit && (
          <EditModal
            open={editOpen}
            onClose={() => {
              setEditOpen(false);
              setAssistantToEdit(null);
            }}
            activeItem={assistantToEdit}
            // HEAD code: template => assistantToEdit.template
            // BUT from feature code: we passed entire assistant as template
            // If your code needs it exactly from HEAD, do:
            template={assistantToEdit.template}
            onRefresh={(seqId) => refreshAfterChange(seqId)}
          />
        )}

        {/* Add Contacts Modal (HEAD) */}
        {addContactsOpen && assistantForContacts && (
          <AddContactsModal
            open={addContactsOpen}
            onClose={() => {
              setAddContactsOpen(false);
              setAssistantForContacts(null);
            }}
            source="sequence"
            sequenceItem={assistantForContacts}
            handleModification={() => {
              setAddContactsOpen(false);
              setAssistantForContacts(null);
              refreshAfterChange(assistantForContacts._id);
            }}
            targetAudience={targetAudience}
            existingList={availableList}
          />
        )}
      </div>
    </TooltipProvider>
  );
};

export default AssistantsSection;
