'use client';

import * as React from 'react';
import * as ToastPrimitives from '@radix-ui/react-toast';
import { cva, type VariantProps } from 'class-variance-authority';
import { X } from 'lucide-react';

import { cn } from '@/lib/utils';

const ToastProvider = ToastPrimitives.Provider;

const ToastViewport = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Viewport>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Viewport>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Viewport
    ref={ref}
    className={cn(
      'fixed',
      'top-0',
      'z-[1400]', // MUI dialogs are at z-[1300]; this is necessary for anything using PModal
      'flex',
      'max-h-screen',
      'w-full',
      'flex-col-reverse',
      'p-4',
      'sm:bottom-0',
      'sm:right-0',
      'sm:top-auto',
      'sm:flex-col',
      'md:max-w-[420px]',
      className,
    )}
    {...props}
  />
));
ToastViewport.displayName = ToastPrimitives.Viewport.displayName;

const toastBaseClasses = cn(
  'group',
  'pointer-events-auto',
  'relative',
  'flex',
  'w-full',
  'items-center',
  'justify-between',
  'space-x-4',
  'overflow-hidden',
  'rounded-md',
  'border',
  'p-6',
  'pr-8',
  'shadow-lg',
  'transition-all',
  'data-[swipe=cancel]:translate-x-0',
  'data-[swipe=end]:translate-x-[var(--radix-toast-swipe-end-x)]',
  'data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)]',
  'data-[swipe=move]:transition-none',
  'data-[state=open]:animate-in',
  'data-[state=closed]:animate-out',
  'data-[swipe=end]:animate-out',
  'data-[state=closed]:fade-out-80',
  'data-[state=closed]:slide-out-to-right-full',
  'data-[state=open]:slide-in-from-top-full',
  'data-[state=open]:sm:slide-in-from-bottom-full',
);

const toastVariants = cva(toastBaseClasses, {
  variants: {
    variant: {
      default: 'border bg-background text-foreground',
      destructive: 'group border-destructive bg-destructive text-destructive-foreground',
      success: 'group border-success bg-success text-success-foreground',
      warning: 'group border-warning bg-warning text-warning-foreground',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

const Toast = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Root> & VariantProps<typeof toastVariants>
>(({ className, variant, ...props }, ref) => {
  return <ToastPrimitives.Root ref={ref} className={cn(toastVariants({ variant }), className)} {...props} />;
});
Toast.displayName = ToastPrimitives.Root.displayName;

const ToastAction = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Action>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Action>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Action
    ref={ref}
    className={cn(
      'inline-flex',
      'h-8',
      'shrink-0',
      'items-center',
      'justify-center',
      'rounded-md',
      'border',
      'bg-transparent',
      'px-3',
      'text-sm',
      'font-medium',
      'ring-offset-background',
      'transition-colors',
      'hover:bg-secondary',
      'focus:outline-none',
      'focus:ring-2',
      'focus:ring-ring',
      'focus:ring-offset-2',
      'disabled:pointer-events-none',
      'disabled:opacity-50',
      'group-[.destructive]:border-muted/40',
      'group-[.destructive]:hover:border-destructive/30',
      'group-[.destructive]:hover:bg-destructive',
      'group-[.destructive]:hover:text-destructive-foreground',
      'group-[.destructive]:focus:ring-destructive',
      className,
    )}
    {...props}
  />
));
ToastAction.displayName = ToastPrimitives.Action.displayName;

const ToastClose = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Close>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Close> & { duration?: number }
>(({ className, duration = 4000, ...props }, ref) => (
  <ToastPrimitives.Close
    ref={ref}
    className={cn(
      'absolute',
      'right-2',
      'top-2',
      'rounded-md',
      'p-1',
      'text-foreground/50',
      'hover:text-foreground',
      'focus:outline-none',
      'focus:ring-2',
      'group-[.destructive]:text-red-300',
      'group-[.destructive]:hover:text-red-50',
      'group-[.destructive]:focus:ring-red-400',
      'group-[.destructive]:focus:ring-offset-red-600',
      'group-[.success]:text-green-300',
      'group-[.success]:hover:text-green-50',
      'group-[.success]:focus:ring-green-400',
      'group-[.success]:focus:ring-offset-green-600',
      'group-[.warning]:text-yellow-300',
      'group-[.warning]:hover:text-yellow-50',
      'group-[.warning]:focus:ring-yellow-400',
      'group-[.warning]:focus:ring-offset-yellow-600',
      className,
    )}
    toast-close=""
    {...props}
  >
    <div className="relative inline-block">
      <X className="h-4 w-4 relative z-10 m-1" />
      <svg
        className="absolute inset-0 w-full h-full transform -rotate-90 countdown-circle"
        viewBox="0 0 16 16"
        style={{ '--duration': `${duration}ms` } as React.CSSProperties}
      >
        <circle
          cx="8"
          cy="8"
          r="7"
          stroke="currentColor"
          strokeWidth="1.25"
          fill="transparent"
          strokeDasharray="44"
          strokeDashoffset="0"
        />
      </svg>
    </div>
  </ToastPrimitives.Close>
));
ToastClose.displayName = ToastPrimitives.Close.displayName;

const ToastTitle = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Title>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Title>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Title ref={ref} className={cn('text-sm', 'font-semibold', className)} {...props} />
));
ToastTitle.displayName = ToastPrimitives.Title.displayName;

const ToastDescription = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Description>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Description>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Description ref={ref} className={cn('text-sm', 'opacity-90', className)} {...props} />
));
ToastDescription.displayName = ToastPrimitives.Description.displayName;

export type ToastProps = React.ComponentPropsWithoutRef<typeof Toast>;
export type ToastActionElement = React.ReactElement<typeof ToastAction>;

export { ToastProvider, ToastViewport, Toast, ToastTitle, ToastDescription, ToastClose, ToastAction };
