import { useEffect, useState, useCallback, useMemo } from 'react';
import { Stack } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { setSettingsPageOverride } from '@/redux/authSlice';

import Bio from './components/bio';
import CRM from './components/crm';
import Personas from './components/personas';
import Social from './components/social';
import Tone from './components/tone';
import Topics from './components/topics';
import Support from './components/support';
import Http from '../../http/httpClient';
import { useIsFeatureFlagEnabled } from '@Hooks/useFeatureFlags';
import Lists from '../Lists/lists';
import IdealCustomerProfile from './components/IdealCustomerProfile';
import PSectionNav from '../../components/library/PSectionNav';
import PButton from '../../components/library/PButton';
import Email from '@/pages/settings/components/email';
import Teams from '../teams/teams';

const Settings = () => {
  const dispatch = useDispatch();

  const [isChangesSaved, setIsChangesSaved] = useState(true);
  const [showToast, setShowToast] = useState(false);
  const idealCustomerProfileEnabled = useIsFeatureFlagEnabled('ui.idealCustomerProfile');
  const [currPage, setCurrPage] = useState({
    title: 'About',
    page: <Bio setIsChangesSaved={setIsChangesSaved} />,
  });

  const settingsPageOverride = useSelector((state) => state.auth.settingsPageOverride);
  const featureFlags = useSelector((state) => state.auth.featureFlags);

  // Define the navigation handler
  const handleNavigation = useCallback(
    (label, pageComponent) => {
      if (!isChangesSaved) {
        setShowToast(true);
      } else {
        setCurrPage({ title: label, page: pageComponent });
      }
    },
    [isChangesSaved],
  );

  // Compute settings options
  const settingsOptions = useMemo(() => {
    const options = [
      {
        label: 'About',
        onClick: () => handleNavigation('About', <Bio setIsChangesSaved={setIsChangesSaved} />),
        active: currPage.title === 'About',
      },
      {
        label: 'Personas',
        onClick: () => handleNavigation('Personas', <Personas />),
        active: currPage.title === 'Personas',
      },
      {
        label: 'Tone',
        onClick: () => handleNavigation('Tone', <Tone />),
        active: currPage.title === 'Tone',
      },
      // {
      //   label: 'Topics',
      //   onClick: () => handleNavigation('Topics', <Topics />),
      //   active: currPage.title === 'Topics',
      // },
      {
        label: 'CRM',
        onClick: () => handleNavigation('CRM', <CRM />),
        active: currPage.title === 'CRM',
      },
      {
        label: 'Social',
        onClick: () => handleNavigation('Social', <Social />),
        active: currPage.title === 'Social',
      },
      ...(featureFlags?.find((x) => x.name === 'sequences')?.isFeatureEnabled
        ? [
            {
              label: 'List',
              onClick: () => handleNavigation('List', <Lists />),
              active: currPage.title === 'List',
            },
          ]
        : []),
      ...(featureFlags?.find((x) => x.name === 'userEmailOptions')?.isFeatureEnabled
        ? [
            {
              label: 'Email',
              onClick: () => handleNavigation('Email', <Email />),
              active: currPage.title === 'Email',
            },
          ]
        : []),
      ...(idealCustomerProfileEnabled
        ? [
            {
              label: 'Customer Profiles',
              onClick: () => handleNavigation('Customer Profiles', <IdealCustomerProfile />),
              active: currPage.title === 'Customer Profiles',
            },
          ]
        : []),
      {
        label: 'Teams',
        onClick: () => handleNavigation('Teams', <Teams />),
        active: currPage.title === 'Teams',
      },
      {
        label: 'Support',
        onClick: () => handleNavigation('Support', <Support />),
        active: currPage.title === 'Support',
      },
    ];

    return options;
  }, [currPage.title, featureFlags, idealCustomerProfileEnabled, handleNavigation, isChangesSaved, setIsChangesSaved]);

  useEffect(() => {
    if (settingsPageOverride) {
      const targetOption = settingsOptions.find((option) => option.label === settingsPageOverride);
      if (targetOption) {
        targetOption.onClick();
      }
      dispatch(setSettingsPageOverride(null));
    }
  }, [dispatch, settingsOptions, settingsPageOverride]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get('tab');

    if (tab) {
      const targetOption = settingsOptions.find((option) => option.label.toLowerCase() === tab.toLowerCase());
      if (targetOption) {
        targetOption.onClick();
      }
    }
  }, [settingsOptions]);

  useEffect(() => {
    if (!isChangesSaved && showToast) {
      setCurrPage({
        title: 'About',
        page: <Bio setIsChangesSaved={setIsChangesSaved} showToast={true} />,
      });

      setTimeout(() => {
        setCurrPage({
          title: 'About',
          page: <Bio setIsChangesSaved={setIsChangesSaved} showToast={false} />,
        });
        setShowToast(false);
      }, 4000);
    }
  }, [showToast, isChangesSaved]);

  const handleSignout = () => {
    Http.postData('/auth/logout').then(() => {
      try {
        if (window?.location?.hostname === 'postilize-stage.herokuapp.com') {
          window.checksumai?.reset();
        }
      } catch (error) {
        console.error('Failed to reset checksumai user');
      }
      window.location.href = '/';
    });
  };

  return (
    <Stack direction="row" sx={{ height: '100%' }}>
      <PSectionNav pVariant="primary" menu={settingsOptions} width="325px">
        <div
          style={{
            width: '100%',
            display: 'flex',
            paddingTop: '8px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          className="mb-4"
        >
          <PButton onClick={handleSignout} className="w-full" pVariant="secondary">
            Sign out
          </PButton>
        </div>
      </PSectionNav>

      <div
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        {currPage?.page || null}
      </div>
    </Stack>
  );
};

export default Settings;
