import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';

interface ConfirmationDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onConfirm: () => void;
  onCancel: () => void;
}

export function TutorialConfirmationDialog({ open, onOpenChange, onConfirm, onCancel }: ConfirmationDialogProps) {
  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent className="max-w-[400px]">
        <AlertDialogHeader>
          <AlertDialogTitle className="text-[#14161A] text-xl font-semibold">
            Proceed without completing setup?
          </AlertDialogTitle>
          <AlertDialogDescription className="text-[#4B5563] text-base">
            These configurations are important for your account to work with Postilize.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter className="mt-6">
          <AlertDialogCancel className="text-[#4B5563] hover:text-[#14161A] hover:bg-transparent" onClick={onCancel}>
            No
          </AlertDialogCancel>
          <AlertDialogAction
            className="bg-[#14161A] text-white hover:bg-[#14161A]/90 rounded-lg px-6"
            onClick={onConfirm}
          >
            Yes
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
