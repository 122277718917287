/**
 * This context provider is used to display Snackbar messages to the user.
 *
 * It allows queueing multiple messages and displaying them sequentially,
 * solving the issue of multiple Snackbars overlapping, hiding each other.
 *
 **/

import React, { createContext, useContext } from 'react';
import { toast } from '@/hooks/use-toast';
import { Toaster } from '../components/toaster';

/**
 * SnackbarContext is now a thin wrapper that delegates notifications
 * to the new toast hook. This allows components using the context to
 * simply call addSnackbar without having to manage notification state.
 */
const SnackbarContext = createContext({
  addSnackbar: () => {},
});

export const useSnackbar = () => useContext(SnackbarContext);

// Add this at the top of the file
let globalAddSnackbar = () => {};

export const setGlobalAddSnackbar = (fn) => {
  globalAddSnackbar = fn;
};

/**
 * SnackbarProvider provides the addSnackbar function and renders the Toaster.
 * It maps the legacy snackbar options onto the toast hook and supports an optional callback.
 *
 * This is a drop‑in replacement (non‑breaking) for modules that already consume the context.
 */
export const SnackbarProvider = ({ children, commentProp }) => {
  const addSnackbar = ({
    message = '',
    pVariant = 'default',
    duration = 4000,
    onClose = null,
    transitionType = 'fade',
    hideSeverityIcon = false,
    hideAction = false,
    customAction = null,
    title = undefined,
  }) => {
    // Map legacy variants to the toast hook's variant choices.
    const variantMapping = {
      success: 'success',
      destructive: 'destructive',
      neutral: 'default',
      default: 'default',
      warning: 'warning',
    };

    toast({
      title,
      description: message,
      variant: variantMapping[pVariant] || 'default',
      duration,
    });

    // Optionally call the onClose callback after the given duration.
    if (onClose) {
      setTimeout(onClose, duration);
    }
  };

  // Store the addSnackbar function globally
  React.useEffect(() => {
    setGlobalAddSnackbar(addSnackbar);
    return () => {
      setGlobalAddSnackbar(() => {});
    };
  }, []);

  return (
    <SnackbarContext.Provider value={{ addSnackbar }}>
      {children}
      <Toaster />
    </SnackbarContext.Provider>
  );
};

export { globalAddSnackbar };
