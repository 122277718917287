import { useCallback, useState, useRef, useEffect } from 'react';
import { Topic } from '@/constants/types/assistant';
import { socialMediaAssistantsApi } from '@/helper/APIs/SocialMediaAssistantsApi';
import { useSnackbar } from '@/contexts/SnackbarContext';
import { ASSISTANT, areStringsEqualIgnoreCase, isValidTopicName } from '@postilize/shared';

/**
 * Props for the useTopicSelection hook
 * @interface UseTopicSelectionProps
 * @property {Topic[]} [selectedTopics] - Array of currently selected topics
 * @property {(topics: Topic[]) => void} [onTopicsChange] - Callback when topics are modified
 * @property {number} [maxSelectedTopics] - Maximum number of topics allowed
 */
export type UseTopicSelectionProps = {
  selectedTopics?: Topic[];
  onTopicsChange?: (topics: Topic[]) => void;
  maxSelectedTopics?: number;
};

/**
 * Custom hook for managing topic selection functionality
 *
 * Provides comprehensive topic management including:
 * - AI-assisted topic generation
 * - Manual topic entry
 * - Topic validation and deduplication
 * - Suggested topics management
 * - Topic limit enforcement
 * - Error handling and user feedback
 *
 * @hook
 * @param {UseTopicSelectionProps} props - Configuration options
 * @returns {Object} Topic selection state and handlers
 *
 * @example
 * ```tsx
 * const {
 *   selectedTopics,
 *   handleTopicSubmission,
 *   addSelectedTopic,
 *   removeSelectedTopic
 * } = useTopicSelection({
 *   maxSelectedTopics: 5,
 *   onTopicsChange: handleChange
 * });
 * ```
 */
export function useTopicSelection({
  selectedTopics = [],
  onTopicsChange,
  maxSelectedTopics = ASSISTANT.TOPIC_LIMIT,
}: UseTopicSelectionProps) {
  const { addSnackbar } = useSnackbar();
  const [prompt, setPrompt] = useState('');
  const [suggestedTopics, setSuggestedTopics] = useState<Topic[]>([]);
  const [mode, setMode] = useState<'ai' | 'manual'>('ai');
  const [isInfoExpanded, setIsInfoExpanded] = useState(false);
  const previousTopicCountRef = useRef(selectedTopics.length);
  const initialRenderRef = useRef(true);

  /**
   * RTK Query mutation for generating topics
   * @type {[Function, { isLoading: boolean }]}
   */
  const [generateTopics, { isLoading }] = socialMediaAssistantsApi.useGenerateTopicsMutation();

  /**
   * Adds a topic to the selected list if valid and not duplicate.
   * For manual topics, frontend validation is performed before calling this.
   *
   * @function addSelectedTopic
   * @param {Topic} topic - Topic to add
   */
  const addSelectedTopic = useCallback(
    (topic: Topic) => {
      if (selectedTopics.length >= maxSelectedTopics) {
        addSnackbar({
          title: 'Topic Limit Reached',
          message: 'You have reached the maximum topic limit.',
          pVariant: 'warning',
        });
        return;
      }
      if (selectedTopics.some((t) => areStringsEqualIgnoreCase(t.name, topic.name))) {
        addSnackbar({
          title: 'Duplicate Topic',
          message: 'Topic names must be unique. Please modify your entry.',
          pVariant: 'destructive',
        });
        return;
      }
      const newTopics = [...selectedTopics, topic];
      onTopicsChange?.(newTopics);
      setSuggestedTopics((prev) => prev.filter((t) => !areStringsEqualIgnoreCase(t.name, topic.name)));
    },
    [maxSelectedTopics, onTopicsChange, selectedTopics, addSnackbar],
  );

  /**
   * Removes a topic from selected list and optionally adds to suggestions.
   *
   * @function removeSelectedTopic
   * @param {Topic} topicToRemove - Topic to remove
   */
  const removeSelectedTopic = useCallback(
    (topicToRemove: Topic) => {
      const newTopics = selectedTopics.filter((t) => !areStringsEqualIgnoreCase(t.name, topicToRemove.name));
      onTopicsChange?.(newTopics);
      setSuggestedTopics((prev) => {
        if (prev.some((t) => areStringsEqualIgnoreCase(t.name, topicToRemove.name))) {
          return prev;
        }
        return [topicToRemove, ...prev];
      });
    },
    [selectedTopics, onTopicsChange],
  );

  /**
   * Removes all selected topics and merges them into suggestions.
   *
   * @function removeAllSelectedTopics
   */
  const removeAllSelectedTopics = useCallback(() => {
    setSuggestedTopics((prev) => {
      const merged = [...selectedTopics, ...prev];
      return merged.filter(
        (topic, index, self) => index === self.findIndex((t) => areStringsEqualIgnoreCase(t.name, topic.name)),
      );
    });
    onTopicsChange?.([]);
  }, [selectedTopics, onTopicsChange]);

  /**
   * Fetches AI-generated topic suggestions based on prompt.
   * Only topics passing the shared validation are retained.
   *
   * @async
   * @function fetchTopicSuggestions
   * @param {string} prompt - User input for topic generation
   * @returns {Promise<boolean>} Success status of generation
   */
  const fetchTopicSuggestions = useCallback(
    async (prompt: string) => {
      try {
        const response = await generateTopics({ prompt });
        if (response.data?.topics?.length) {
          // Filter out any topics that fail validation.
          const newSuggestions = response.data.topics.filter(
            (topic) =>
              isValidTopicName(topic?.name?.trim()) &&
              !selectedTopics.some((selected) => areStringsEqualIgnoreCase(selected.name, topic.name)),
          );
          setSuggestedTopics((prev) => {
            const merged = [...prev, ...newSuggestions];
            return merged.filter(
              (topic, index, self) => index === self.findIndex((t) => areStringsEqualIgnoreCase(t.name, topic.name)),
            );
          });
          return true;
        }
        return false;
      } catch (error) {
        console.error('Failed to generate topics:', error);
        return false;
      }
    },
    [generateTopics, selectedTopics],
  );

  /**
   * Handles topic submission for both AI and manual modes.
   * For manual mode, the topic is validated and a small error message is shown if invalid.
   *
   * @async
   * @function handleTopicSubmission
   * @param {React.FormEvent} e - Form submission event
   * @param {'ai' | 'manual'} mode - Current input mode
   */
  const handleTopicSubmission = useCallback(
    async (e: React.FormEvent, mode: 'ai' | 'manual') => {
      e.preventDefault();
      const trimmedPrompt = prompt.trim();
      if (!trimmedPrompt) {
        addSnackbar({
          title: 'Invalid Input',
          message: 'Please enter a valid topic.',
          pVariant: 'destructive',
        });
        return;
      }
      if (selectedTopics.length >= maxSelectedTopics) {
        addSnackbar({
          title: 'Topic Limit Reached',
          message: 'You have reached the maximum topic limit.',
          pVariant: 'warning',
        });
        return;
      }

      try {
        if (mode === 'ai') {
          const success = await fetchTopicSuggestions(trimmedPrompt);
          if (!success) {
            addSnackbar({
              title: 'Generation Failed',
              message: 'Failed to generate topics. Please try again.',
              pVariant: 'destructive',
            });
            return;
          }
        } else {
          // For manual input, perform strict validation and show an error message if it fails.
          if (!isValidTopicName(trimmedPrompt)) {
            addSnackbar({
              title: 'Invalid Topic',
              message: 'A topic must be 3 to 50 valid characters.',
              pVariant: 'destructive',
            });
            return;
          }
          addSelectedTopic({ name: trimmedPrompt });
        }
        setPrompt('');
      } catch (error) {
        addSnackbar({
          title: 'Error',
          message: 'An error occurred while processing your request.',
          pVariant: 'destructive',
        });
        console.error('Error generating topics:', error);
      }
    },
    [prompt, selectedTopics.length, maxSelectedTopics, fetchTopicSuggestions, addSelectedTopic, addSnackbar],
  );

  /**
   * Adds a suggested topic to the selected list.
   * Silently discards topics that fail validation.
   *
   * @function handleSuggestedTopicAdd
   * @param {Topic} topic - Suggested topic to add
   */
  const handleSuggestedTopicAdd = useCallback(
    (topic: Topic) => {
      // Discard invalid suggested topics without alerting the user.
      if (!isValidTopicName(topic?.name?.trim())) {
        return;
      }

      if (selectedTopics.length >= maxSelectedTopics) {
        addSnackbar({
          title: 'Topic Limit Reached',
          message: 'You have reached the maximum topic limit.',
          pVariant: 'warning',
        });
        return;
      }

      if (selectedTopics.some((t) => areStringsEqualIgnoreCase(t.name, topic.name))) {
        addSnackbar({
          title: 'Duplicate Topic',
          message: `The topic "${topic.name}" already exists in this assistant.`,
          pVariant: 'destructive',
        });
        setSuggestedTopics((prev) => prev.filter((t) => !areStringsEqualIgnoreCase(t.name, topic.name)));
        return;
      }

      addSelectedTopic(topic);
      setSuggestedTopics((prev) => prev.filter((t) => !areStringsEqualIgnoreCase(t.name, topic.name)));
    },
    [selectedTopics, maxSelectedTopics, addSelectedTopic, addSnackbar],
  );

  /**
   * Toggles information panel expansion state
   * @function onToggleInfoExpansion
   */
  const onToggleInfoExpansion = useCallback(() => {
    setIsInfoExpanded((prev) => !prev);
  }, []);

  // Effects
  useEffect(() => {
    initialRenderRef.current = false;
  }, []);

  useEffect(() => {
    if (
      !initialRenderRef.current &&
      previousTopicCountRef.current === maxSelectedTopics - 1 &&
      selectedTopics.length === maxSelectedTopics
    ) {
      addSnackbar({ message: 'All topics selected!', pVariant: 'success' });
    }
    previousTopicCountRef.current = selectedTopics.length;
  }, [selectedTopics.length, maxSelectedTopics, addSnackbar]);

  return {
    selectedTopics,
    isLoading,
    prompt,
    addSelectedTopic,
    removeSelectedTopic,
    removeAllSelectedTopics,
    fetchTopicSuggestions,
    handlePromptChange: useCallback((value: string) => setPrompt(value), []),
    suggestedTopics,
    setSuggestedTopics,
    handleTopicSubmission,
    inputMode: mode,
    setInputMode: setMode,
    isInfoExpanded,
    onToggleInfoExpansion,
    handleSuggestedTopicAdd,
    initialRenderRef,
  };
}

export default useTopicSelection;
