import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useTheme } from '@mui/material';
import { alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { TUTORIAL_TASK_NAMES } from '@postilize/shared/constants/tutorial';

import Http from '../../../http/httpClient';

export default function UserResets() {
  const theme = useTheme();
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState('');
  const [users, setUsers] = React.useState([]);
  const [checkedItems, setCheckedItems] = React.useState({});
  const [showDeleteConfirmation, setShowDeleteConfirmation] = React.useState(false);
  const [tutorialTasks, setTutorialTasks] = React.useState(null);
  const [pendingTutorialChanges, setPendingTutorialChanges] = React.useState({});

  const enabledSteps = [
    'addConnectionsDone',
    'createSequenceDone',
    'addBioDone',
    'createListDone',
    'chooseStrategyDone',
    'connectLinkedInExtension',
    'connectMobileApp',
  ];

  const handleCheckboxChange = (itemName) => {
    const newCheckedItems = { ...checkedItems, [itemName]: !checkedItems[itemName] };
    setCheckedItems(newCheckedItems);
  };

  function mapObjectByKeys(originalObject, keysToMap) {
    return Object.keys(originalObject).reduce((newObject, key) => {
      if (keysToMap.includes(key)) {
        newObject[key] = originalObject[key];
      }
      return newObject;
    }, {});
  }

  const resetUserHandle = (event) => {
    event.preventDefault();
    if (selectedOption.length > 0) {
      setIsLoading(true);
      Http.postData('resetUser', { user_id: selectedOption })
        .then((res) => {
          // handle if anything required
          setSelectedOption('');
          alert('User data successfully deleted!!');
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
          handleDeleteDialogClose();
        });
    }
  };

  const updateUserSteps = (event) => {
    event.preventDefault();
    if (selectedOption.length > 0) {
      Http.postData('updateSetupSteps', { user_id: selectedOption, setupSteps: checkedItems })
        .then((res) => {
          alert('User setup steps successfully updated.');
          // Apply the changes back to the 'users' state
          setUsers((prev) => prev.map((u) => (u.id === selectedOption ? { ...u, setupSteps: checkedItems } : u)));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const fetchTutorialTasks = async (userId) => {
    try {
      const response = await Http.getData('tutorial/getTasksStatus', { userId });
      if (response && Object.keys(response).length > 0) {
        // Transform the response into a simpler structure
        const transformedTasks = {};
        Object.entries(response).forEach(([key, value]) => {
          transformedTasks[key] = value.completed;
        });
        setTutorialTasks(transformedTasks);
        // Reset any pending changes when fetching new data
        setPendingTutorialChanges({});
      } else {
        // Set to null if no tutorial data exists
        setTutorialTasks(null);
      }
    } catch (error) {
      console.error('Error fetching tutorial tasks status:', error);
      setTutorialTasks(null);
      setPendingTutorialChanges({});
    }
  };

  const handleTutorialTaskChange = (taskName) => {
    setPendingTutorialChanges((prev) => {
      const currentValue = prev.hasOwnProperty(taskName) ? prev[taskName] : tutorialTasks?.[taskName] || false;

      return {
        ...prev,
        [taskName]: !currentValue,
      };
    });
  };

  const saveTutorialChanges = async () => {
    try {
      const changedTasks = Object.entries(pendingTutorialChanges);
      if (changedTasks.length === 0) return;

      // Group tasks by their completion status
      const tasksToComplete = changedTasks.filter(([_, completed]) => completed).map(([task]) => task);
      const tasksToUncomplete = changedTasks.filter(([_, completed]) => !completed).map(([task]) => task);

      // Make API calls for each group (complete: true/false)
      const updates = [];
      if (tasksToComplete.length > 0) {
        updates.push(
          Http.putData('tutorial/updateTasksStatus', {
            data: {
              userId: selectedOption,
              taskNames: tasksToComplete,
              completed: true,
            },
          }),
        );
      }
      if (tasksToUncomplete.length > 0) {
        updates.push(
          Http.putData('tutorial/updateTasksStatus', {
            data: {
              userId: selectedOption,
              taskNames: tasksToUncomplete,
              completed: false,
            },
          }),
        );
      }

      await Promise.all(updates);

      // Update local state after successful API calls
      setTutorialTasks((prev) => ({
        ...prev,
        ...pendingTutorialChanges,
      }));
      setPendingTutorialChanges({});
    } catch (error) {
      console.error('Error updating tutorial tasks status:', error);
    }
  };

  const handleOptionChange = async (event) => {
    const userId = event.target.value;
    if (!userId) {
      setSelectedOption('');
      setTutorialTasks(null);
      return;
    }

    setSelectedOption(userId);
    setCheckedItems(mapObjectByKeys(users.find((u) => u.id === userId).setupSteps, enabledSteps));

    fetchTutorialTasks(userId);
  };

  const handleDeleteDialogOpen = (event) => {
    if (!selectedOption) return;
    setShowDeleteConfirmation(true);
  };
  const handleDeleteDialogClose = (_event) => {
    setShowDeleteConfirmation(false);
  };

  const detailedPersonLabel = (userId) => {
    const user = users.find((u) => u.id === userId);
    if (!user) return `Could not find '${userId}'`;
    return `${user.name} - ${user.email}`;
  };

  React.useEffect(() => {
    const fetchAvailableUsers = async () => {
      Http.getData('users/list')
        .then((res) => {
          const resUsers = [];
          res.users.map((item) => {
            resUsers.push({ id: item.id, name: item.name, setupSteps: item.setupSteps, email: item.email });
          });
          setUsers(resUsers);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    fetchAvailableUsers();
  }, []);

  return (
    <>
      <Box display="flex" alignItems="center" gap={2} flexDirection="row">
        <Box display="flex" alignItems="center" gap={2} sx={{ marginLeft: 'none' }}>
          <Select value={selectedOption} onChange={handleOptionChange} sx={{ minWidth: 200 }}>
            {users.map((user) => (
              <MenuItem key={user.id} value={user.id}>
                {user.name} - {user.email || 'no email'}
              </MenuItem>
            ))}
          </Select>
          <Button
            variant="contained"
            color="error"
            sx={{
              mt: 3,
              ml: 1,
              px: 3,
              my: 2,
              fontSize: '1.2rem',
              fontWeight: 'bold',
              textTransform: 'none',
              fontFamily: 'Poppins',
            }}
            onClick={handleDeleteDialogOpen}
          >
            {isLoading ? (
              <Box
                sx={{
                  position: 'relative',
                  width: '52px',
                  height: '34px',
                  display: 'flex',
                  borderRadius: 16,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress color="inherit" />
              </Box>
            ) : (
              'Delete'
            )}
          </Button>
        </Box>
        <Dialog
          open={showDeleteConfirmation}
          onClose={handleDeleteDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete User?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete {detailedPersonLabel(selectedOption)}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteDialogClose} color="info" variant="contained">
              Cancel
            </Button>
            <Button onClick={resetUserHandle} autoFocus color="error" variant="contained">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <Box display="flex" justifyContent="center" flexDirection="column">
        <FormHelperText sx={{ color: 'red' }}>
          * This will erase all the data which is related to the selected user.
        </FormHelperText>
      </Box>
      {selectedOption && (
        <Box sx={{ p: 2, border: '1px dashed grey' }}>
          <FormGroup>
            {Object.entries(checkedItems).map(([item, done]) => (
              <FormControlLabel
                key={item}
                control={<Switch color="success" checked={done} onChange={() => handleCheckboxChange(item)} />}
                label={item}
              />
            ))}
          </FormGroup>
          <Button
            sx={{
              mt: 3,
              ml: 1,
              px: 3,
              my: 2,
              backgroundColor: '#3a9fbf',
              color: '#ffffff',
              fontSize: '1rem',
              textTransform: 'none',
              fontFamily: 'Poppins',
            }}
            onClick={updateUserSteps}
          >
            Update setup steps
          </Button>
        </Box>
      )}
      {selectedOption && (
        <Box sx={{ mt: 4, p: 2, border: '1px dashed grey' }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Tutorial Progress
          </Typography>
          {tutorialTasks ? (
            <>
              <FormGroup>
                {Object.keys(TUTORIAL_TASK_NAMES).map((taskName) => {
                  const isCompleted = pendingTutorialChanges.hasOwnProperty(taskName)
                    ? pendingTutorialChanges[taskName]
                    : tutorialTasks?.[taskName] ?? false;

                  return (
                    <FormControlLabel
                      key={taskName}
                      control={
                        <Switch
                          color="success"
                          checked={isCompleted}
                          onChange={() => handleTutorialTaskChange(taskName)}
                        />
                      }
                      label={taskName}
                    />
                  );
                })}
              </FormGroup>
              <Button
                sx={{
                  mt: 3,
                  ml: 1,
                  px: 3,
                  my: 2,
                  backgroundColor: '#3a9fbf',
                  color: '#ffffff',
                  fontSize: '1rem',
                  textTransform: 'none',
                  fontFamily: 'Poppins',
                }}
                onClick={saveTutorialChanges}
                disabled={Object.keys(pendingTutorialChanges).length === 0}
              >
                Save Tutorial Progress
              </Button>
            </>
          ) : (
            <Button
              sx={{
                mt: 3,
                ml: 1,
                px: 3,
                my: 2,
                backgroundColor: '#3a9fbf',
                color: '#ffffff',
                fontSize: '1rem',
                textTransform: 'none',
                fontFamily: 'Poppins',
              }}
              onClick={async () => {
                await Http.putData('tutorial/create', { data: { userId: selectedOption } });
                // Fetch tutorial tasks after creating them
                fetchTutorialTasks(selectedOption);
              }}
            >
              Create Tutorial
            </Button>
          )}
        </Box>
      )}
    </>
  );
}
