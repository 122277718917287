import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { Info } from 'lucide-react';

type TopicSelectionProgressProps = {
  selectedCount: number;
  maxTopics: number;
};

export function TopicSelectionProgress({ selectedCount, maxTopics }: TopicSelectionProgressProps) {
  const getSegmentColor = (index: number) => {
    if (index >= selectedCount) return 'bg-gray-100';
    const progress = index / maxTopics;
    if (progress < 0.33) return 'bg-gray-300';
    if (progress < 0.66) return 'bg-gray-600';
    return 'bg-gray-900';
  };

  const getMessage = () => {
    const remaining = maxTopics - selectedCount;
    const topicText = remaining === 1 ? 'topic' : 'topics';

    if (selectedCount === 0) return `Choose ${maxTopics} topics that resonate with your audience`;
    if (selectedCount === maxTopics) return "Great choices! You're all set!";
    if (selectedCount < maxTopics / 2) return `Good start, keep going! Select ${remaining} more ${topicText}`;
    return `Almost there! Just ${remaining} more ${topicText} to go`;
  };

  return (
    <div className="pb-2 space-y-4">
      <div className="text-base w-full flex items-center justify-between gap-2" role="status" aria-live="polite">
        <div className="flex items-center justify-between gap-2 w-full">
          <span>{getMessage()}</span>
          <Tooltip>
            <TooltipTrigger asChild>
              <Info className="h-4 w-4 text-muted-foreground cursor-help hover:text-blue-600 transition-colors" />
            </TooltipTrigger>
            <TooltipContent className="relative w-[320px] rounded-lg border border-blue-200 bg-blue-50 p-4">
              <div className="mb-1 font-bold leading-none tracking-tight">Did you know?</div>
              <div className="text-sm">
                Your most successful peers share an average of 3 posts each week. Join them!
              </div>
            </TooltipContent>
          </Tooltip>
        </div>
      </div>

      {/* 
      TODO: Fix this and add back in
      <Tooltip
        content={
          selectedCount === maxTopics
            ? 'All topics selected!'
            : `${maxTopics - selectedCount} more ${maxTopics - selectedCount === 1 ? 'topic' : 'topics'} to go`
        }
      >
        <div className="relative flex gap-1">
          {[...Array(maxTopics)].map((_, i) => (
            <motion.div
              key={i}
              className={`flex-1 h-2 rounded-sm transition-colors duration-300 ${getSegmentColor(i)}`}
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.2, delay: i * 0.05 }}
            />
          ))}
        </div>
      </Tooltip> */}
    </div>
  );
}
