import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import { useTheme, Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import CustomModal from '../../login/components/confirmModal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  maxHeight: '80vh',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius: '5px',
  boxShadow: 2,
  display: 'flex',
  flexDirection: 'column',
};

const CheckboxItem = ({ name, label, checked, onChange }) => {
  const theme = useTheme();

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          name={name}
          onChange={() => onChange(name)}
          sx={{
            'color': theme.palette.primary.blue,
            '&.Mui-checked': {
              color: theme.palette.primary.blue,
              backgroundColor: 'white',
            },
            '&.Mui-disabled': {
              color: theme.palette.primary.lightGrey,
              backgroundColor: 'white',
            },
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 255, 0.5)',
            },
          }}
        />
      }
      label={label}
      sx={{
        '& .MuiTypography-root': {
          color: theme.palette.primary.black,
          fontSize: '12px',
          fontWeight: 500,
        },
        'marginRight': 6,
      }}
    />
  );
};

// Add this new component for indented checkbox items
const IndentedCheckboxItem = ({ name, label, checked, onChange }) => {
  const theme = useTheme();

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          name={name}
          onChange={() => onChange(name)}
          sx={{
            'color': theme.palette.primary.blue,
            '&.Mui-checked': {
              color: theme.palette.primary.blue,
              backgroundColor: 'white',
            },
            '&.Mui-disabled': {
              color: theme.palette.primary.lightGrey,
              backgroundColor: 'white',
            },
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 255, 0.5)',
            },
          }}
        />
      }
      label={label}
      sx={{
        '& .MuiTypography-root': {
          color: theme.palette.primary.black,
          fontSize: '12px',
          fontWeight: 500,
        },
        'marginRight': 6,
        'marginLeft': '24px', // Add indentation
      }}
    />
  );
};

export default function CompanyPreferenceModal({ open, onClose, company }) {
  const theme = useTheme();
  const [goals, setGoals] = React.useState(company.goals || {});
  const [audience, setAudience] = React.useState(company.targetAudience || {});
  const [companyState, setCompanyState] = React.useState(company);
  const [isValidEmail, setIsValidEmail] = React.useState(true);
  const [alertText, setAlertText] = React.useState('');
  const [validateModalOpen, setValidateModalOpen] = React.useState(false);
  const [adminInvite, setAdminInvite] = React.useState(company.adminInvite?.invitee || '');
  const [maxEmailCount, setMaxEmailCount] = React.useState(company.emailPreference.maxEmailCount);
  const [linkedInPreferences, setLinkedInPreferences] = React.useState({
    autoLikeColleagueLinkedInPost: company.autoLikeColleagueLinkedInPost || false,
    autoCommentColleagueLinkedInPost: company.autoCommentColleagueLinkedInPost || false,
    approvalRequiredCommentColleagueLinkedInPost: company.approvalRequiredCommentColleagueLinkedInPost || false,
  });

  const resetWithOriginalValues = () => {};

  const handleOnClose = () => {
    resetWithOriginalValues();
  };

  const handleGoalChange = (goal) => {
    setGoals((prevGoals) => ({
      ...prevGoals,
      [goal]: !prevGoals[goal],
    }));
  };

  const handleAudienceChange = (audienceType) => {
    setAudience((prevAudience) => ({
      ...prevAudience,
      [audienceType]: !prevAudience[audienceType],
    }));
  };

  const handleModalAction = (action) => {
    setValidateModalOpen(false);
    setAlertText('');
  };

  const handleEmailCount = (e) => {
    setMaxEmailCount(e.target.value);
  };

  const handleLinkedInPreferenceChange = (preferenceName) => {
    setLinkedInPreferences((prev) => ({
      ...prev,
      [preferenceName]: !prev[preferenceName],
    }));
  };

  return (
    <div>
      {validateModalOpen && <CustomModal clickAction={handleModalAction} alertText={alertText} />}
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="edit-user-modal-title"
        aria-describedby="edit-user-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ p: 2, borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
            <Typography id="edit-user-modal-title" variant="h6" component="h2">
              Company Preferences
            </Typography>
          </Box>
          <Box
            sx={{
              'flex': 1,
              'overflowY': 'auto',
              'p': 2,
              '&::-webkit-scrollbar': {
                width: '0.4em',
              },
              '&::-webkit-scrollbar-track': {
                background: '#f1f1f1',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#888',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                background: '#555',
              },
            }}
          >
            <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={6}>
                <Typography variant="h6" component="h2">
                  {company?.name}
                </Typography>
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={12}>
                <InputLabel>Goals</InputLabel>
                <FormGroup row>
                  <CheckboxItem name="grow" label="Grow" checked={goals.grow} onChange={handleGoalChange} />
                  <CheckboxItem name="retain" label="Retain" checked={goals.retain} onChange={handleGoalChange} />
                  <CheckboxItem name="acquire" label="Acquire" checked={goals.acquire} onChange={handleGoalChange} />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <InputLabel>Target Audience</InputLabel>
                <FormGroup row>
                  <CheckboxItem name="ai" label="Start with AI" checked={audience.ai} onChange={handleAudienceChange} />
                  <CheckboxItem
                    name="manualcontact"
                    label="Add people manually"
                    checked={audience.manualcontact}
                    onChange={handleAudienceChange}
                  />
                  <CheckboxItem
                    name="csv"
                    label="Import CSV file"
                    checked={audience.csv}
                    onChange={handleAudienceChange}
                  />
                  <CheckboxItem
                    name="crm"
                    label="From your CRM"
                    checked={audience.crm}
                    onChange={handleAudienceChange}
                  />
                  <CheckboxItem
                    name="existing"
                    label="Existing Lists"
                    checked={audience.existing}
                    onChange={handleAudienceChange}
                  />
                </FormGroup>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Emails Settings</InputLabel>
              <FormGroup row>
                <Box display="flex" flexDirection="row" gap={2} alignItems="center">
                  <Box component="span" mr={2}>
                    Max Sequence Emails Per Day
                  </Box>
                  <TextField
                    type="number"
                    InputProps={{
                      inputProps: {
                        min: 1,
                        style: {
                          height: '25px',
                        },
                      },
                    }}
                    onChange={handleEmailCount}
                    value={maxEmailCount}
                    sx={{
                      'minWidth': 100,
                      '.MuiInputBase-input': {
                        paddingTop: '5px',
                        paddingBottom: '5px',
                      },
                    }}
                  />
                </Box>
              </FormGroup>
            </Grid>
            <Box mt={4}>
              <Grid item xs={12}>
                <FormGroup row>
                  <TextField
                    id="maxUsers"
                    label="Max Users"
                    value={companyState?.maxUsers}
                    onChange={(e) => {
                      setCompanyState((prevState) => ({ ...prevState, maxUsers: e.target.value }));
                    }}
                  />
                  {/* <TextField
                    id='maxContactEnrichments'
                    label='Max Contact Enrichments'
                    value={companyState?.maxContactEnrichments}
                    onChange={(e) => {
                      setCompanyState(prevState => ({ ...prevState, maxContactEnrichments: e.target.value }));
                    }}
                  /> */}
                </FormGroup>
              </Grid>
            </Box>
            <Grid item xs={12}>
              <FormGroup>
                <TextField
                  id="adminInvite"
                  label="Admin Invite"
                  value={adminInvite}
                  onChange={(e) => {
                    let emailVal = e.target.value;
                    setAdminInvite(emailVal);
                    const isValid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailVal);
                    setIsValidEmail(isValid);
                  }}
                  error={!isValidEmail}
                />
              </FormGroup>
            </Grid>
            <Box mt={4}>
              <Grid item xs={12}>
                <InputLabel>LinkedIn Engagement Settings</InputLabel>
                <FormGroup>
                  <CheckboxItem
                    name="autoLikeColleagueLinkedInPost"
                    label="Auto-like colleague LinkedIn posts"
                    checked={linkedInPreferences.autoLikeColleagueLinkedInPost}
                    onChange={handleLinkedInPreferenceChange}
                  />
                  <CheckboxItem
                    name="autoCommentColleagueLinkedInPost"
                    label="Auto-comment on colleague LinkedIn posts"
                    checked={linkedInPreferences.autoCommentColleagueLinkedInPost}
                    onChange={handleLinkedInPreferenceChange}
                  />
                  <IndentedCheckboxItem
                    name="approvalRequiredCommentColleagueLinkedInPost"
                    label="Require approval for LinkedIn comments for colleague posts"
                    checked={linkedInPreferences.approvalRequiredCommentColleagueLinkedInPost}
                    onChange={handleLinkedInPreferenceChange}
                  />
                </FormGroup>
              </Grid>
            </Box>
          </Box>
          <Box
            sx={{
              p: 2,
              borderTop: '1px solid rgba(0, 0, 0, 0.12)',
              backgroundColor: 'background.paper',
            }}
          >
            <Button
              sx={{ mb: 1, width: '100%' }}
              variant="contained"
              onClick={(e) => {
                const maxUsers = parseInt(companyState.maxUsers, 10);
                // const maxContactEnrichments = parseInt(companyState.maxContactEnrichments, 10);

                // if (isNaN(maxUsers) || maxUsers < 0 || isNaN(maxContactEnrichments) || maxContactEnrichments < 0) {
                //   setAlertText('Max Users and Max Contact Enrichment must be numbers greater than or equal to 0');
                //   setValidateModalOpen(true);
                //   return;
                // }

                if (!isValidEmail) {
                  let alertPrompt = `email is invalid.`;
                  setAlertText(alertPrompt);
                  setValidateModalOpen(true);
                  return;
                }

                company.companyId = company?._id;
                company.goals = goals;
                company.targetAudience = audience;
                company.maxUsers = maxUsers || company.maxUsers;
                // company.maxContactEnrichments = maxContactEnrichments || company.maxContactEnrichments
                company.adminInvite = adminInvite || company?.adminInvite?.invitee;
                company.emailPreference.maxEmailCount = maxEmailCount;
                // Add the LinkedIn preferences
                company.autoLikeColleagueLinkedInPost = linkedInPreferences.autoLikeColleagueLinkedInPost;
                company.autoCommentColleagueLinkedInPost = linkedInPreferences.autoCommentColleagueLinkedInPost;
                company.approvalRequiredCommentColleagueLinkedInPost =
                  linkedInPreferences.approvalRequiredCommentColleagueLinkedInPost;

                onClose(company);
              }}
            >
              Save
            </Button>
            <Button sx={{ width: '100%' }} variant="outlined" color="error" onClick={onClose}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
