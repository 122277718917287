import React from 'react';
import { Sparkles, Plus, Eraser } from 'lucide-react';
import { Card, CardContent, CardHeader } from '@/components/ui/card';
import { motion, AnimatePresence } from 'framer-motion';
import { TopicInput } from './TopicInput';
import { TopicsList } from './TopicsList';
import { Topic } from '@/constants/types/assistant';
import { TopicSelectionProvider, useTopicSelectionContext } from './TopicSelectionContext';
import { cn } from '@/lib/utils';
import { ASSISTANT } from '@postilize/shared/constants/assistants';
import { TopicSelectionHeader } from './components/TopicSelectionHeader';
import { TopicSelectionProgress } from './components/TopicSelectionProgress';
import { CollapsibleInfoCard } from './components/CollapsibleInfoCard';
import { TooltipProvider } from '@/components/ui/tooltip';
import { Button } from '@/components/ui/button';

/**
 * Props for the TopicSelection component
 * @interface TopicSelectionProps
 * @property {Topic[]} [selectedTopics] - Array of currently selected topics
 * @property {boolean} [isLoading] - Loading state indicator for external operations
 * @property {(topics: Topic[]) => void} [onTopicsChange] - Callback fired when topics are added/removed
 * @property {number} [maxTopics] - Maximum number of topics that can be selected
 * @property {string} [assistantName] - Name of the assistant being configured
 * @property {(name: string) => void} [onNameChange] - Callback fired when assistant name changes
 */
export type TopicSelectionProps = {
  selectedTopics?: Topic[];
  isLoading?: boolean;
  onTopicsChange?: (topics: Topic[]) => void;
  maxTopics?: number;
  assistantName?: string;
  onNameChange?: (name: string) => void;
};

/**
 * TopicSelection Component
 *
 * A comprehensive UI for selecting and managing topics for an AI assistant. This component
 * provides both AI-assisted and manual topic selection capabilities.
 *
 * Features:
 * - AI-powered topic generation based on audience description
 * - Manual topic entry
 * - Topic limit enforcement
 * - Animated topic list management
 * - Progress tracking
 * - Responsive layout
 *
 * @component
 * @example
 * ```tsx
 * <TopicSelection
 *   selectedTopics={currentTopics}
 *   onTopicsChange={handleTopicsChange}
 *   maxTopics={5}
 *   assistantName="Content Assistant"
 *   onNameChange={handleNameChange}
 * />
 * ```
 */
export function TopicSelection({
  selectedTopics = [],
  isLoading,
  onTopicsChange,
  maxTopics = ASSISTANT.TOPIC_LIMIT,
  assistantName,
  onNameChange,
}: TopicSelectionProps) {
  return (
    <TooltipProvider>
      <TopicSelectionProvider selectedTopics={selectedTopics} onTopicsChange={onTopicsChange} maxSlots={maxTopics}>
        <div className="w-full">
          <TopicSelectionContent
            isLoading={isLoading}
            maxTopics={maxTopics}
            assistantName={assistantName}
            onNameChange={onNameChange}
          />
        </div>
      </TopicSelectionProvider>
    </TooltipProvider>
  );
}

/**
 * Props for the TopicSelectionContent component
 * @interface TopicSelectionContentProps
 * @property {boolean} [isLoading] - Loading state indicator
 * @property {number} maxTopics - Maximum allowed topics
 * @property {string} [assistantName] - Name of the assistant
 * @property {(name: string) => void} [onNameChange] - Callback for name changes
 */
type TopicSelectionContentProps = {
  isLoading?: boolean;
  maxTopics: number;
  assistantName?: string;
  onNameChange?: (name: string) => void;
};

/**
 * TopicSelectionContent Component
 *
 * Internal component that handles the main layout and UI elements of the topic selection screen.
 * Manages the display of input modes, selected topics, and informational content.
 *
 * Features:
 * - Dual input modes (AI/Manual)
 * - Topic list visualization
 * - Progress tracking
 * - Collapsible information panel
 * - Loading states
 *
 * @component
 * @internal
 */
const TopicSelectionContent: React.FC<TopicSelectionContentProps> = ({
  isLoading,
  maxTopics,
  assistantName,
  onNameChange,
}) => {
  const {
    selectedTopics,
    suggestedTopics,
    setSuggestedTopics,
    handleTopicSubmission,
    inputMode,
    setInputMode,
    isInfoExpanded,
    onToggleInfoExpansion,
    handleSuggestedTopicAdd,
    initialRenderRef,
  } = useTopicSelectionContext();

  return (
    <div className="w-full p-4 md:p-8 space-y-6">
      <TopicSelectionHeader assistantName={assistantName} onNameChange={onNameChange} />

      <Card className="w-full border shadow-sm">
        <CardHeader>
          <TopicSelectionProgress selectedCount={selectedTopics.length} maxTopics={maxTopics} />
        </CardHeader>

        <CardContent className="space-y-6">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            {/* Left Column - Topic Input and Suggestions */}
            <div className="min-w-0">
              <section className="border rounded-lg p-4 bg-white space-y-6">
                <div className="flex items-center justify-between mb-4">
                  <h2 className="text-lg font-semibold text-gray-900">Topic Selection</h2>
                  <div className="text-sm text-gray-500">Choose your topics</div>
                </div>

                <div className="w-full">
                  <div className="grid w-full grid-cols-2 gap-2 p-2 bg-slate-50 rounded-2xl">
                    <button
                      onClick={() => setInputMode('ai')}
                      className={cn(
                        'relative flex items-center gap-3 px-4 py-3 rounded-xl border transition-all duration-200 group',
                        inputMode === 'ai'
                          ? 'bg-white border-slate-200 shadow-sm'
                          : 'bg-white/50 border-transparent hover:bg-white/80',
                      )}
                    >
                      <div
                        className={cn(
                          'flex items-center justify-center w-8 h-8 rounded-lg transition-colors',
                          inputMode === 'ai' ? 'bg-slate-50' : 'bg-slate-100 group-hover:bg-slate-50',
                        )}
                      >
                        <Sparkles className="w-4 h-4 text-slate-600" />
                      </div>
                      <div className="text-left">
                        <p className="text-sm font-medium text-slate-700">Describe your customer to AI</p>
                        <p className="text-xs text-slate-500">Smart topic generation</p>
                      </div>
                    </button>
                    <button
                      onClick={() => setInputMode('manual')}
                      className={cn(
                        'relative flex items-center gap-3 px-4 py-3 rounded-xl border transition-all duration-200 group',
                        inputMode === 'manual'
                          ? 'bg-white border-slate-200 shadow-sm'
                          : 'bg-white/50 border-transparent hover:bg-white/80',
                      )}
                    >
                      <div
                        className={cn(
                          'flex items-center justify-center w-8 h-8 rounded-lg transition-colors',
                          inputMode === 'manual' ? 'bg-slate-50' : 'bg-slate-100 group-hover:bg-slate-50',
                        )}
                      >
                        <Plus className="w-4 h-4 text-slate-600" />
                      </div>
                      <div className="text-left">
                        <p className="text-sm font-medium text-slate-700">Manual Entry</p>
                        <p className="text-xs text-slate-500">Add custom topics</p>
                      </div>
                    </button>
                  </div>

                  <div className="pt-6">
                    <form onSubmit={(e) => handleTopicSubmission(e, inputMode)} className="space-y-6">
                      <TopicInput key={inputMode} inputMode={inputMode} maxSelectedTopics={maxTopics} />

                      {/* Suggested Topics */}
                      <AnimatePresence>
                        {suggestedTopics.length > 0 && (
                          <motion.div
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -20 }}
                            transition={{ duration: 0.3 }}
                            className="space-y-3"
                          >
                            <div className="flex items-center justify-between">
                              <p className="text-sm text-muted-foreground">Click on a suggestion to add it:</p>
                              <Button
                                variant="ghost"
                                size="sm"
                                type="button"
                                className="h-8 text-gray-600 hover:text-gray-700 hover:bg-gray-50"
                                onClick={() => setSuggestedTopics([])}
                              >
                                <Eraser className="w-4 h-4 mr-2" />
                                Clear Suggestions
                              </Button>
                            </div>
                            <div className="flex flex-wrap gap-2">
                              {suggestedTopics.map((topic) => {
                                const isAtMax = selectedTopics.length >= maxTopics;
                                const uniqueKey = topic._id ? `${topic._id}-${topic.name}` : `suggested-${topic.name}}`;

                                return (
                                  <motion.div
                                    key={uniqueKey}
                                    initial={{ opacity: 0, scale: 0.8 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    exit={{ opacity: 0, scale: 0.8 }}
                                    transition={{ duration: 0.2 }}
                                    whileHover={!isAtMax ? { scale: 1.05 } : {}}
                                    whileTap={!isAtMax ? { scale: 0.95 } : {}}
                                  >
                                    <div
                                      className={cn(
                                        'flex items-center gap-3 px-4 py-2 rounded-lg border border-blue-200 bg-blue-50/80 shadow-sm cursor-pointer transition-colors',
                                        isAtMax
                                          ? 'opacity-50 cursor-not-allowed'
                                          : 'hover:bg-blue-100 hover:border-blue-300',
                                      )}
                                      onClick={() => !isAtMax && handleSuggestedTopicAdd(topic)}
                                      tabIndex={0}
                                      onKeyDown={(e) => {
                                        if (e.key === 'Enter' || e.key === ' ') {
                                          e.preventDefault();
                                          !isAtMax && handleSuggestedTopicAdd(topic);
                                        }
                                      }}
                                      aria-label={`Add suggestion ${topic.name}`}
                                      role="button"
                                    >
                                      <span className="text-sm font-medium truncate">{topic.name}</span>
                                      <Plus className="h-4 w-4 text-blue-600" />
                                    </div>
                                  </motion.div>
                                );
                              })}
                            </div>
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </form>
                  </div>
                </div>
              </section>
            </div>

            {/* Right Column - Selected Topics List */}
            <div className="min-w-0">
              <TopicsList isInitialRender={initialRenderRef.current} maxSelectedTopics={maxTopics} />
            </div>
          </div>
        </CardContent>
      </Card>

      <CollapsibleInfoCard isExpanded={isInfoExpanded} toggleExpanded={onToggleInfoExpansion} maxTopics={maxTopics} />

      {/* Success Notification */}
      {/*
      <AnimatePresence>
        {shouldShowSuccess && (
          <motion.div
            variants={successVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.3 }}
            className="fixed bottom-4 right-4 bg-green-500 text-white px-4 py-2 rounded-md shadow-lg flex items-center gap-2"
            role="alert"
            aria-live="assertive"
          >
            <CheckCircle className="h-5 w-5" aria-hidden="true" />
            <span>All topics selected!</span>
          </motion.div>
        )}
      </AnimatePresence>
      // TODO: Migrate the above animation to the `toast` component,
      // as it's a more attractive visualization.
      */}

      {/* Shimmer Animation Style */}
      <style>{`
        @keyframes shimmer {
          0% {
            background-position: -200% 0;
          }
          100% {
            background-position: 200% 0;
          }
        }
        .animate-shimmer {
          animation: shimmer 1.5s infinite;
        }
      `}</style>

      {/* External loading state indicator (if isLoading is passed) */}
      {isLoading && (
        <div className="flex justify-center p-4">
          <div className="h-6 w-6 animate-spin rounded-full border-2 border-t-transparent border-gray-500"></div>
        </div>
      )}
    </div>
  );
};

/**
 * Default export for the TopicSelection component
 * @exports
 * @default
 */
export default TopicSelection;
