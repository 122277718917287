import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import PTypography from '../PTypography';
import { replaceEmailSubjectPlaceholders, replaceEmailBodyPlaceholders } from '../../../helper/emailUtility';
import PPostCardCommonContent from './PPostCardCommonContent';
import { Avatar, Box } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { TopicType } from '@/constants/constant';
import { useSelector } from 'react-redux';
import featureFlagService from '@/services/featureFlagService';
import { isLexicalHTML } from '@/utils/richTextUtils';

const EmailCard = ({ post, onContactClick, handleSendToMessage, errorNote }) => {
  const featureFlags = useSelector((state) => state.auth.featureFlags);

  const handleContactClick = React.useCallback(
    (event) => {
      event.stopPropagation();
      if (post?.topic_type === TopicType.RECAP_EMAIL) {
        return;
      }

      if (onContactClick) {
        onContactClick();
      }
    },
    [onContactClick],
  );

  const getEmailLabel = () => {
    if (post?.topic_type === TopicType.LINKEDIN_EMAIL) {
      return ' - LinkedIn Message';
    }

    // Check for multiple recipients first
    if (post?.recipients?.length > 0) {
      const emails = post.recipients.filter((recipient) => recipient.email).map((recipient) => recipient.email);
      return ` - To: ${emails.join(', ')}`;
    }

    // Fallback to original single recipient logic
    const { email } = post?.sequenceAttr?.contactDetails || {};
    if (email || post?.topic_type === TopicType.ADHOC) {
      return ` - ${email}`;
    }
    return ' - LinkedIn Message';
  };

  const headerNode = React.useMemo(() => {
    return (
      <Box onClick={handleContactClick} sx={{ pt: 0.9 }}>
        <PTypography size="body2" weight="bold">
          {post.topic_type === 'linkedInEmail' && (
            <LinkedInIcon sx={{ fontSize: 18 }} style={{ verticalAlign: 'top' }} />
          )}
          {post.topic_type === TopicType.RECAP_EMAIL
            ? post.subject
            : post?.sequenceAttr?.contactDetails?.name ?? post?.name}
          {getEmailLabel()}
        </PTypography>
      </Box>
    );
  }, [handleContactClick, post]);

  const avatarNode = React.useMemo(() => {
    return (
      <Box onClick={handleContactClick}>
        <Avatar
          sx={{ width: '32px', height: '32px' }}
          src={post?.authorType === 'contact' ? post?.responseTo?.ImageUrl : post?.ImageUrl}
        />
      </Box>
    );
  }, [handleContactClick, post]);

  const subjectDisplay = useMemo(() => {
    if (post?.topic_type === TopicType.RECAP_EMAIL) {
      return null;
    }
    return (
      <PTypography size="body2" weight="regular">
        {replaceEmailSubjectPlaceholders(post?.subject, null, post?.sequenceAttr?.contactDetails)}
      </PTypography>
    );
  }, [post]);

  const messageDisplay = useMemo(() => {
    const isPRTEditorEnabled = featureFlagService.isFeatureEnabled('ui.richTextEditor', featureFlags);

    if (isPRTEditorEnabled && isLexicalHTML(post?.message)) {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: replaceEmailBodyPlaceholders(post?.message, null, post?.sequenceAttr?.contactDetails),
          }}
        />
      );
    }

    return (
      <PTypography size="body1" weight="regular" preformatted>
        {replaceEmailBodyPlaceholders(post?.message, null, post?.sequenceAttr?.contactDetails)}
      </PTypography>
    );
  }, [featureFlags, post?.message, post?.sequenceAttr?.contactDetails]);

  return (
    <PPostCardCommonContent
      post={post}
      avatarNode={avatarNode}
      headerNode={headerNode}
      handleSendToMessage={handleSendToMessage}
      errorNote={errorNote}
    >
      {subjectDisplay}
      {messageDisplay}
    </PPostCardCommonContent>
  );
};

EmailCard.propTypes = {
  post: PropTypes.object.isRequired,
  onContactClick: PropTypes.func,
};

export default EmailCard;
