import React, { useMemo, forwardRef } from 'react';
import { motion } from 'framer-motion';
import { X } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { TOPIC_HEIGHT, TOPIC_ITEM_HEIGHT } from './constants';
import { badgeVariants, buttonVariants } from './animations';
import { Topic } from '@/constants/types/assistant';

/**
 * Props for the TopicItem component
 * @interface TopicItemProps
 * @property {Topic} item - The topic data to display
 * @property {number} index - Position in the topic list (0-based)
 * @property {(topic: Topic) => void} onRemoveSelectedTopic - Callback to remove the topic
 * @property {boolean} isInitialRender - Controls initial animation behavior
 * @property {number} [maxSelectedTopics] - Maximum allowed topics for reference
 */
export type TopicItemProps = {
  item: Topic;
  index: number;
  onRemoveSelectedTopic: (topic: Topic) => void;
  isInitialRender: boolean;
  maxSelectedTopics?: number;
};

/**
 * TopicItem Component
 *
 * A motion-animated list item component that displays a single topic with
 * ordering, removal capabilities, and smooth animations for all state changes.
 *
 * Features:
 * - Framer Motion animations for enter/exit/reorder
 * - Numbered badge showing topic order
 * - Remove button with hover states
 * - Accessible button labels
 * - Spring animations for badge
 *
 * @component
 * @param {TopicItemProps} props - Component props
 * @param {React.Ref<HTMLDivElement>} ref - Forwarded ref for animations
 * @returns {JSX.Element} Rendered TopicItem component
 *
 * @example
 * ```tsx
 * <TopicItem
 *   item={{ _id: '1', name: 'React' }}
 *   index={0}
 *   onRemoveSelectedTopic={handleRemove}
 *   isInitialRender={false}
 *   maxSelectedTopics={5}
 * />
 * ```
 */
export const TopicItem = forwardRef<HTMLDivElement, TopicItemProps>(
  ({ item, index, onRemoveSelectedTopic, isInitialRender, maxSelectedTopics }, ref) => {
    const position = index * TOPIC_ITEM_HEIGHT;

    return (
      <motion.div
        ref={ref}
        layout
        initial={useMemo(() => (isInitialRender ? false : { opacity: 0, y: -TOPIC_HEIGHT }), [isInitialRender])}
        animate={{
          opacity: 1,
          y: position,
          scale: 1,
        }}
        exit={{ opacity: 0, scale: 0, transition: { duration: 0.2 } }}
        transition={{ duration: 0.3, ease: 'easeInOut' }}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: TOPIC_HEIGHT,
          pointerEvents: 'auto',
        }}
        className={`flex items-center gap-3 px-4 rounded-lg border border-blue-200 bg-blue-50/80 shadow-sm`}
        key={item._id}
      >
        <motion.div
          className={`flex items-center justify-center w-6 h-6 rounded-full bg-blue-100 text-blue-600 text-xs font-medium`}
          variants={badgeVariants}
          initial="hidden"
          animate="visible"
          transition={{ delay: 0.1, type: 'spring', stiffness: 500, damping: 25 }}
        >
          {index + 1}
        </motion.div>
        <motion.span
          className={`flex-1 text-sm truncate font-medium`}
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.2, duration: 0.3 }}
        >
          {item.name}
        </motion.span>
        <motion.div variants={buttonVariants} whileHover="hover" whileTap="tap">
          <Button
            type="button"
            variant="ghost"
            size="icon"
            onClick={() => onRemoveSelectedTopic(item)}
            className="h-6 w-6 hover:bg-red-50 hover:text-red-600"
            aria-label={`Remove topic ${item.name}`}
          >
            <X className="h-4 w-4" />
          </Button>
        </motion.div>
      </motion.div>
    );
  },
);

TopicItem.displayName = 'TopicItem';
