import * as React from 'react';
import { useCallback, useMemo } from 'react';
// import { Typography, useTheme, Grid, Container, Stack, Avatar, Divider, Alert } from '@mui/material';
import { useTheme, Menu, MenuItem } from '@mui/material';
import _ from 'lodash';
import Fuse from 'fuse.js';
// import ReactTimeAgo from 'react-time-ago';

import PContactList from '../../../components/library/PContactList';

import { ReactComponent as Search } from '../../../assets/Icons/Search.svg';
// import { ReactComponent as ArrowDown } from '../../../assets/Icons/Arrow-Down.svg';

import ManualListModal from '../../setup/simpleSetupComponents/Lists/ManualListModal.js';
import AddContactsModal from './addContactsModal';
import EditModal from './sequenceSettingsModal/edit.js';
import PauseModal from './sequenceSettingsModal/pause.js';
import DeleteModal from './sequenceSettingsModal/delete.js';
import RenameModal from './sequenceSettingsModal/rename.js';
import Http from '../../../http/httpClient';
import { SequenceMailableStepIds, SequenceStepStatus, TwitterStepIds } from '../../../constants/constant.js';
import { truncateText, rollIndex } from '../../../helper/utilities.js';
import EmailModal from '../../../components/sequence/emailModal.js';
import PEditableHeader from '@/components/library/PEditableHeader';
import { formatTimeUntilNextStep, formatTimeToolTip } from './helpers';

export default function SequenceReview(props) {
  // console.log("SequenceReview rendered")
  const theme = useTheme();
  let {
    userBio,
    contacts,
    contacts2,
    stepTypes,
    listItem,
    handleSequenceModification,
    fetchSequenceData,
    setCreatedSequenceId,
    setSequenceList,
    sequenceList,
    handleSequenceDelete,
    targetAudience,
    availableList,
    forDelegation,
  } = props;

  // const user = React.useMemo(() => userBio, [userBio]);
  const sequenceContacts = React.useMemo(() => contacts, [contacts]);
  const sequenceContacts2 = React.useMemo(() => contacts2, [contacts2]);
  const [filteredContacts, setFilteredContacts] = React.useState([]);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [activeButton, setActiveButton] = React.useState('All');
  const [sortFields, setSortFields] = React.useState([
    'stepStatus.statusUpdatedAt',
    'hasNull',
    'name',
    'title',
    'company',
  ]);
  const [searchContact, setSearchContact] = React.useState('');
  const [editContact, setEditContact] = React.useState(null);
  const [editContactModalOpen, setEditContactModalOpen] = React.useState(false);
  const [showEditSequence, setShowEditSequence] = React.useState(false);
  const [selectedContacts, setSelectedContacts] = React.useState([]);
  const [currentContact, setCurrentContact] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const [renameOpen, setRenameOpen] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);
  const [pauseOpen, setPauseOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [emailOpen, setEmailOpen] = React.useState(false);
  const [currentStep, setCurrentStep] = React.useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const fuse = useMemo(
    () =>
      new Fuse(sequenceContacts, {
        keys: ['name', 'company', 'title'],
        threshold: 0.4,
        location: 0,
        distance: 100,
        findAllMatches: true,
        minMatchCharLength: 1,
      }),
    [sequenceContacts],
  );
  const fuse2 = useMemo(
    () =>
      new Fuse(sequenceContacts2, {
        keys: ['name', 'company', 'title'],
        threshold: 0.4,
        location: 0,
        distance: 100,
        findAllMatches: true,
        minMatchCharLength: 1,
      }),
    [sequenceContacts2],
  );

  const handleMenuOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const closeEdit = useCallback(() => setEditOpen(false), []);
  const closeModal = useCallback(() => setModalOpen(false), []);
  const editContactModalClose = useCallback(() => setEditContactModalOpen(false), []);
  const closeEmail = useCallback(() => setEmailOpen(false), []);
  const closeRename = useCallback(() => setRenameOpen(false), []);
  const closePause = useCallback(() => setPauseOpen(false), []);
  const closeDelete = useCallback(() => setDeleteOpen(false), []);

  const getContactStepStatus = useCallback(
    (contact) => {
      let status = '';
      if (contact?.nextStepExecTime) {
        const stepDetails = listItem?.template?.steps?.find((s) => s._id === contact?.stepStatus?.id);

        let emailTemplate = stepDetails?.emailTemplate;
        if (stepDetails?.id === 'checkin.recurring') {
          const nextStepStatus = contact.stepStatus;
          const validRecurrenceIndex = rollIndex(nextStepStatus?.recurrenceIndex, stepDetails?.emailTemplates?.length);
          emailTemplate = stepDetails?.emailTemplates?.[validRecurrenceIndex];
        }
        const preciseSubject = truncateText(emailTemplate?.subjectLine, 30);
        // status = stepTypes.find(
        //   (st) => st.id === contact.stepStatus?.filter((s) => !TwitterStepIds.includes(s.stepType))?.[0].stepType,
        // )?.desc;
        status = preciseSubject;
      } else if (contact?.lastCompletedStepId) {
        const lastStep = contact?.stepStatus?.id?.toString() === contact.lastCompletedStepId?.toString();
        if (
          lastStep &&
          SequenceMailableStepIds.includes(contact.stepStatus?.stepType) &&
          contact.stepStatus?.status === 'failed'
        ) {
          status = 'Bounced';
        }
      }

      const isTwiiiterStep = TwitterStepIds.includes(contact?.stepStatus?.stepType);
      if (isTwiiiterStep) {
        const stepType = contact?.stepStatus?.stepType;
        status = `Auto-${getStepTypeWord(stepType)} ${getTarget(stepType)}`;
      }

      return status;
    },
    [listItem],
  );

  const handleSeeEmail = useCallback(
    (contact) => {
      setCurrentContact(contact);
      setCurrentStep(listItem?.template?.steps?.find((s) => s._id === contact?.stepStatus?.id));
      setEmailOpen(true);
    },
    [listItem],
  );

  const handleContactSelection = useCallback((contactId) => {
    setSelectedContacts((prevSelectedContacts) => {
      const isSelected = prevSelectedContacts.includes(contactId);
      return isSelected ? prevSelectedContacts.filter((id) => id !== contactId) : [...prevSelectedContacts, contactId];
    });
  }, []);

  const handleContactClick = useCallback((contact) => {
    setEditContact(contact);
    setEditContactModalOpen(true);
  }, []);

  const getStepTypeWord = useCallback((stepType) => {
    switch (stepType) {
      case 'share':
        return 'reshared';
      case 'connect':
        return `request to connect`;
      case 'follow':
      case 'comment':
        return `${stepType}ed`;
      default:
        return `${stepType}d`;
    }
  }, []);

  const getTarget = useCallback((stepType) => {
    switch (stepType) {
      case 'connect':
        return `on social media`;
      case 'follow':
        return `them on social media`;
      default:
        return `their posts`;
    }
  }, []);

  const preparedContacts = useMemo(
    () =>
      filteredContacts
        .map((contact) => {
          if (!contact) return null;

          const currentStep = listItem?.template?.steps?.find((s) => s._id === contact?.stepStatus?.id);

          const stepIndex = listItem?.template?.steps?.findIndex((s) => s._id === contact?.stepStatus?.id) + 1;
          const totalSteps = listItem?.template?.steps?.length;

          const emailSubject = (() => {
            if (currentStep?.id === 'checkin.recurring') {
              const nextStepStatus = contact.stepStatus;
              const validRecurrenceIndex = rollIndex(
                nextStepStatus?.recurrenceIndex,
                currentStep?.emailTemplates?.length,
              );
              return currentStep?.emailTemplates?.[validRecurrenceIndex]?.subjectLine;
            } else {
              return currentStep?.emailTemplate?.subjectLine;
            }
          })();

          const stepSubtitle = `Step ${stepIndex}/${totalSteps}`;
          const stepStatus = getContactStepStatus(contact);

          return {
            _id: contact._id,
            name: contact.name,
            photo_url: contact.photo_url,
            role: contact.title,
            company: contact.company,
            time: formatTimeUntilNextStep(contact, currentStep, listItem),
            timeToolTip: formatTimeToolTip(contact, currentStep),
            seeEmail: () => handleSeeEmail(contact),
            emailSubject: emailSubject || null,
            stepSubtitle: emailSubject ? stepSubtitle : stepStatus,
            checked: selectedContacts.includes(contact._id),
            onCheck: () => handleContactSelection(contact._id),
            onClickName: () => handleContactClick(contact),
            isLinkedInMail: contact?.stepStatus?.stepType === 'linkedInEmail',
          };
        })
        .filter(Boolean),
    [
      filteredContacts,
      listItem,
      getContactStepStatus,
      selectedContacts,
      handleSeeEmail,
      handleContactSelection,
      handleContactClick,
    ],
  );

  const handleContactAction = useCallback(
    (action, contactIds) => {
      setIsLoading(true);
      const sequenceData = { sequenceId: listItem?._id, contactIds: contactIds };
      Http.postData('updateSequenceContactSettings', {
        type: action,
        sequenceData,
        pausedType: 'Manually paused',
      })
        .then((res) => {
          if (res && res.status) {
            if (action === 'Remove') setSelectedContacts([]);
            handleSequenceModification();
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    },
    [listItem?._id, handleSequenceModification],
  );

  const handleButtonClick = useCallback(
    (action) => {
      if (['Remove', 'Pause', 'Unpause'].includes(action)) {
        handleContactAction(action, selectedContacts);
      } else if (['All', 'Upcoming', 'Recent', 'Active', 'Paused'].includes(action)) {
        setActiveButton(action);
      }
    },
    [handleContactAction, selectedContacts],
  );

  const handleToggleAll = useCallback(
    (newChecked) => {
      if (newChecked) {
        setSelectedContacts(preparedContacts.map((contact) => contact._id));
      } else {
        setSelectedContacts([]);
      }
    },
    [preparedContacts],
  );

  React.useEffect(() => {
    const sortingFieldsMap = {
      All: ['hasNull', 'name', 'title', 'company'],
      Role: ['hasNull', 'title', 'name', 'company'],
      Company: ['hasNull', 'company', 'name', 'title'],
      Channel: [],
    };

    const sortingFields = sortingFieldsMap[activeButton] || [
      'stepStatus.statusUpdatedAt',
      'hasNull',
      'name',
      'title',
      'company',
    ];
    setSortFields(sortingFields);
    setSelectedContacts([]);
  }, [activeButton]);

  function customSort(contact) {
    if (!contact.nextStepExecTime) {
      return contact.status === 'active' ? 1 : 0;
    } else {
      return contact.status === 'paused' ? 0 : -1;
    }
  }

  const handleSearchSequenceContact = useCallback(
    (event) => {
      const value = event.target.value.toLowerCase();
      setSearchContact(value);
      if (value === '') {
        setFilteredContacts(sequenceContacts);
      } else {
        const results = fuse.search(value).map(({ item }) => item);
        setFilteredContacts(results);
      }
    },
    [sequenceContacts, fuse],
  );

  const handleClearSearch = useCallback(() => {
    setSearchContact('');
    setFilteredContacts(sequenceContacts);
  }, [sequenceContacts]);

  // This is the main filter function that filters the contacts based on the active button and the search query
  React.useEffect(() => {
    const searchQuery = searchContact.toLowerCase();

    let contacts = [];
    if (activeButton === 'All') {
      contacts = searchQuery ? fuse2.search(searchQuery).map((contact) => contact.item) : sequenceContacts2;
    } else {
      contacts = searchQuery ? fuse.search(searchQuery).map((contact) => contact.item) : sequenceContacts;
    }

    const preliminaryFiltered = contacts?.filter((contact) => {
      if (contact === null) return false;

      const isStatusActive = contact.status === 'active';
      const isStatusPaused = contact.status === 'paused';
      const stepStatus = contact.stepStatus;
      const stepType = stepStatus?.stepType;
      const startDate = new Date(stepStatus?.startDate);
      const currentDate = new Date();
      const ONE_DAYms = 1000 * 60 * 60 * 24;
      const socialStepIds = ['follow', 'comment', 'share', 'connect', 'like'];
      const isSocialStepAndNoLinkedInResponse = socialStepIds.includes(stepType) && !stepStatus?.linkedInResponse;
      switch (activeButton) {
        case 'All':
          return true;

        case 'Upcoming':
          return (
            isStatusActive &&
            ![SequenceStepStatus.MANUALLY_DELETED, SequenceStepStatus.TRIGGERED].includes(stepStatus?.status) &&
            ((contact.nextStepId === stepStatus?.id &&
              SequenceMailableStepIds.includes(stepStatus?.stepType) &&
              startDate.getTime() + ONE_DAYms > currentDate.getTime()) ||
              (contact.nextStepId === stepStatus?.id && !SequenceMailableStepIds.includes(stepStatus?.stepType)) ||
              (contact.nextStepId === null && TwitterStepIds.includes(stepType) && startDate > currentDate))
          );

        case 'Recent':
          const showRecent =
            isStatusActive &&
            ([
              'triggered',
              'failed',
              'skipped',
              'notapproved',
              'limitexceeded',
              SequenceStepStatus.MANUALLY_DELETED,
            ].includes(stepStatus?.status) ||
              (TwitterStepIds.includes(stepType) && startDate < currentDate));
          return showRecent && !isSocialStepAndNoLinkedInResponse;
        case 'Paused':
          return isStatusPaused;

        case 'Active':
          return (
            isStatusActive &&
            ![
              'triggered',
              'failed',
              'skipped',
              'notapproved',
              'limitexceeded',
              SequenceStepStatus.MANUALLY_DELETED,
            ].includes(stepStatus?.status) &&
            !TwitterStepIds.includes(stepType)
          );

        case 'Expired':
          return (
            isStatusActive &&
            stepStatus?.status === SequenceStepStatus.NOTAPPROVED &&
            startDate.getTime() < currentDate.getTime() - ONE_DAYms
          );

        default:
          return false;
      }
    });

    let filtered = [];
    if (activeButton === 'Paused') {
      const uniqueIds = new Set();
      filtered = preliminaryFiltered.filter((contact) => {
        const isDuplicate = uniqueIds.has(contact._id);
        uniqueIds.add(contact._id);
        return !isDuplicate;
      });
    } else {
      filtered = preliminaryFiltered;
    }

    const sortedContacts = _.orderBy(
      filtered,
      [
        (contact) => contact.stepStatus?.statusUpdatedAt, // Sort this field in descending order
        ...sortFields.filter((field) => field !== 'stepStatus.statusUpdatedAt'), // Sort other fields in ascending order
      ],
      ['desc', ...Array(sortFields.length - 1).fill('asc')],
    );
    setFilteredContacts([...sortedContacts]);
  }, [searchContact, sortFields, sequenceContacts, props.refreshKey, activeButton, fuse2, sequenceContacts2, fuse]);

  const handlePauseClick = useCallback(() => {
    setPauseOpen(true);
    handleMenuClose();
  }, [handleMenuClose]);

  const handleDeleteClick = useCallback(() => {
    setDeleteOpen(true);
    handleMenuClose();
  }, [handleMenuClose]);

  const handleRenameClick = useCallback(() => {
    setRenameOpen(true);
    handleMenuClose();
  }, [handleMenuClose]);

  const handleEditClick = useCallback(() => {
    setEditOpen(true);
    handleMenuClose();
  }, [handleMenuClose]);

  const uniqueContactIDs = useMemo(
    () => [...new Set(preparedContacts.map((contact) => contact._id))],
    [preparedContacts],
  );
  const uniqueSelectedIDs = useMemo(() => [...new Set(selectedContacts)], [selectedContacts]);

  // ==============================
  const [headerText, setHeaderText] = React.useState(listItem?.name || '');

  React.useEffect(() => {
    setHeaderText(listItem?.name || '');
  }, [listItem]);

  const handleRename = useCallback(() => {
    const newName = headerText.trim() || 'Untitled';
    Http.postData('updateSequenceSettings', {
      type: 'Rename',
      sequenceData: {
        _id: listItem?._id,
        sequenceName: newName,
      },
    })
      .then((res) => {
        if (res.status) {
          const newSequence = res.userSequence.sequences.find((item) => item._id === listItem?._id);
          if (newSequence) {
            setCreatedSequenceId(listItem?._id);
            const updatedSequenceList = sequenceList.map((item) =>
              item._id === listItem?._id ? { ...item, name: newSequence.name } : item,
            );
            setSequenceList(updatedSequenceList);
          }
        }
      })
      .catch((error) => {
        console.error('Error renaming sequence:', error);
      });
  }, [headerText, listItem?._id, sequenceList, setCreatedSequenceId, setSequenceList]);

  const memoizedEditModal1 = useMemo(() => {
    if (editOpen) {
      return (
        <EditModal
          open={editOpen}
          onClose={closeEdit}
          activeItem={listItem}
          template={listItem?.template}
          onRefresh={fetchSequenceData}
        />
      );
    }
    return null;
  }, [closeEdit, editOpen, fetchSequenceData, listItem]);

  const memoizedEditModal2 = useMemo(() => {
    if (showEditSequence && listItem) {
      return (
        <EditModal
          open={editOpen}
          onClose={closeEdit}
          activeItem={listItem}
          template={listItem?.template}
          onRefresh={fetchSequenceData}
        />
      );
    }
    return null;
  }, [closeEdit, editOpen, fetchSequenceData, listItem, showEditSequence]);

  const memoizedPauseModal = useMemo(() => {
    return (
      <PauseModal
        forDelegation={forDelegation}
        open={pauseOpen}
        onClose={closePause}
        activeItem={listItem}
        onRefresh={fetchSequenceData}
      />
    );
  }, [forDelegation, pauseOpen, closePause, listItem, fetchSequenceData]);

  const memoizedDeleteModal = useMemo(() => {
    return (
      <DeleteModal
        forDelegation={forDelegation}
        open={deleteOpen}
        onClose={closeDelete}
        handleSequenceDelete={() => handleSequenceDelete(listItem?._id)}
      />
    );
  }, [forDelegation, deleteOpen, closeDelete, listItem, handleSequenceDelete]);

  const memoizedRenameModal = useMemo(() => {
    return (
      <RenameModal
        forDelegation={forDelegation}
        open={renameOpen}
        onClose={closeRename}
        activeItem={listItem}
        setCreatedSequenceId={setCreatedSequenceId}
        setSequenceList={setSequenceList}
        sequenceList={sequenceList}
      />
    );
  }, [forDelegation, renameOpen, closeRename, listItem, setCreatedSequenceId, setSequenceList, sequenceList]);

  const memoizedAddContactsModal = useMemo(() => {
    if (modalOpen) {
      return (
        <AddContactsModal
          open={modalOpen}
          onClose={closeModal}
          source={'sequence'}
          sequenceItem={listItem}
          handleModification={handleSequenceModification}
          targetAudience={targetAudience}
          existingList={availableList}
        />
      );
    }
    return null;
  }, [modalOpen, closeModal, listItem, handleSequenceModification, targetAudience, availableList]);

  const memoizedModals = useMemo(() => {
    if (!showEditSequence) {
      return (
        <>
          {memoizedPauseModal}
          {memoizedDeleteModal}
          {memoizedRenameModal}
          {memoizedEditModal1}
          {memoizedAddContactsModal}
        </>
      );
    }
    return null;
  }, [
    showEditSequence,
    memoizedPauseModal,
    memoizedDeleteModal,
    memoizedRenameModal,
    memoizedEditModal1,
    memoizedAddContactsModal,
  ]);

  const memoizedContactList = useMemo(() => {
    if (!forDelegation) {
      return (
        <PContactList
          headerComponent={
            <PEditableHeader text={headerText} onChange={setHeaderText} onAction={handleRename} id={listItem?._id} />
          }
          header1Text="Edit"
          header1Theme="secondary"
          header1OnClick={(event) => handleMenuOpen(event)}
          {...(!forDelegation && {
            header2Text: 'Add contacts',
            header2Theme: 'primary',
            header2OnClick: () => setModalOpen(true),
          })}
          inputPlaceHolder="Search name, role, company"
          inputIcon={Search}
          inputOnChange={handleSearchSequenceContact}
          onClear={handleClearSearch}
          activeFilter={activeButton}
          filterAction={handleButtonClick}
          filterChange={setActiveButton}
          filterToggleAll={handleToggleAll}
          filterTotalCount={uniqueContactIDs.length}
          filterSelectedCount={uniqueSelectedIDs.length}
          contacts={preparedContacts}
          loadingState={isLoading}
        />
      );
    }
    return null;
  }, [
    forDelegation,
    headerText,
    handleMenuOpen,
    handleRename,
    preparedContacts,
    uniqueContactIDs,
    uniqueSelectedIDs,
    isLoading,
    activeButton,
    handleButtonClick,
    handleToggleAll,
    handleSearchSequenceContact,
    handleClearSearch,
  ]);

  const memoizedMenu = useMemo(() => {
    if (!isMenuOpen) {
      return null;
    }

    return (
      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleMenuClose}
        PaperProps={{
          style: {
            borderRadius: '8px',
            elevation: 0,
            minWidth: '200px',
            boxShadow:
              '0px 1px 2px -1px rgba(0,0,0,0.1), ' +
              '0px 2px 3px 0px rgba(0,0,0,0.07), ' +
              '0px 1px 5px 0px rgba(0,0,0,0.06)',
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          onClick={handleRenameClick}
          sx={{
            fontFamily: 'Inter',
            fontWeight: 500,
            fontSize: '14px',
            color: theme.palette.primary.black,
          }}
        >
          Rename
        </MenuItem>
        <MenuItem
          onClick={handleEditClick}
          sx={{
            fontFamily: 'Inter',
            fontWeight: 500,
            fontSize: '14px',
            color: theme.palette.primary.black,
          }}
        >
          Edit steps
        </MenuItem>
        <MenuItem
          onClick={handlePauseClick}
          sx={{
            fontFamily: 'Inter',
            fontWeight: 500,
            fontSize: '14px',
            color: theme.palette.primary.black,
          }}
        >
          {listItem?.status === 'Active' ? 'Pause' : 'Unpause'}
        </MenuItem>
        <MenuItem
          onClick={handleDeleteClick}
          sx={{
            fontFamily: 'Inter',
            fontWeight: 500,
            fontSize: '14px',
            color: theme.palette.primary.orange,
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    );
  }, [
    anchorEl,
    handleDeleteClick,
    handleEditClick,
    handleMenuClose,
    handlePauseClick,
    handleRenameClick,
    isMenuOpen,
    listItem?.status,
    theme.palette.primary.black,
    theme.palette.primary.orange,
  ]);

  const memoizedEmailModal = useMemo(() => {
    if (emailOpen) {
      return (
        <EmailModal
          open={emailOpen}
          onClose={closeEmail}
          step={currentStep}
          setupList={null}
          isEditEmail={false}
          listItem={listItem}
          readOnly={true}
          contact={currentContact}
          userBio={userBio}
        />
      );
    }
    return null;
  }, [closeEmail, currentContact, currentStep, emailOpen, listItem, userBio]);

  const memoizedManualListModal = useMemo(() => {
    if (editContactModalOpen) {
      return (
        <ManualListModal
          open={editContactModalOpen}
          onClose={editContactModalClose}
          contact={editContact}
          forSetup={false}
          refreshListContacts={() => {
            editContactModalClose();
            handleSequenceModification();
          }}
        />
      );
    }
    return null;
  }, [editContact, editContactModalClose, editContactModalOpen, handleSequenceModification]);

  if (!showEditSequence) {
    return (
      <>
        {memoizedModals}

        {/* {confirmModal && <ConfirmationModal open={confirmModal} onClose={closeConfirmModal} activeItem={listItem}
        contact={selectedContact} modalType={modalType} handleModification={handleSequenceModification} /> } */}

        {memoizedContactList}

        {memoizedMenu}

        {memoizedEmailModal}

        {memoizedManualListModal}
      </>
    );
  } else {
    return memoizedEditModal2;
  }
}
