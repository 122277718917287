'use client';

import { useRef, useEffect, useCallback, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIsFeatureFlagEnabled } from '@/hooks/useFeatureFlags';
import { ClientTable } from './ERMDashboard/components/ClientTable';
import { RelationshipTable } from './ERMDashboard/components/RelationshipTable';
import { InteractionsModal } from './ERMDashboard/components/InteractionsModal';
import {
  fetchClients,
  fetchPipelineStages,
  fetchContactInteractions,
  closeInteractionsModal,
  changeSelectedErmDashboardTab,
  changeContactMineOnly,
} from '@/redux/slices/ERMDashboard';
import * as TabsPrimitive from '@radix-ui/react-tabs';
import { useSnackbar } from '@/contexts/SnackbarContext';

export default function ERMDashboard() {
  const relationshipTableRef = useRef(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const dispatch = useDispatch();
  const selectedClient = useSelector((state) => state.dashboard.selectedClient);
  const notesFeatureEnabled = useIsFeatureFlagEnabled('ui.erm_dashboard.notes');
  const showHidden = useSelector((state) => state.clientTable.queryParams.filters.showHidden);

  const interactionsModalOpen = useSelector((state) => state.dashboard.interactions.ui.isModalOpen);
  const selectedRelationship = useSelector((state) => state.dashboard.interactions.data.selectedRelationship);
  const contactInteractions = useSelector((state) => state.dashboard.interactions.data.contactInteractions);
  const isLoadingInteractions = useSelector((state) => state.dashboard.interactions.loading.loading);

  const [activeTab, setActiveTab] = useState('clientList');
  const { addSnackbar } = useSnackbar();

  useEffect(() => {
    dispatch(fetchClients());
    dispatch(fetchPipelineStages());
  }, [dispatch]);

  useEffect(() => {
    if (relationshipTableRef.current) {
      // Observe changes in the attributes and child list of the relationship table
      const observer = new MutationObserver(() => {
        const rect = relationshipTableRef.current.getBoundingClientRect();
        setPosition({ x: rect.left, y: rect.top }); // Update state with new position
      });

      observer.observe(relationshipTableRef.current, { attributes: true, childList: true, subtree: true });

      // Cleanup observer to prevent memory leaks
      return () => observer.disconnect();
    }
  }, [relationshipTableRef]);

  useEffect(() => {
    if (selectedClient && relationshipTableRef?.current) {
      // Delay scrolling to ensure the relationship table is fully rendered
      const timeoutId = setTimeout(() => {
        relationshipTableRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      }, 250); // Adjust the delay as needed

      // Cleanup the timeout to prevent memory leaks
      return () => clearTimeout(timeoutId);
    }
  }, [selectedClient, position]); // Re-trigger when position changes

  const onInteractionsClick = useCallback(
    (relationship) => {
      dispatch(fetchContactInteractions(relationship))
        .unwrap()
        .catch(() => {
          addSnackbar({
            title: 'Error',
            message: 'Failed to load interactions',
            pVariant: 'default',
          });
        });
    },
    [dispatch, addSnackbar],
  );

  const relationshipTable = useMemo(() => {
    if (!selectedClient) return null;

    return (
      <div ref={relationshipTableRef} className={`shadow-sm rounded-lg mt-4 transition-all duration-300 ease-in-out`}>
        <RelationshipTable onInteractionsClick={onInteractionsClick} context="client" />
      </div>
    );
  }, [selectedClient, onInteractionsClick]);

  const handleTabChange = useCallback(
    (value: string) => {
      setActiveTab(value);
      dispatch(changeSelectedErmDashboardTab(value));
      if (value === 'contactList') {
        dispatch(changeContactMineOnly(false));
      }
    },
    [dispatch],
  );

  return (
    <>
      <TabsPrimitive.Root defaultValue="clientList" className="relative" onValueChange={handleTabChange}>
        <main className="w-full">
          <div className="p-4">
            <div className="flex justify-between items-end">
              <TabsPrimitive.List className="flex relative z-0">
                <TabsPrimitive.Trigger
                  value="clientList"
                  className={`border-l border-t data-[state=active]:bg-card data-[state=active]:border-r 
                    data-[state=active]:shadow-[0_1px_0_0_#fff] data-[state=active]:z-10 data-[state=inactive]:bg-muted 
                    data-[state=inactive]:shadow-[inset_0_-8px_8px_-8px_rgba(0,0,0,0.1)] px-8 py-2.5 relative
                    rounded-t-lg transition-all`}
                >
                  Clients
                </TabsPrimitive.Trigger>
                <TabsPrimitive.Trigger
                  value="contactList"
                  className={`-ml-px border-r border-t data-[state=active]:bg-card data-[state=active]:border-l 
                    data-[state=active]:shadow-[0_1px_0_0_#fff] data-[state=active]:z-10 data-[state=inactive]:bg-muted 
                    data-[state=inactive]:shadow-[inset_0_-8px_8px_-8px_rgba(0,0,0,0.1)] px-8 py-2.5 relative
                    rounded-t-lg transition-all`}
                >
                  Contacts
                </TabsPrimitive.Trigger>
              </TabsPrimitive.List>
            </div>
            <div className={`rounded-b-lg rounded-tr-lg border bg-card relative z-0`}>
              <TabsPrimitive.Content value="clientList" className="m-0">
                <div className="flex flex-col gap-2 md:gap-4">
                  {activeTab === 'clientList' && <ClientTable />}
                  {relationshipTable}
                </div>
              </TabsPrimitive.Content>
              <TabsPrimitive.Content value="contactList" className="m-0">
                <RelationshipTable context="contact" />
              </TabsPrimitive.Content>
            </div>
          </div>
        </main>
      </TabsPrimitive.Root>

      <InteractionsModal
        isOpen={interactionsModalOpen}
        onClose={() => dispatch(closeInteractionsModal())}
        contactName={selectedRelationship?.contactDetails?.name}
        interactions={contactInteractions}
        notesFeatureEnabled={notesFeatureEnabled}
        isLoading={isLoadingInteractions}
      />
    </>
  );
}
