import { motion, AnimatePresence } from 'framer-motion';
import { Sparkles, ChevronDown } from 'lucide-react';
import { Card, CardContent } from '@/components/ui/card';

type CollapsibleInfoCardProps = {
  isExpanded: boolean;
  toggleExpanded: () => void;
  maxTopics: number;
};

export function CollapsibleInfoCard({ isExpanded, toggleExpanded, maxTopics }: CollapsibleInfoCardProps) {
  return (
    <Card>
      <CardContent className="p-6">
        <button onClick={toggleExpanded} className="flex items-center justify-between w-full">
          <h3 className="text-lg font-semibold flex items-center gap-2">
            <Sparkles className="w-4 h-4 text-blue-600" />
            What happens next?
          </h3>
          <motion.div animate={{ rotate: isExpanded ? 180 : 0 }} transition={{ duration: 0.2 }}>
            <ChevronDown className="h-4 w-4 text-muted-foreground" />
          </motion.div>
        </button>

        <AnimatePresence initial={false}>
          {isExpanded && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.2 }}
              className="overflow-hidden"
            >
              <div className="pt-4">
                <p className="text-muted-foreground">
                  Select up to {maxTopics} topics. Once you've selected your topics, our AI will generate engaging
                  LinkedIn posts tailored to your audience. We'll create 5 posts per week for each topic, maintaining an
                  ideal posting cadence. Each post will be crafted in your preferred tone.
                </p>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </CardContent>
    </Card>
  );
}
