import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  Assistant,
  SocialMediaAssistant,
  BusinessDevelopmentAssistant,
  RelationshipGrowthAssistant,
  Post,
  AssistantType,
  Topic,
} from '@/constants/types/assistant';
import { socialMediaAssistantsApi } from '@/helper/APIs/SocialMediaAssistantsApi';
import { ASSISTANT } from '@postilize/shared';

type AssistantsState = {
  assistants: Assistant[];
  businessDevelopmentAssistants: BusinessDevelopmentAssistant[];
  groupByType: boolean;
  posts: Post[];
  relationshipGrowthAssistants: RelationshipGrowthAssistant[];
  selectedAssistantId: string | null;
  socialMediaAssistants: SocialMediaAssistant[];
  strategies: any[];
};

function mapSequenceTypeToAssistantType(templateType: string): AssistantType {
  switch (templateType) {
    case 'acquire':
      return 'Business Development';
    case 'retain':
      return 'Relationship Growth';
    case 'grow':
      return 'Social Media';
    default:
      return 'Other';
  }
}

function loadGroupByType(): boolean {
  const storedValue = localStorage.getItem('groupByType');
  if (storedValue === null) return false;
  return storedValue === 'true';
}

const initialState: AssistantsState = {
  assistants: [],
  businessDevelopmentAssistants: [],
  groupByType: loadGroupByType(),
  posts: [],
  relationshipGrowthAssistants: [],
  selectedAssistantId: null,
  socialMediaAssistants: [],
  strategies: [],
};

export const assistantsSlice = createSlice({
  name: 'assistants',
  initialState,
  reducers: {
    setSelectedAssistantId(state, action: PayloadAction<string | null>) {
      state.selectedAssistantId = action.payload;
    },
    clearSelectedAssistantId(state) {
      state.selectedAssistantId = null;
    },
    setGroupByType(state, action: PayloadAction<boolean>) {
      state.groupByType = action.payload;
      localStorage.setItem('groupByType', JSON.stringify(action.payload));
    },
    setAssistantsFromSequences(state, action: PayloadAction<any[]>) {
      const sequences = action.payload;
      const mappedAssistants: Assistant[] = sequences.map((seq) => ({
        ...seq,
        _id: seq?._id,
        name: seq?.name,
        status:
          seq?.status?.toLowerCase() === ASSISTANT.STATUS.ACTIVE.toLowerCase()
            ? ASSISTANT.STATUS.ACTIVE
            : ASSISTANT.STATUS.PAUSED,
        type: mapSequenceTypeToAssistantType(seq?.template?.type ?? ''),
        startTime: typeof seq?.startTime === 'string' ? seq?.startTime : new Date(seq?.startTime ?? '').toISOString(),
      }));

      state.businessDevelopmentAssistants = mappedAssistants.filter(
        (a) => a?.type === 'Business Development',
      ) as BusinessDevelopmentAssistant[];
      state.relationshipGrowthAssistants = mappedAssistants.filter(
        (a) => a?.type === 'Relationship Growth',
      ) as RelationshipGrowthAssistant[];
    },
    updateTopics(
      state,
      action: PayloadAction<{
        assistantId: string;
        topics: Topic[];
      }>,
    ) {
      const { assistantId, topics } = action.payload;
      const assistant = state.socialMediaAssistants.find((a) => a?._id === assistantId);
      if (assistant) {
        assistant.topics = topics;
      }
    },
    updatePostFrequency(state, action: PayloadAction<{ assistantId: string; generationCadence: number }>) {
      const { assistantId, generationCadence } = action.payload;
      const found = state.socialMediaAssistants.find((a) => a?._id === assistantId);
      if (found) {
        found.generationCadence = generationCadence;
      }
    },
    updateSocialNetworks(state, action: PayloadAction<{ assistantId: string; socialNetworks: string[] }>) {
      const { assistantId, socialNetworks } = action.payload;
      const found = state.socialMediaAssistants.find((a) => a?._id === assistantId);
      if (found && found.settings) {
        found.settings.socialNetworks = socialNetworks;
      }
    },
    setPosts(state, action: PayloadAction<Post[]>) {
      state.posts = action.payload;
    },
    updateAssistantName(state, action: PayloadAction<{ id: string; name: string }>) {
      state.assistants = state.assistants.map((assistant) =>
        assistant?._id === action.payload?.id ? { ...assistant, name: action.payload?.name } : assistant,
      );

      state.businessDevelopmentAssistants = state.assistants.filter(
        (a) => a?.type === 'Business Development',
      ) as BusinessDevelopmentAssistant[];
      state.relationshipGrowthAssistants = state.assistants.filter(
        (a) => a?.type === 'Relationship Growth',
      ) as RelationshipGrowthAssistant[];
    },
    removeAssistant(state, action: PayloadAction<string>) {
      state.assistants = state.assistants.filter((a) => a?._id !== action.payload);
      state.socialMediaAssistants = state.socialMediaAssistants.filter((a) => a?._id !== action.payload);
      state.businessDevelopmentAssistants = state.businessDevelopmentAssistants.filter(
        (a) => a?._id !== action.payload,
      );
      state.relationshipGrowthAssistants = state.relationshipGrowthAssistants.filter((a) => a?._id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(socialMediaAssistantsApi.endpoints.getSocialMediaAssistants.matchFulfilled, (state, action) => {
        state.socialMediaAssistants = action.payload?.assistants ?? [];
      })
      .addMatcher(socialMediaAssistantsApi.endpoints.createSocialMediaAssistant.matchFulfilled, (state, action) => {
        state.socialMediaAssistants.push(action.payload?.assistant);
      })
      .addMatcher(socialMediaAssistantsApi.endpoints.deleteSocialMediaAssistant.matchFulfilled, (state, action) => {
        const deletedId = action.meta?.arg?.originalArgs?.id;
        state.socialMediaAssistants = state.socialMediaAssistants.filter((a) => a?._id !== deletedId);
        state.assistants = state.assistants.filter((a) => a?._id !== deletedId);
        state.businessDevelopmentAssistants = state.businessDevelopmentAssistants.filter((a) => a?._id !== deletedId);
        state.relationshipGrowthAssistants = state.relationshipGrowthAssistants.filter((a) => a?._id !== deletedId);
      })
      .addMatcher(socialMediaAssistantsApi.endpoints.updateSocialMediaAssistant.matchFulfilled, (state, action) => {
        const updatedAssistant = action.payload;
        state.socialMediaAssistants = state.socialMediaAssistants.map((assistant) =>
          assistant?._id === updatedAssistant?._id ? updatedAssistant : assistant,
        );
      });
  },
});

export const {
  setSelectedAssistantId,
  clearSelectedAssistantId,
  setGroupByType,
  setAssistantsFromSequences,
  updateTopics,
  updatePostFrequency,
  updateSocialNetworks,
  setPosts,
  updateAssistantName,
  removeAssistant,
} = assistantsSlice.actions;

export const selectSelectedAssistantId = (state: any) => state.assistants?.selectedAssistantId;
export const selectSelectedAssistant = (state: any) => {
  const selectedId = state.assistants?.selectedAssistantId;
  const allAssistants = [
    ...(state.assistants?.socialMediaAssistants ?? []),
    ...(state.assistants?.businessDevelopmentAssistants ?? []),
    ...(state.assistants?.relationshipGrowthAssistants ?? []),
  ];
  return allAssistants.find((a) => a?._id === selectedId);
};
export const selectGroupByType = (state: any) => state.assistants?.groupByType;
export const selectAssistants = (state: any) => state.assistants?.assistants;

export const selectBusinessDevelopmentAssistants = (state: any) => state.assistants?.businessDevelopmentAssistants;
export const selectRelationshipGrowthAssistants = (state: any) => state.assistants?.relationshipGrowthAssistants;
export const selectSocialMediaAssistants = (state: { assistants: AssistantsState }) =>
  state.assistants?.socialMediaAssistants;

export const selectSocialMediaAssistantById = (state: any, id: string) =>
  state.assistants?.socialMediaAssistants?.find((assistant: SocialMediaAssistant) => assistant?._id === id);

export default assistantsSlice.reducer;
