import { useState, useCallback, useMemo } from 'react';
import { mergeCompanyClients } from '@/helper/apiHelper';
import { invalidateClientTableCacheForAllPages, fetchClients } from '@/redux/slices/ERMDashboard/clientSlice';
import { useDispatch, useSelector } from 'react-redux';
import type { Client } from '@/v0/ERMDashboard/components/ClientTable';

type MergeStatus = {
  isMerging: boolean;
  message: string;
};

/**
 * Custom hook to manage client selection state and merge operations
 * Handles both checkbox selection and client merging functionality
 */
export function useClientCheckboxSelection() {
  // Track row hover state for UI feedback
  const [hoveredRow, setHoveredRow] = useState<string | null>(null);

  // Store selected client IDs for efficient lookup
  const [selectedClientIds, setSelectedClientIds] = useState<Set<string>>(new Set());

  // Track merge operation status and feedback
  const [mergeStatus, setMergeStatus] = useState<MergeStatus>({
    isMerging: false,
    message: '',
  });

  const dispatch = useDispatch();

  // Get all cached clients from Redux store
  const allCachedClients = useSelector((state: any) => {
    const { cacheByPage } = state.clientTable.data;
    return Object.values(cacheByPage).flat() as Client[];
  });

  // Map selected IDs to full client objects for operations
  const selectedClientObjects = useMemo(() => {
    if (!allCachedClients) return [];
    return allCachedClients.filter((client) => client && client._id && selectedClientIds.has(client._id));
  }, [allCachedClients, selectedClientIds]);

  // Toggle client selection state
  const handleSelectClient = useCallback((client: Client) => {
    setSelectedClientIds((prev) => {
      const newSelected = new Set(prev);
      if (newSelected.has(client._id)) {
        newSelected.delete(client._id);
      } else {
        newSelected.add(client._id);
      }
      return newSelected;
    });
  }, []);

  const selectedCount = useMemo(() => selectedClientIds.size, [selectedClientIds]);

  const clearMergeStatus = useCallback((timeout: number = 2500) => {
    setTimeout(() => {
      setMergeStatus((prev) => ({ ...prev, message: '' }));
    }, timeout);
  }, []);

  const handleMergeClients = useCallback(
    async (mergeTarget: string | { newClientName: string }) => {
      setMergeStatus({ isMerging: true, message: 'Merging clients...' });

      const response = await mergeCompanyClients(Array.from(selectedClientIds), mergeTarget);

      console.log('response', response);

      if (response.success) {
        setMergeStatus({ isMerging: false, message: `${selectedCount} clients merged successfully` });
        setSelectedClientIds(new Set());
        setHoveredRow(null);

        // Refresh the client table after merging
        dispatch(invalidateClientTableCacheForAllPages());
        dispatch(fetchClients());
      } else if (response.status === 403) {
        setMergeStatus({
          isMerging: false,
          message: `Unauthorized operation.`,
        });
      } else {
        setMergeStatus({
          isMerging: false,
          message: `Error merging ${selectedCount} clients.`,
        });
      }
      clearMergeStatus();
    },
    [selectedClientIds, selectedCount, clearMergeStatus],
  );

  return {
    hoveredRow,
    setHoveredRow,
    selectedClientIds,
    selectedCount,
    mergeStatus,
    selectedClientObjects,
    handleSelectClient,
    handleMergeClients,
  };
}
