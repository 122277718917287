export const TutorialCustomStyling = {
  options: {
    arrowColor: 'hsl(var(--background))',
    backgroundColor: 'hsl(var(--background))',
    overlayColor: 'rgba(0, 0, 0, 0.5)',
    primaryColor: 'hsl(var(--primary))',
    textColor: 'hsl(var(--foreground))',
    width: '400px',
    zIndex: 10000,
  },
  buttonNext: {
    'backgroundColor': 'hsl(var(--primary))',
    'color': 'white',
    'border': 'none',
    'borderRadius': 'var(--radius)',
    'padding': '0.5rem 1rem',
    'fontSize': '0.875rem',
    'fontWeight': 500,
    'height': '2.5rem',
    'minWidth': '4rem',
    'cursor': 'pointer',
    'display': 'inline-flex',
    'alignItems': 'center',
    'justifyContent': 'center',
    'transition': 'background-color 0.2s',
    'textTransform': 'none',
    '&:hover': {
      backgroundColor: 'hsl(var(--primary)/.9)',
    },
  },
  buttonBack: {
    'backgroundColor': 'transparent',
    'border': 'none',
    'borderRadius': 'var(--radius)',
    'color': 'hsl(var(--foreground))',
    'padding': '0.5rem 1rem',
    'fontSize': '0.875rem',
    'fontWeight': 500,
    'height': '2.5rem',
    'minWidth': '4rem',
    'cursor': 'pointer',
    'display': 'inline-flex',
    'alignItems': 'center',
    'justifyContent': 'center',
    'transition': 'background-color 0.2s',
    'textTransform': 'none',
    '&:hover': {
      backgroundColor: 'hsl(var(--accent))',
      color: 'hsl(var(--accent-foreground))',
    },
  },
  buttonSkip: {
    'backgroundColor': 'transparent',
    'border': 'none',
    'borderRadius': 'var(--radius)',
    'color': 'hsl(var(--muted-foreground))',
    'padding': '0.5rem 1rem',
    'fontSize': '0.875rem',
    'fontWeight': 500,
    'height': '2.5rem',
    'minWidth': '4rem',
    'cursor': 'pointer',
    'display': 'inline-flex',
    'alignItems': 'center',
    'justifyContent': 'center',
    'transition': 'opacity 0.2s',
    'textTransform': 'none',
    '&:hover': {
      opacity: 0.8,
    },
  },
  spotlight: {
    borderRadius: 'var(--radius)',
  },
  tooltip: {
    backgroundColor: 'hsl(var(--background))',
    borderRadius: 'calc(var(--radius) - 2px)',
    boxShadow: '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
    zIndex: 10000,
  },
  tooltipContainer: {
    textAlign: 'center',
    zIndex: 10000,
  },
  tooltipTitle: {
    fontSize: '1.5rem',
    fontWeight: 600,
    lineHeight: '2rem',
    marginBottom: '0.5rem',
    color: 'hsl(var(--foreground))',
    padding: '1rem 1.5rem 0',
  },
  tooltipContent: {
    padding: '0 1.5rem 1.5rem',
    color: 'hsl(var(--muted-foreground))',
  },
  tooltipFooter: {
    alignItems: 'center',
    borderTop: '1px solid hsl(var(--border))',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1.5rem',
    marginTop: '1rem',
  },
  overlay: {
    zIndex: 9999,
  },
};
