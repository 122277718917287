import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Http from '@/http/httpClient';
import { getDefaultStrategies, getDefaultTemplates, getAvailableList, toggleSequencePaused } from '@/helper/apiHelper';
import { GetCookie } from '@/helper/cookieManager';
import {
  setAssistantsFromSequences,
  updateAssistantName,
  updatePostFrequency,
  updateSocialNetworks,
} from '@/redux/slices/Assistants/assistantsSlice';
import {
  useGetSocialMediaAssistantsQuery,
  useCreateSocialMediaAssistantMutation,
  useDeleteSocialMediaAssistantMutation,
  useUpdateSocialMediaAssistantMutation,
} from '@/helper/APIs/SocialMediaAssistantsApi';
import { ASSISTANT } from '@postilize/shared';

type Template = object;
type Strategy = object;
type AvailableList = object;

type UserBio = {
  name: string;
  company?: string;
};

/**
 * Represents a base data structure for an assistant entity in Postilize.
 *
 * This type provides common properties shared across assistant types: social media,
 * business development, and relationship growth assistants. Captures essential
 * details like unique ID, display name, status, and type. Optional properties
 * include creation timestamp, start time, and contact engagement counts.
 *
 * Usage:
 * - Used by hooks (useAssistants) to fetch/combine/sort assistant data
 * - Powers UI components (AssistantsSection, AssistantItem, AssistantActions)
 * - Integrated with Redux slices and RTK Query endpoints (SocialMediaAssistantsApi)
 *   for state management and CRUD operations
 *
 * Benefits:
 * - Ensures consistency/type safety across assistant handling
 * - Foundation for specialized types with unified backend/frontend interface
 *
 * @property {string} _id - Unique identifier of the assistant (typically a MongoDB ObjectId).
 * @property {string} name - The display name of the assistant.
 * @property {string} status - The current operational status of the assistant (e.g., 'Active', 'Paused'),
 *    as defined in the ASSISTANT.STATUS constants.
 * @property {string} type - The classification type of the assistant (e.g., 'Social Media', 'Business Development').
 * @property {string} [createdAt] - Optional timestamp representing when the assistant was created.
 * @property {string} [startedAt] - Optional timestamp indicating when the assistant began operation.
 * @property {number} [contacts] - Optional number indicating associated contacts or engagements.
 */
type Assistant = {
  _id: string;
  name: string;
  status: (typeof ASSISTANT.STATUS)[keyof typeof ASSISTANT.STATUS];
  type: string;
  createdAt?: string;
  startedAt?: string;
  contacts?: number;
};

type UseAssistantsReturn = {
  userBio: UserBio | null;
  defaultTemplates: Template[];
  strategies: Strategy[];
  availableList: AvailableList[];
  targetAudience: any;
  stepText: string[];
  stepTypes: any[];
  isEmpty: boolean;
  isLoading: boolean;
  initialLoading: boolean;
  strategyLoading: boolean;
  initialListLoading: boolean;
  modalOpen: boolean;
  openSequenceModal: () => void;
  closeSequenceModal: () => void;
  setCreatedSequenceId: React.Dispatch<React.SetStateAction<string | null>>;
  createdSequenceId: string | null;
  fetchAssistantsData: (sequenceId?: string) => void;
  createAssistant: (data: any) => Promise<void>;
  pauseOrResumeAssistant: (assistant: Assistant) => Promise<void>;
  handleAssistantRename: (id: string, newName: string) => Promise<void>;
  handleAssistantDelete: (id: string) => Promise<void>;
  handleAssistantEdit: (id: string) => void;
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  showActive: boolean;
  setShowActive: (active: boolean) => void;
  showPaused: boolean;
  setShowPaused: (paused: boolean) => void;
  sortBy: string;
  setSortBy: (sort: string) => void;
  groupByType: boolean;
  setGroupByType: (type: boolean) => void;
  filteredAndSortedAssistants: () => Assistant[];
  groupedAssistants: any;
  renameAssistant: (assistantId: string, newName: string) => void;
  updateAssistantPostFrequency: (assistantId: string, postFrequency: string) => void;
  updateAssistantSocialNetworks: (assistantId: string, socialNetworks: string[]) => void;
  combinedAssistants: () => Assistant[];
  BDAssistants: any;
  RGAssistants: any;
  socialMediaAssistants: any;
};

export function useAssistants(): UseAssistantsReturn {
  const curUser = GetCookie('user');
  const dispatch = useDispatch();

  const delegationAccessId = useSelector((state: any) => state.auth?.delegationAccessId);
  const forDelegation = useSelector((state: any) => state.auth?.forDelegation || false);
  const userFromStore = useSelector((state: any) => state.auth?.user);

  const [userBio, setUserBio] = useState<UserBio | null>(null);
  const [defaultTemplates, setDefaultTemplates] = useState<Template[]>([]);
  const [strategies, setStrategies] = useState<Strategy[]>([]);
  const [availableList, setAvailableList] = useState<AvailableList[]>([]);
  const [stepText, setStepText] = useState<string[]>([]);
  const [stepTypes, setStepTypes] = useState<any[]>([]);
  const [isEmpty, setIsEmpty] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  // New states moved from ClientAcquisition
  const [initialLoading, setInitialLoading] = useState(true);
  const [strategyLoading, setStrategyLoading] = useState(true);
  const [initialListLoading, setInitialListLoading] = useState(true);
  const [targetAudience, setTargetAudience] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [createdSequenceId, setCreatedSequenceId] = useState<string | null>(null);
  const { isLoading: smIsLoading } = useGetSocialMediaAssistantsQuery({
    accessId: delegationAccessId,
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [showActive, setShowActive] = useState(true);
  const [showPaused, setShowPaused] = useState(true);
  const [sortBy, setSortBy] = useState('alphabetically');
  const [groupByType, setGroupByType] = useState(false);
  const socialMediaAssistants: any[] = useSelector((state: any) => state.assistants?.socialMediaAssistants ?? []);
  const businessDevelopmentAssistants: Assistant[] = useSelector(
    (state: any) => state.assistants?.businessDevelopmentAssistants ?? [],
  );
  const relationshipGrowthAssistants: Assistant[] = useSelector(
    (state: any) => state.assistants?.relationshipGrowthAssistants ?? [],
  );

  const [createSocialMediaAssistant] = useCreateSocialMediaAssistantMutation();
  const [deleteSocialMediaAssistant] = useDeleteSocialMediaAssistantMutation();
  const [updateSocialMediaAssistant] = useUpdateSocialMediaAssistantMutation();

  const combinedAssistants = useCallback(() => {
    return [...businessDevelopmentAssistants, ...relationshipGrowthAssistants, ...socialMediaAssistants];
  }, [businessDevelopmentAssistants, relationshipGrowthAssistants, socialMediaAssistants]);

  const getUpdateEndpoint = (forDelegation: boolean, delegationAccessId: string | undefined) => {
    return forDelegation && delegationAccessId
      ? `updateSequenceSettings/${delegationAccessId}`
      : 'updateSequenceSettings';
  };

  const getDeleteEndpoint = (forDelegation: boolean, delegationAccessId: string | undefined) => {
    return forDelegation && delegationAccessId ? `deleteSequenceData/${delegationAccessId}` : 'deleteSequenceData';
  };

  const fetchBio = async () => {
    try {
      const response = await Http.getData('setup/getBio');
      setUserBio({ name: curUser?.name, company: response?.bio?.companyName });
    } catch (error) {
      console.error('Failed to fetch bio:', error);
    }
  };

  const fetchAssistantsData = useCallback(
    (sequenceId?: string) => {
      setIsLoading(true);
      Http.getData('getSequences', { sequenceId })
        .then((response: any) => {
          if (response?.status) {
            dispatch(setAssistantsFromSequences(response?.sequences ?? []));
            setIsEmpty((response?.sequences?.length ?? 0) === 0);
          }
        })
        .catch((error: any) => console.error('Failed to fetch assistants:', error))
        .finally(() => setIsLoading(false));
    },
    [dispatch],
  );

  const fetchDefaultTemplatesFn = async () => {
    try {
      const response = await getDefaultTemplates();
      if (response?.status) {
        setDefaultTemplates([...(response?.templates ?? [])]);
        if (response?.stepText) {
          setStepText([...response.stepText]);
        }
      }
    } catch (error) {
      console.error('Failed to fetch default templates:', error);
    } finally {
      setInitialLoading(false);
    }
  };

  const fetchStrategiesFn = async () => {
    try {
      const response = await getDefaultStrategies();
      if (response?.status) {
        setStrategies([...(response?.strategies ?? [])]);
        setTargetAudience(response?.targetAudience ?? {});
      }
    } catch (error) {
      console.error('Error fetching strategies:', error);
    } finally {
      setStrategyLoading(false);
    }
  };

  const fetchAvailableListsFn = async () => {
    try {
      const response = await getAvailableList();
      if (response?.status) {
        setAvailableList([...(response?.lists ?? [])]);
      }
    } catch (error) {
      console.error('Error fetching available lists:', error);
    } finally {
      setInitialListLoading(false);
    }
  };

  const createAssistant = async (data: any) => {
    try {
      await createSocialMediaAssistant({
        data,
        accessId: delegationAccessId,
      });
    } catch (error: any) {
      throw new Error(error.data?.message || 'Failed to create assistant');
    }
  };

  const pauseOrResumeAssistant = async (assistant: Assistant) => {
    try {
      if (assistant?.type === 'Social Media') {
        await updateSocialMediaAssistant({
          _id: assistant?._id,
          data: {
            status:
              assistant?.status?.toLowerCase() === ASSISTANT.STATUS.ACTIVE.toLowerCase()
                ? ASSISTANT.STATUS.PAUSED
                : ASSISTANT.STATUS.ACTIVE,
          },
          accessId: delegationAccessId,
        });
      } else {
        await toggleSequencePaused(
          assistant,
          assistant?.status?.toLowerCase() === ASSISTANT.STATUS.ACTIVE.toLowerCase()
            ? ASSISTANT.STATUS.PAUSED
            : ASSISTANT.STATUS.ACTIVE,
          delegationAccessId,
        );
        fetchAssistantsData();
      }
    } catch (error: any) {
      console.error('Failed to pause/resume assistant:', error);
      throw new Error('Failed to update assistant status');
    }
  };

  const handleAssistantRename = async (id: string, newName: string) => {
    try {
      const endpoint = getUpdateEndpoint(forDelegation, delegationAccessId);
      await Http.postData(endpoint, {
        type: 'Rename',
        sequenceData: {
          _id: id,
          sequenceName: newName,
        },
      });
      fetchAssistantsData();
    } catch (error) {
      console.error('Failed to rename assistant:', error);
    }
  };

  const handleAssistantDelete = async (id: string) => {
    try {
      const assistant = combinedAssistants().find((a) => a?._id === id);
      if (!assistant) throw new Error('Assistant not found');

      if (assistant?.type === 'Social Media') {
        await deleteSocialMediaAssistant({
          id: assistant?._id,
          accessId: delegationAccessId,
        });
      } else {
        const endpoint = getDeleteEndpoint(forDelegation, delegationAccessId);
        await Http.postData(endpoint, {
          seqId: id,
        });
        fetchAssistantsData();
      }
    } catch (error: any) {
      console.error('Failed to delete assistant:', error);
      throw new Error('Failed to delete assistant');
    }
  };

  const handleAssistantEdit = async (id: string) => {
    try {
      const response = await Http.getData('getSequences', { sequenceId: id });
      if (response?.status && response?.sequences?.length > 0) {
        return response?.sequences?.[0];
      }
      return null;
    } catch (error) {
      console.error('Failed to fetch assistant data:', error);
      return null;
    }
  };

  const openSequenceModal = () => setModalOpen(true);
  const closeSequenceModal = () => setModalOpen(false);

  const filteredAndSortedAssistants = useCallback(() => {
    return combinedAssistants()
      .filter((assistant) => {
        const isActive = assistant?.status?.toLowerCase() === ASSISTANT.STATUS.ACTIVE.toLowerCase();
        const isPaused = assistant?.status?.toLowerCase() === ASSISTANT.STATUS.PAUSED.toLowerCase();
        if (!showActive && isActive) return false;
        if (!showPaused && isPaused) return false;
        return assistant?.name?.toLowerCase().includes(searchTerm?.toLowerCase() ?? '');
      })
      .sort((a, b) => {
        switch (sortBy) {
          case 'alphabetically':
            return (a?.name ?? '').localeCompare(b?.name ?? '');
          case 'contacts':
            return (b?.contacts ?? 0) - (a?.contacts ?? 0);
          case 'created':
            if (a?.createdAt && b?.createdAt) {
              return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
            }
            return 0;
          case 'started':
            if (a?.startedAt && b?.startedAt) {
              return new Date(b.startedAt).getTime() - new Date(a.startedAt).getTime();
            }
            return 0;
          default:
            return 0;
        }
      });
  }, [combinedAssistants, showActive, showPaused, searchTerm, sortBy]);

  const groupedAssistants = useCallback(() => {
    if (!groupByType) {
      return { All: filteredAndSortedAssistants() };
    }
    return filteredAndSortedAssistants().reduce((acc: any, assistant: Assistant) => {
      if (!acc[assistant?.type]) {
        acc[assistant?.type] = [];
      }
      acc[assistant?.type].push(assistant);
      return acc;
    }, {});
  }, [filteredAndSortedAssistants, groupByType]);

  const renameAssistant = (assistantId: string, newName: string) => {
    dispatch(updateAssistantName({ assistantId, name: newName }));
  };

  const updateAssistantPostFrequency = (assistantId: string, postFrequency: string) => {
    dispatch(updatePostFrequency({ assistantId, postFrequency }));
  };

  const updateAssistantSocialNetworks = (assistantId: string, socialNetworks: string[]) => {
    dispatch(updateSocialNetworks({ assistantId, socialNetworks }));
  };

  useEffect(() => {
    fetchBio();
    fetchAssistantsData();
    fetchDefaultTemplatesFn();
    fetchStrategiesFn();
    fetchAvailableListsFn();
  }, [fetchAssistantsData]);

  return {
    availableList,
    closeSequenceModal,
    createAssistant,
    createdSequenceId,
    defaultTemplates,
    fetchAssistantsData,
    filteredAndSortedAssistants,
    groupByType,
    groupedAssistants,
    handleAssistantDelete,
    handleAssistantEdit,
    handleAssistantRename,
    initialListLoading,
    initialLoading,
    isEmpty,
    isLoading: isLoading || smIsLoading,
    modalOpen,
    openSequenceModal,
    pauseOrResumeAssistant,
    renameAssistant,
    searchTerm,
    setCreatedSequenceId,
    setGroupByType,
    setSearchTerm,
    setShowActive,
    setShowPaused,
    setSortBy,
    showActive,
    showPaused,
    sortBy,
    stepText,
    stepTypes,
    strategies,
    strategyLoading,
    targetAudience,
    updateAssistantPostFrequency,
    updateAssistantSocialNetworks,
    userBio,
    combinedAssistants,
    socialMediaAssistants,
  };
}
