import { isRejectedWithValue, isFulfilled } from '@reduxjs/toolkit';
import type { Middleware } from '@reduxjs/toolkit';
import { toast } from '@/hooks/use-toast';
import { ERROR_CODES } from '@postilize/shared';

export function getFriendlyErrorMessage(error: any): string {
  const errorCode = error?.code;
  const backendMessage = error?.message;

  switch (errorCode) {
    case ERROR_CODES.NOT_FOUND:
      return 'Assistant not found. Please refresh and try again.';
    case ERROR_CODES.INVALID_ID:
      return 'Invalid assistant ID. Please refresh and try again.';
    case ERROR_CODES.ALREADY_EXISTS:
      return 'An assistant with this name already exists. Please choose a different name.';
    case ERROR_CODES.INVALID_TOPICS:
      return 'Please add at least one topic before proceeding.';
    case ERROR_CODES.VALIDATION_ERROR:
      if (backendMessage?.includes('unique')) {
        return 'Each topic must be unique. Please remove duplicate topics.';
      } else if (backendMessage?.includes('empty')) {
        return 'The assistant name cannot be empty. Please provide a valid name.';
      } else if (backendMessage?.includes('social network')) {
        return 'One or more selected social networks are not supported.';
      }
      return 'Please check your input and try again.';
    case ERROR_CODES.UNAUTHORIZED:
      return 'You do not have permission to perform this action.';
    default:
      return 'We encountered an unexpected error. Please try again later.';
  }
}
/**
 * Middleware that intercepts API actions and shows toast notifications
 * for both success and error cases.
 */
export const assistantsNotificationMiddleware: Middleware = () => (next) => (action) => {
  // Handle errors
  if (isRejectedWithValue(action)) {
    if (action.type.startsWith('socialMediaAssistantsApi/')) {
      const error = action.payload?.data?.error || action.payload;
      const friendlyMessage = getFriendlyErrorMessage(error);

      toast({
        title: 'Error',
        description: friendlyMessage,
        variant: 'destructive',
      });
    }
  }

  // Handle success cases
  if (isFulfilled(action)) {
    if (action.meta?.arg?.endpointName === 'updateSocialMediaAssistant') {
      toast({
        title: 'Assistant Updated',
        description: 'Assistant settings were successfully updated.',
        variant: 'success',
      });
    } else if (action.meta?.arg?.endpointName === 'createSocialMediaAssistant') {
      toast({
        title: 'Assistant Created',
        description: 'New assistant was successfully created.',
        variant: 'success',
      });
    } else if (action.meta?.arg?.endpointName === 'deleteSocialMediaAssistant') {
      toast({
        title: 'Assistant Deleted',
        description: 'Assistant was successfully deleted.',
        variant: 'success',
      });
    }
  }

  return next(action);
};
