import * as React from 'react';
import { Divider, Stack } from '@mui/material';
import PInput from '../../../components/library/PInput'; // Assuming correct path
import PCheckbox from '../../../components/library/PCheckbox';
import PButton from '../../../components/library/PButton';
import PTypography from '@/components/library/PTypography';
import { setBccAndSignature } from '@/helper/apiHelper';
import { useDispatch, useSelector } from 'react-redux';
import { setUser as setAuthSliceUser } from '@/redux/authSlice';

const validateEmail = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

const headerStyle = {
  paddingLeft: '40px',
  paddingRight: '40px',
  paddingTop: '14px',
  paddingBottom: '14px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const contentStyle = {
  padding: '40px',
  maxWidth: '560px',
};

export default function Email() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [emailSignature, setEmailSignature] = React.useState(user?.emailSignature || '');
  const [sequenceBcc, setSequenceBcc] = React.useState(user?.sequenceBcc || '');
  const [isSequenceBccValid, setIsSequenceBccValid] = React.useState(() => validateEmail(user?.sequenceBcc || ''));
  const [sendLinkedInBCC, setSendLinkedInBCC] = React.useState(() => {
    return isSequenceBccValid && user?.sendLinkedInBCC ? user.sendLinkedInBCC : false;
  });
  const [sendEmailBCC, setSendEmailBCC] = React.useState(() => {
    return isSequenceBccValid && user?.sendEmailBCC ? user.sendEmailBCC : false;
  });
  const [isLoading, setIsLoading] = React.useState(false);

  const handleSendLinkedInBCCChange = (event) => {
    setSendLinkedInBCC(event.target.checked);
  };

  const handleSendEmailBCCChange = (event) => {
    setSendEmailBCC(event.target.checked);
  };

  const handleEmailSignatureChange = (event) => {
    setEmailSignature(event.target.value);
  };

  const handleSequenceBccChange = (event) => {
    const email = event.target.value;
    setSequenceBcc(email);
    const isValid = validateEmail(email);
    setIsSequenceBccValid(isValid);

    if (!isValid) {
      setSendLinkedInBCC(false);
      setSendEmailBCC(false);
    }
  };

  const saveEmailSettings = async () => {
    setIsLoading(true);

    try {
      const successful = await setBccAndSignature({
        emailSignature,
        sequenceBcc,
        sendLinkedInBCC,
        sendEmailBCC,
      });
      if (successful) {
        dispatch(
          setAuthSliceUser({
            ...user,
            emailSignature,
            sequenceBcc,
            sendLinkedInBCC,
            sendEmailBCC,
          }),
        );
      } else {
        throw new Error('Failed to update settings');
      }
    } catch (error) {
      // Show error toast
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div style={headerStyle}>
        <PTypography size="h1" weight="regular">
          Email
        </PTypography>
        <PButton onClick={saveEmailSettings} disabled={!isSequenceBccValid || isLoading}>
          Save
        </PButton>
      </div>

      <Divider />

      <Stack gap="20px" style={contentStyle}>
        <Stack gap="8px">
          <PTypography size="body2" weight="bold">
            Email signature
          </PTypography>
          <PInput
            multiline
            rows={7}
            value={emailSignature}
            onChange={handleEmailSignatureChange}
            // "Your Name\nTitle\nCompany" does not work, it renders the \n as text
            placeholder="Your Name&#13;&#10;Title&#13;&#10;Company&#13;&#10;Website"
          />
        </Stack>

        <Stack gap="8px">
          <PTypography size="body2" weight="bold">
            Email address for CRM updates via BCC
          </PTypography>
          <PInput
            value={sequenceBcc}
            onChange={handleSequenceBccChange}
            placeholder="name@email.com"
            error={!isSequenceBccValid && sequenceBcc !== ''}
            helperText={!isSequenceBccValid && sequenceBcc !== '' ? 'Please enter a valid email address.' : ''}
          />
        </Stack>

        <Stack gap="8px">
          <PTypography size="body2" weight="bold">
            BCC Options
          </PTypography>
          <Stack direction="row" alignItems="center" spacing={1}>
            <PCheckbox checked={sendEmailBCC} onChange={handleSendEmailBCCChange} disabled={!isSequenceBccValid} />
            <PTypography weight="regular">Send BCC copies of emails</PTypography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <PCheckbox
              checked={sendLinkedInBCC}
              onChange={handleSendLinkedInBCCChange}
              disabled={!isSequenceBccValid}
            />
            <PTypography weight="regular">Send BCC copies of LinkedIn messages</PTypography>
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
}
