import React, { useState, useEffect } from 'react';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import useTutorialClickHandlers from './useTutorialClickHandlers';
import useTutorialMode from './useTutorialMode';
import { TutorialConfirmationDialog } from './TutorialConfirmationDialog';
import { INITIAL_LOCALE, WELCOME_MESSAGE, TUTORIAL_STEPS } from './TutorialConstants';
import { TutorialCustomStyling } from './TutorialCustomStyling';
import { TUTORIAL_TASK_NAMES } from '@postilize/shared/constants/tutorial';

const JoyrideTutorial = () => {
  const [showWelcome, setShowWelcome] = useState(true);
  const [joyrideLocale, setJoyrideLocale] = useState(INITIAL_LOCALE);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isJoyrideRunning, setIsJoyrideRunning] = useState(true);
  const [stepIndex, setStepIndex] = useState(0);

  const handleElementClick = () => {
    setStepIndex(stepIndex + 1);
  };

  useTutorialClickHandlers(handleElementClick);
  const { isTutorialMode, tutorialTasks, updateTutorialTasks, disableTutorialMode, skipTutorialTasks } =
    useTutorialMode();

  if (!isTutorialMode) {
    return null;
  }

  // Define tutorial steps based on tutorial tasks
  const welcomeStep = tutorialTasks.includes(TUTORIAL_TASK_NAMES.downloadLinkedInChromeExtension)
    ? WELCOME_MESSAGE.newWelcome
    : WELCOME_MESSAGE.welcomeBack;

  const settingsStep =
    tutorialTasks.includes(TUTORIAL_TASK_NAMES.createTopics) ||
    tutorialTasks.includes(TUTORIAL_TASK_NAMES.connectSocial)
      ? [TUTORIAL_STEPS.settings]
      : [];

  const chromeExtensionStep = tutorialTasks.includes(TUTORIAL_TASK_NAMES.downloadLinkedInChromeExtension)
    ? [TUTORIAL_STEPS.chromeExtension]
    : [];

  const mobileAppStep = tutorialTasks.includes(TUTORIAL_TASK_NAMES.downloadMobileApp) ? [TUTORIAL_STEPS.mobileApp] : [];

  const topicsStep = tutorialTasks.includes(TUTORIAL_TASK_NAMES.createTopics)
    ? [TUTORIAL_STEPS.topics, TUTORIAL_STEPS.fillTopics]
    : [];

  const socialStep = tutorialTasks.includes(TUTORIAL_TASK_NAMES.connectSocial)
    ? [TUTORIAL_STEPS.social, TUTORIAL_STEPS.connectLinkedIn]
    : [];

  // Combine to form ordered steps to be rendered
  const orderedSteps = [
    welcomeStep,
    ...chromeExtensionStep,
    ...mobileAppStep,
    ...settingsStep,
    ...topicsStep,
    ...socialStep,
  ];

  const steps = orderedSteps.map((step) => {
    return {
      ...step,
      ...(step.getDynamicProps ? step.getDynamicProps() : {}),
    };
  });

  const stepToTaskMapping = {
    [TUTORIAL_STEPS.chromeExtension.target]: TUTORIAL_TASK_NAMES.downloadLinkedInChromeExtension,
    [TUTORIAL_STEPS.mobileApp.target]: TUTORIAL_TASK_NAMES.downloadMobileApp,
    [TUTORIAL_STEPS.fillTopics.target]: TUTORIAL_TASK_NAMES.createTopics,
    [TUTORIAL_STEPS.connectLinkedIn.target]: TUTORIAL_TASK_NAMES.connectSocial,
  };

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;

    // handle skip button
    if (action === ACTIONS.SKIP) {
      setIsDialogOpen(true);
      setIsJoyrideRunning(false);
      return;
    }

    if (index === 0 && action === ACTIONS.NEXT) {
      setShowWelcome(false);
      setJoyrideLocale({
        ...joyrideLocale,
        next: 'Next',
      });
    }

    // step after / target not found
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // update tutorial task status when user clicks next at the end of a step
      if (action === ACTIONS.NEXT) {
        const currentStep = steps[index];
        const taskName = stepToTaskMapping[currentStep.target];
        if (taskName) {
          updateTutorialTasks(taskName);
        }
      }
      // controls the step index for next and previous buttons
      setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
      return;
    }

    // If the tutorial finishes
    if (status === STATUS.FINISHED) {
      disableTutorialMode();
    }
  };

  // handle skip dialog confirm
  const handleDialogConfirm = () => {
    setIsDialogOpen(false);
    skipTutorialTasks();
    disableTutorialMode();
    setIsJoyrideRunning(false);
  };

  // handle skip dialog cancel
  const handleDialogCancel = () => {
    setIsDialogOpen(false);
    setIsJoyrideRunning(true);
  };

  return (
    <>
      <Joyride
        steps={steps}
        stepIndex={stepIndex}
        run={isJoyrideRunning}
        continuous={true}
        showSkipButton={true}
        hideCloseButton={true}
        disableScrolling={true}
        spotlightClicks={true}
        floaterProps={{
          disableAnimation: true,
          styles: {
            floater: {
              filter: 'drop-shadow(0 4px 6px rgb(0 0 0 / 0.1))',
              zIndex: 10000,
            },
          },
        }}
        styles={TutorialCustomStyling}
        locale={joyrideLocale}
        callback={handleJoyrideCallback}
      />
      <TutorialConfirmationDialog
        open={isDialogOpen}
        onOpenChange={setIsDialogOpen}
        onConfirm={handleDialogConfirm}
        onCancel={handleDialogCancel}
      />
    </>
  );
};

export default JoyrideTutorial;
