import { useEffect } from 'react';

const TUTORIAL_ELEMENTS = [
  {
    selector: '[data-menu-item="Settings"]',
    stateKey: 'settingsClicked',
    logMessage: '[useTutorialClickStates] Settings clicked',
    autoAdvance: true,
  },
  {
    selector: '[data-settings-menu-item="Topics"]',
    stateKey: 'topicsClicked',
    logMessage: '[useTutorialClickStates] Topics clicked',
    autoAdvance: true,
  },
  {
    selector: '[data-settings-menu-item="Social"]',
    stateKey: 'socialClicked',
    logMessage: '[useTutorialClickStates] Social clicked',
    autoAdvance: true,
  },
];

// Used for Joyride tutorial
// This hook is used to track the click states of the tutorial elements
// It is used to determine if the user has clicked on the tutorial elements
const useTutorialClickHandlers = (onElementClick) => {
  useEffect(() => {
    const handleClick = (stateKey, logMessage, autoAdvance) => () => {
      if (onElementClick) {
        setTimeout(() => {
          onElementClick();
        }, 500);
      }
    };

    const setupEventListeners = () => {
      return TUTORIAL_ELEMENTS.map(({ selector, stateKey, logMessage, autoAdvance }) => {
        const element = document.querySelector(selector);
        if (element) {
          const clickHandler = handleClick(stateKey, logMessage, autoAdvance);
          element.addEventListener('click', clickHandler);
          return { element, clickHandler };
        }
        return null;
      }).filter(Boolean);
    };

    const listeners = setupEventListeners();

    const observer = new MutationObserver(() => {
      listeners.forEach((listener) => {
        if (listener) {
          listener.element.removeEventListener('click', listener.clickHandler);
        }
      });
      setupEventListeners();
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
      listeners.forEach((listener) => {
        if (listener) {
          listener.element.removeEventListener('click', listener.clickHandler);
        }
      });
    };
  }, [onElementClick]);
};

export default useTutorialClickHandlers;
