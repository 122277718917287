import React, { useCallback } from 'react';
import { Stack, useTheme, CircularProgress, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectSelectedAssistant } from '@/redux/slices/Assistants/assistantsSlice';

import SequenceModal from './components/addSequenceModal/sequenceModal.js';
import SequenceReview from './components/sequenceReview.js';
import PauseModal from './components/sequenceSettingsModal/pause.js';
import DeleteModal from './components/sequenceSettingsModal/delete.js';
import RenameModal from './components/sequenceSettingsModal/rename.js';
import EditModal from './components/sequenceSettingsModal/edit.js';
import { getDefaultStrategies, getDefaultTemplates, getAvailableList } from '../../helper/apiHelper';
import { GetCookie } from '../../helper/cookieManager';

import Http from '../../http/httpClient';

import SocialMediaAssistantView from '@/v0/Assistants/SocialMediaAssistant/SocialMediaAssistantView';

const ClientAcquisition = () => {
  const curUser = React.useMemo(() => GetCookie('user'), []);
  const theme = useTheme();
  const [isEmpty, setIsEmpty] = React.useState(true);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalIndex, setModalIndex] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [createdSequenceId, setCreatedSequenceId] = React.useState(null);
  const [activeItem, setActiveItem] = React.useState(null);
  const [progress, setProgress] = React.useState({});
  const [sequenceContacts, setSequenceContacts] = React.useState({});
  const [sequenceContacts2, setSequenceContacts2] = React.useState({});
  const [stepTypes, setStepTypes] = React.useState([]);
  const [sequenceRefreshKey, setSequenceRefreshKey] = React.useState(0);
  const [defaultTemplates, setDefaultTemplates] = React.useState([]);
  const [strategies, setStrategies] = React.useState([]);
  const [availableList, setAvailableList] = React.useState([]);
  const [targetAudience, setTargetAudience] = React.useState({});
  const [stepText, setStepText] = React.useState([]);
  const [initialLoading, setInitialLoading] = React.useState(true);
  const [strategyLoading, setStrategyLoading] = React.useState(true);
  const [initialListLoading, setInitialListLoading] = React.useState(true);
  const [userBio, setUserBio] = React.useState(null);

  const [renameOpen, setRenameOpen] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);
  const [pauseOpen, setPauseOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);

  const [sequenceList, setSequenceList] = React.useState([]);

  const selectedAssistant = useSelector(selectSelectedAssistant);

  const closeModal = () => setModalOpen(false);
  const closeRename = () => setRenameOpen(false);
  const closeEdit = () => setEditOpen(false);
  const closePause = () => setPauseOpen(false);
  const closeDelete = () => setDeleteOpen(false);

  React.useEffect(() => {
    if (selectedAssistant && sequenceList.length > 0) {
      const foundItem = sequenceList.find((item) => item._id === selectedAssistant._id);
      if (foundItem) {
        setActiveItem(foundItem);
      }
    }
  }, [selectedAssistant, sequenceList]);

  const expireCache = (sequenceId) => {
    setTimeout(() => {
      setSequenceContacts((prevContacts) => {
        return {
          ...prevContacts,
          [sequenceId]: { ...prevContacts[sequenceId], replaceable: true },
        };
      });
      setSequenceContacts2((prevContacts) => {
        return {
          ...prevContacts,
          [sequenceId]: { ...prevContacts[sequenceId], replaceable: true },
        };
      });
    }, 15000);
  };

  React.useEffect(() => {
    if (!activeItem) {
      return;
    }
    if (
      sequenceContacts[activeItem._id] &&
      sequenceContacts2[activeItem._id] &&
      !sequenceContacts[activeItem._id].replaceable &&
      !sequenceContacts2[activeItem._id].replaceable
    ) {
      return;
    }

    setProgress((prev) => ({ ...prev, getContacts: true }));
    Http.getData('getSequenceContacts', { sequenceId: activeItem._id })
      .then(function (response) {
        if (response && response.status) {
          setSequenceContacts((prevContacts) => ({
            ...prevContacts,
            [activeItem._id]: { contacts: response.contacts, replaceable: false },
          }));
          setSequenceContacts2((prevContacts) => ({
            ...prevContacts,
            [activeItem._id]: { contacts: response.contacts2, replaceable: false },
          }));
          expireCache(activeItem._id);
          setStepTypes(response.steps);
          setProgress((prev) => ({ ...prev, getContacts: false }));
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  }, [activeItem]);

  const fetchBio = async () => {
    const response = await Http.getData('setup/getBio');
    setUserBio({ name: curUser.name, company: response?.bio?.companyName });
  };

  const fetchSequenceData = useCallback((sequenceId) => {
    setProgress((prev) => ({ ...prev, getSequence: true }));
    Http.getData('getSequences', { sequenceId })
      .then(function (response) {
        if (response && response.status) {
          setSequenceList(response.sequences);
          setIsEmpty(response.sequences.length === 0);
          setProgress((prev) => ({ ...prev, getSequence: false }));
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  }, []);

  const fetchDefaultTemplates = () => {
    getDefaultTemplates()
      .then(function (response) {
        if (response && response.status) {
          setDefaultTemplates([...response.templates]);
          if (response.stepText) setStepText([...response.stepText]);
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .finally(() => {
        setInitialLoading(false);
      });
  };

  const fetchStrategies = async () => {
    getDefaultStrategies()
      .then((response) => {
        if (response && response.status) {
          setStrategies([...response.strategies]);
          setTargetAudience(response.targetAudience);
        }
      })
      .catch((error) => {
        console.error('Error : ', error);
      })
      .finally(() => {
        setStrategyLoading(false);
      });
  };

  const fetchInitialLists = async () => {
    getAvailableList()
      .then((response) => {
        if (response && response.status) {
          setAvailableList([...response.lists]);
        }
      })
      .catch((error) => {
        console.error('Error : ', error);
      })
      .finally(() => {
        setInitialListLoading(false);
      });
  };

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const seqId = urlParams.get('seqId');

    fetchBio();
    fetchSequenceData(seqId);
    fetchDefaultTemplates();
    fetchStrategies();
    fetchInitialLists();
  }, []);

  React.useEffect(() => {
    if (createdSequenceId) {
      fetchSequenceData(createdSequenceId);
    }
  }, [createdSequenceId]);

  const handleSequenceModification = () => {
    setActiveItem({ ...activeItem });
  };

  React.useEffect(() => {
    if (activeItem && sequenceList.length) {
      const nextActiveItem = sequenceList.find((item) => item._id?.toString() === activeItem._id?.toString());
      setActiveItem(nextActiveItem ? { ...nextActiveItem } : null);
    } else if (!sequenceList.length) {
      setActiveItem(null);
    }
  }, [sequenceList]);

  const handleSequenceDelete = (sequenceId) => {
    try {
      Http.postData('deleteSequenceData', { seqId: sequenceId }).then(function (response) {
        if (response.status) {
          const filteredUserSequence = sequenceList.filter((seq) => seq._id !== sequenceId);
          const selectedItem = filteredUserSequence[0];
          setActiveItem(selectedItem);
          setSequenceList(filteredUserSequence);
          setIsEmpty(filteredUserSequence.length === 0);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const processedMenuItems = sequenceList.map((item) => ({
    label: item.name,
    onClick: () => {
      setActiveItem(item);
    },
    badgeText: item.status === 'Paused' ? 'Paused' : null,
    badgeTheme: 'orange',
    active: activeItem && activeItem._id === item._id,
  }));

  React.useEffect(() => {
    const handleRefresh = () => {
      fetchSequenceData();
    };

    window.addEventListener('refreshClientAcquisition', handleRefresh);
    return () => {
      window.removeEventListener('refreshClientAcquisition', handleRefresh);
    };
  }, [fetchSequenceData]);

  return (
    <Box>
      {!initialLoading && !strategyLoading && !initialListLoading && (
        <SequenceModal
          open={modalOpen}
          onClose={closeModal}
          setCreatedSequenceId={setCreatedSequenceId}
          templates={defaultTemplates}
          templateSteps={stepText}
          strategies={strategies}
          targetAudience={targetAudience}
          availableList={availableList}
        />
      )}
      {activeItem && (
        <>
          {pauseOpen && (
            <PauseModal open={pauseOpen} onClose={closePause} activeItem={activeItem} onRefresh={fetchSequenceData} />
          )}
          {deleteOpen && (
            <DeleteModal
              open={deleteOpen}
              onClose={closeDelete}
              handleSequenceDelete={() => handleSequenceDelete(activeItem._id)}
            />
          )}
          {renameOpen && (
            <RenameModal
              open={renameOpen}
              onClose={closeRename}
              activeItem={activeItem}
              setCreatedSequenceId={setCreatedSequenceId}
              setSequenceList={setSequenceList}
              sequenceList={sequenceList}
            />
          )}
          {editOpen && (
            <EditModal
              open={editOpen}
              onClose={closeEdit}
              activeItem={activeItem}
              template={activeItem.template}
              onRefresh={fetchSequenceData}
            />
          )}
        </>
      )}
      <Stack direction="row" sx={{ width: '100%', minHeight: '100vh' }}>
        {/* <Divider orientation="vertical" flexItem sx={{ height: '100vh' }} /> */}

        <Box sx={{ width: 'auto', flex: 3, display: 'flex', flexDirection: 'column' }}>
          {selectedAssistant?.type === 'Social Media' ? (
            <SocialMediaAssistantView assistantId={selectedAssistant._id} />
          ) : progress.getContacts ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '50vh' }}>
              <CircularProgress color="inherit" />
            </div>
          ) : (
            progress.getContacts === false &&
            !isEmpty &&
            !initialLoading &&
            !strategyLoading &&
            !initialListLoading && (
              <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
                <SequenceReview
                  userBio={userBio}
                  setCreatedSequenceId={setCreatedSequenceId}
                  setSequenceList={setSequenceList}
                  sequenceList={sequenceList}
                  refreshKey={sequenceRefreshKey}
                  contacts={activeItem ? sequenceContacts[activeItem._id]?.contacts || [] : []}
                  contacts2={activeItem ? sequenceContacts2[activeItem._id]?.contacts || [] : []}
                  stepTypes={stepTypes}
                  listItem={activeItem}
                  handleSequenceModification={handleSequenceModification}
                  fetchSequenceData={fetchSequenceData}
                  handleSequenceDelete={handleSequenceDelete}
                  targetAudience={targetAudience}
                  availableList={availableList}
                  processedMenuItems={processedMenuItems}
                />
              </Box>
            )
          )}
        </Box>
      </Stack>
    </Box>
  );
};

export default ClientAcquisition;
