import React from 'react';
import { TooltipContent } from '@/components/ui/tooltip';
import { Assistant } from '@/constants/types/assistant';
import { ASSISTANT } from '@postilize/shared';

/**
 * Props for the AssistantCountHover component.
 * @param {Assistant} assistant - The assistant object.
 */
type AssistantCountHoverProps = {
  assistant: Assistant;
};

export const AssistantCountHover: React.FC<AssistantCountHoverProps> = ({ assistant }) => {
  const isActive = assistant.status?.toLowerCase() === ASSISTANT.STATUS.ACTIVE.toLowerCase();

  return (
    <TooltipContent side="right" align="start" className="w-64 p-0">
      <div className="bg-gray-50/80 px-4 py-3 border-b">
        <h4 className="font-semibold text-sm">{assistant.name}</h4>
      </div>
      <div className="p-4">
        <p className="text-sm">
          <span className="font-semibold">{assistant.type}</span> assistant
        </p>
        {assistant.createdAt && (
          <p className="text-sm mt-2">
            <span className="text-gray-500">Created on</span> {new Date(assistant.createdAt).toLocaleDateString()}
          </p>
        )}
        {assistant.startTime && (
          <p className="text-sm">
            <span className="text-gray-500">Started on</span> {new Date(assistant.startTime).toLocaleDateString()}
          </p>
        )}
        <p className="text-sm mt-2">
          <span className="text-gray-500">Status:</span>{' '}
          <span className={isActive ? 'text-emerald-600' : 'text-orange-500'}>{assistant.status}</span>
        </p>
        <p className="text-sm">
          <span className="text-gray-500">Contacts:</span> <span className="font-semibold">{assistant.count}</span>
        </p>
      </div>
    </TooltipContent>
  );
};
