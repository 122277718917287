import * as React from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import ListItemButton from '@mui/material/ListItemButton';
import { styled, useTheme } from '@mui/material/styles';

import PTypography from './PTypography';
import PBadge from './PBadge';

const getVariantStyles = (theme, pVariant) => {
  let colorScheme = {};

  switch (pVariant) {
    case 'primary':
      colorScheme = {
        text: theme.palette.primaryCL.Black200,
        background: theme.palette.primaryCL.Black50,
        hoverText: theme.palette.primaryCL.Blue100,
        hoverBackground: theme.palette.primaryCL.White110,
        activeText: theme.palette.primaryCL.Black200,
        activeBackground: theme.palette.primaryCL.White100,
        borderColor: theme.palette.primaryCL.Black70,
      };
      break;

    // Add more cases for 'outlined', 'plain', etc.
  }

  return colorScheme;
};

const StyledPListItemButton = styled('div', {
  shouldForwardProp: (prop) => prop !== 'pVariant',
})(({ theme, pVariant, active }) => {
  const { background, text, hoverText, hoverBackground, activeText, activeBackground, borderColor } = getVariantStyles(
    theme,
    pVariant,
  );

  return {
    ...theme.typography.regular.body2,
    'color': active ? activeText : text,
    'backgroundColor': active ? activeBackground : background,
    'borderBottom': '1px solid',
    'borderBottomColor': borderColor,
    'p': 0,
    'display': 'flex',
    'justifyContent': 'flex-start',
    '&:hover': {
      backgroundColor: hoverBackground,
      color: hoverText,
    },
  };
});

function PSectionNavMenuItem({ pVariant, onClick, label, badgeText, badgeTheme, active }) {
  const theme = useTheme();

  return (
    <StyledPListItemButton onClick={onClick} pVariant={pVariant} active={active}>
      <div
        data-settings-menu-item={label}
        style={{ width: '100%', display: 'flex', alignItems: 'center', padding: '24px', gap: '8px' }}
      >
        {badgeText && <PBadge pVariant={badgeTheme}>{badgeText}</PBadge>}
        <PTypography weight="regular" size="body2">
          {label}
        </PTypography>
      </div>
    </StyledPListItemButton>
  );
}

PSectionNavMenuItem.propTypes = {
  /** Postilize design variant, only primary for now, leaving in so we can easily update in future */
  pVariant: PropTypes.oneOf(['primary']),

  /** Label */
  label: PropTypes.string.isRequired,

  /** If the item is currently selected */
  active: PropTypes.bool,

  /** Text for the badge */
  badgeText: PropTypes.string,

  /** Badge theme */
  badgeTheme: PropTypes.oneOf(['blue', 'teal', 'green', 'pink', 'purple', 'orange', 'black', 'grey', 'red']),

  /** Click handler */
  onClick: PropTypes.func,
};

PSectionNavMenuItem.defaultProps = {
  pVariant: 'primary',
  label: 'Label',
  badgeTheme: 'orange',
  active: false,
};

export default PSectionNavMenuItem;
