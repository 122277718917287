import type { Variants } from 'framer-motion';

export const containerVariants: Variants = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -50, transition: { duration: 0.3 } },
};

export const badgeVariants: Variants = {
  hidden: { scale: 0 },
  visible: { scale: 1 },
};

export const buttonVariants: Variants = {
  hover: { scale: 1.05 },
  tap: { scale: 0.95 },
};

export const successVariants: Variants = {
  hidden: { opacity: 0, scale: 0.9 },
  visible: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 0.9 },
};

export const checkmarkVariants: Variants = {
  hidden: { scale: 0 },
  visible: { scale: 1 },
};
