import React, { memo, useCallback, useEffect, useState, useMemo } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import { useCreateSocialMediaAssistantMutation } from '@/helper/APIs/SocialMediaAssistantsApi';
import { AssistantType } from '@/constants/types/assistant';

import PModal from '@Library/PModal';
import PButton from '@Library/PButton';
import PBackButton from '@Library/PBackButton';

import TopicSelection from '@CommonScreens/TopicSelection/TopicSelection';
import BrandingAssistantCreated from './brandingAssistantCreated';

const DEFAULT_DRAFT_ASSISTANT = {
  name: '',
  topics: [],
  generationCadence: 24,
  type: AssistantType.SOCIAL_MEDIA,
};

/**
 * BrandingFlow - a component to drive the branding assistant creation.
 * When currentContent is "audienceAndTopicSelection", it renders
 * a non-floating modal wrapping TopicSelection.
 * When the assistant is created (currentContent is "brandingAssistantCreated"),
 * the success screen is rendered on its own (without a modal).
 */
function BrandingFlow(props) {
  const { onClose } = props;
  const [createAssistant] = useCreateSocialMediaAssistantMutation();

  const [isLoading, setIsLoading] = useState(false);
  const [currentContent, setCurrentContent] = useState('audienceAndTopicSelection');
  const [pageHistory, setPageHistory] = useState([]);
  const [createdAssistantId, setCreatedAssistantId] = useState(null);
  const [draftAssistant, setDraftAssistant] = useState({ ...DEFAULT_DRAFT_ASSISTANT });

  const handleSetTopics = useCallback((newTopics) => {
    setDraftAssistant((prev) => ({ ...prev, topics: newTopics }));
  }, []);

  const handleSetName = useCallback((newName) => {
    setDraftAssistant((prev) => ({ ...prev, name: newName }));
  }, []);

  // Removed automatic topic fetching - let TopicSelection handle empty state
  useEffect(() => {
    // Initialize with empty topics array
    handleSetTopics([]);
  }, [handleSetTopics]);

  const handleFinish = useCallback(async () => {
    if (isLoading) return; // Prevent multiple clicks

    setIsLoading(true);
    try {
      const payload = {
        data: {
          ...draftAssistant,
        },
      };

      // Await the createAssistant mutation and destructure the returned data
      const { data: assistant } = await createAssistant(payload);

      // Check the assistant object for its _id
      if (assistant?._id) {
        setCreatedAssistantId(assistant._id);
        setCurrentContent('brandingAssistantCreated');
      }
    } catch (error) {
      // Let the middleware handle the error toast
    } finally {
      setIsLoading(false);
    }
  }, [draftAssistant, createAssistant, isLoading]);

  useEffect(() => {
    return () => {
      setDraftAssistant({ ...DEFAULT_DRAFT_ASSISTANT });
      setPageHistory([]);
    };
  }, []);

  const handleBack = useCallback(() => {
    if (pageHistory.length === 0) {
      // Should never happen, but just in case
      onClose({ fullClose: false });
      return;
    }

    // TODO: Add guards to prevent going back with unsaved work
    const previousContent = pageHistory[pageHistory.length - 1];
    const updatedPageHistory = pageHistory.slice(0, pageHistory.length - 1);

    setPageHistory(updatedPageHistory);
    setCurrentContent(previousContent);
  }, [onClose, pageHistory]);

  const renderContent = () => {
    switch (currentContent) {
      case 'audienceAndTopicSelection':
        return (
          <TopicSelection
            selectedTopics={draftAssistant.topics}
            onTopicsChange={handleSetTopics}
            assistantName={draftAssistant.name}
            onNameChange={handleSetName}
          />
        );
      case 'brandingAssistantCreated':
        return <BrandingAssistantCreated onClose={onClose} assistantId={createdAssistantId} />;
      default:
        return null;
    }
  };

  const actionButtons = useMemo(() => {
    if (currentContent === 'brandingAssistantCreated') return null;

    const isContinueButtonDisabled =
      isLoading ||
      (currentContent === 'audienceAndTopicSelection' &&
        (draftAssistant.topics.length === 0 || draftAssistant.topics.length > 10));

    return (
      <PButton onClick={handleFinish} pVariant="primary" disabled={isContinueButtonDisabled}>
        {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Finish'}
      </PButton>
    );
  }, [isLoading, currentContent, draftAssistant.topics.length, handleFinish]);

  // If the assistant creation succeeded, render the success screen on its own.
  if (currentContent === 'brandingAssistantCreated') {
    return <BrandingAssistantCreated onClose={onClose} assistantId={createdAssistantId} />;
  }

  // Otherwise, render the topic selection inside a non-floating modal.
  return (
    <PModal
      open={true}
      onClose={handleBack}
      loadingNext={isLoading}
      backButton={<PBackButton onClick={handleBack} iconType="back" />}
      actionButtons={actionButtons ? [actionButtons] : []}
      ultraWideContent
    >
      {renderContent()}
    </PModal>
  );
}

export default memo(BrandingFlow);
