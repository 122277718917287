import { useToast } from '@/hooks/use-toast';
import { Toast, ToastClose, ToastDescription, ToastProvider, ToastTitle, ToastViewport } from '@/components/ui/toast';

/**
 * Toaster component that renders the active toast and includes global styles for countdown animation.
 *
 * @returns {JSX.Element} The rendered toaster component.
 */
export function Toaster() {
  const { toasts } = useToast();
  // Only display the first toast in the queue.
  const currentToast = toasts[0];

  return (
    <>
      <ToastProvider>
        {currentToast && (
          <Toast key={currentToast.id} {...currentToast}>
            <div className="grid gap-1">
              {currentToast.title && <ToastTitle>{currentToast.title}</ToastTitle>}
              {currentToast.description && <ToastDescription>{currentToast.description}</ToastDescription>}
            </div>
            {currentToast.action}
            <ToastClose duration={currentToast.duration} />
          </Toast>
        )}
        <ToastViewport />
      </ToastProvider>
      <style>{`
        @keyframes countdown {
          from {
            stroke-dashoffset: 0;
          }
          to {
            stroke-dashoffset: 44;
          }
        }
        .countdown-circle circle {
          animation: countdown var(--duration) linear forwards;
        }
      `}</style>
    </>
  );
}
