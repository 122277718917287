import { Sparkles, Plus } from 'lucide-react';
import { ASSISTANT } from '@postilize/shared/constants/assistants';

export const MODES = [
  { value: 'ai', label: 'AI Suggestions', Icon: Sparkles },
  { value: 'manual', label: 'Manual Entry', Icon: Plus },
] as const;

export const TOPIC_HEIGHT = 48;
export const TOPIC_GAP = 12;
export const TOPIC_ITEM_HEIGHT = TOPIC_HEIGHT + TOPIC_GAP;
export const TOPICS_CONTAINER_HEIGHT = ASSISTANT.TOPIC_LIMIT * TOPIC_ITEM_HEIGHT;

export const DEFAULTS = {
  MODES,
} as const;

// Add new constants for accessibility
export const ARIA_LIVE_REGION = 'polite';
