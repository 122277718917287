import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Loader2, Sparkles, Plus } from 'lucide-react';
import { motion } from 'framer-motion';
import { buttonVariants } from './animations';
import { useTopicSelectionContext } from './TopicSelectionContext';

/**
 * Props for the TopicInput component
 * @interface TopicInputProps
 * @property {'ai' | 'manual'} inputMode - The current input mode determining behavior and UI
 * @property {number} maxSelectedTopics - Maximum number of topics that can be selected
 */
type TopicInputProps = {
  inputMode: 'ai' | 'manual';
  maxSelectedTopics: number;
};

/**
 * TopicInput Component
 *
 * A dual-mode input component that handles both AI-assisted and manual topic entry.
 * Provides real-time feedback and enforces topic limits with appropriate UI states.
 *
 * Features:
 * - Switches between AI generation and manual entry modes
 * - Animated button states
 * - Loading indicators
 * - Keyboard support (Enter key submission)
 * - Accessibility labels
 * - Topic limit enforcement
 *
 * @component
 * @param {TopicInputProps} props - Component props
 * @returns {JSX.Element} Rendered TopicInput component
 *
 * @example
 * ```tsx
 * <TopicInput
 *   inputMode="ai"
 *   maxSelectedTopics={5}
 * />
 * ```
 */
export function TopicInput({ inputMode, maxSelectedTopics }: TopicInputProps) {
  const { prompt, isLoading, handlePromptChange, handleTopicSubmission, selectedTopics } = useTopicSelectionContext();

  const topicCount = selectedTopics.length;
  const isDisabled = topicCount >= maxSelectedTopics;

  const onSubmit = (e: React.FormEvent) => {
    handleTopicSubmission(e, inputMode);
  };

  return (
    <div className="flex gap-3 items-center">
      <div className="relative flex-grow">
        <Input
          value={prompt}
          onChange={(e) => handlePromptChange(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && prompt.trim() && selectedTopics.length < maxSelectedTopics) {
              onSubmit(e);
            }
          }}
          disabled={isDisabled || isLoading}
          placeholder={
            isDisabled
              ? "You've reached the topic limit"
              : inputMode === 'ai'
                ? 'Describe your target audience'
                : 'Type your topic here'
          }
          className="h-10"
          aria-label={
            isDisabled ? 'Topic limit reached' : inputMode === 'ai' ? 'Describe your audience' : 'Enter custom topic'
          }
          aria-invalid={isDisabled}
        />
      </div>
      <motion.div
        variants={buttonVariants}
        whileHover={!isDisabled && !isLoading ? 'hover' : undefined}
        whileTap={!isDisabled && !isLoading ? 'tap' : undefined}
      >
        <Button
          type="submit"
          className="h-10 gap-2 w-[120px] flex-shrink-0"
          disabled={isDisabled || isLoading}
          onClick={onSubmit}
          aria-label={isLoading ? 'Generating topics...' : inputMode === 'ai' ? 'Generate Topics' : 'Add Topic'}
        >
          {isLoading ? (
            <>
              <Loader2 className="w-4 h-4 animate-spin" aria-hidden="true" />
              <span>Thinking...</span>
            </>
          ) : inputMode === 'ai' ? (
            <>
              <Sparkles className="w-4 h-4" aria-hidden="true" />
              <span>Generate</span>
            </>
          ) : (
            <>
              <Plus className="w-4 h-4" aria-hidden="true" />
              <span>Add Topic</span>
            </>
          )}
        </Button>
      </motion.div>
    </div>
  );
}
