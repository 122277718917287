import { useState, useEffect } from 'react';
import { GetCookie } from '../../helper/cookieManager';
import Http from '../../http/httpClient';

const useTutorialMode = () => {
  const [isTutorialMode, setIsTutorialMode] = useState(false);
  const [tutorialTasks, setTutorialTasks] = useState([]);

  useEffect(() => {
    const fetchTutorialMode = async () => {
      try {
        // Fetch tutorial tasks
        const tasks = await Http.getData(`tutorial/getTasks`);
        setIsTutorialMode(tasks.length > 0);
        setTutorialTasks(tasks);
      } catch (error) {
        console.error('Error fetching tutorial mode:', error);
        setIsTutorialMode(false);
      }
    };

    fetchTutorialMode();
  }, []);

  const disableTutorialMode = () => {
    setIsTutorialMode(false);
  };

  // Update the tutorial tasks status when the user finishes a task
  const updateTutorialTasks = async (taskName) => {
    try {
      const response = await Http.putData(`tutorial/updateTask`, {
        data: {
          taskNames: taskName,
          completed: true,
        },
      });
    } catch (error) {
      console.error('Error updating tutorial tasks status:', error);
    }
  };

  const skipTutorialTasks = async () => {
    try {
      const response = await Http.putData(`tutorial/skipTasks`, { data: { thresholdDays: 1 } });
    } catch (error) {
      console.error('Error skipping tutorial tasks:', error);
    }
  };

  return { isTutorialMode, tutorialTasks, updateTutorialTasks, disableTutorialMode, skipTutorialTasks };
};

export default useTutorialMode;
