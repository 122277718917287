import React, { useState, useEffect, useCallback } from 'react';
import { Users, Briefcase, ListChecks, BarChart, Bot, CheckSquare, Share2 } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { setPage } from '@/redux/authSlice';
import { GetCookie } from '@/helper/cookieManager';
import { UserConst } from '@/constants/userConst';
import { useIsFeatureFlagEnabled } from '@/hooks/useFeatureFlags';
import { FeatureFlags } from '@/constants/constant';
import { AssistantType } from '@/constants/types/assistant';

import PSidebar from '@Library/PSidebar';

import { useAssistants } from '@/hooks/useAssistants';
import {
  setSelectedAssistantId,
  selectGroupByType,
  selectBusinessDevelopmentAssistants,
  selectRelationshipGrowthAssistants,
} from '@/redux/slices/Assistants/assistantsSlice';
import AssistantsSection from '@/v0/Assistants/AssistantsSection';

import { useStrategies } from '@/hooks/useStrategies';

const Sidebar = ({ full }) => {
  const dispatch = useDispatch();
  const currentPage = useSelector((state) => state.auth.page);
  const featureFlags = useSelector((state) => state.auth.featureFlags);
  const groupByType = useSelector(selectGroupByType);
  const BDAssistants = useSelector(selectBusinessDevelopmentAssistants);
  const RGAssistants = useSelector(selectRelationshipGrowthAssistants);
  const socialMediaAssistants = useSelector((state) => state.assistants.socialMediaAssistants);
  const [menuItems, setMenuItems] = useState([]);
  const [user, setUser] = useState({ name: null, type: null });

  const curUser = React.useMemo(() => GetCookie('user'), []);

  const isErmDashboardEnabled = useIsFeatureFlagEnabled(FeatureFlags.ERM_DASHBOARD);
  const isOutreachListsEnabled = useIsFeatureFlagEnabled(FeatureFlags.OUTREACH_LISTS);
  const isSequencesEnabled = useIsFeatureFlagEnabled(FeatureFlags.SEQUENCES);
  const { strategies, loading, error } = useStrategies();
  const strategyList = Array.isArray(strategies) && Array.isArray(strategies[0]) ? strategies[0] : [];

  const isGrowEnabled = strategyList.some((s) => s.id === 'grow' && s.enabled);

  const { pauseOrResumeAssistant, handleAssistantRename, handleAssistantDelete } = useAssistants();

  const updateMenu = useCallback(
    (baseMenu) => {
      const updatedMenu = baseMenu.map((item) => ({
        ...item,
        active: item.page === currentPage,
      }));
      setMenuItems((prev) => (JSON.stringify(prev) === JSON.stringify(updatedMenu) ? prev : updatedMenu));
    },
    [currentPage],
  );

  useEffect(() => {
    if (featureFlags) {
      const sbClientRetention = { title: 'Approval Queue', icon: CheckSquare, page: 'retention', active: false };
      const sbClientAcquisition = { title: 'Assistants', icon: Bot, page: 'acquisition', active: false };
      const sbClients = { title: 'Relationship Intelligence', icon: Briefcase, page: 'clients', active: false };

      const sbStats = { title: 'Stats', icon: BarChart, page: 'stats', active: false };
      const sbOutreachLists = { title: 'Lists', icon: ListChecks, page: 'outreachLists', active: false };

      let sbItems = [sbClientRetention];

      if (isSequencesEnabled) {
        sbItems.push(sbClientAcquisition);
      }
      if (isErmDashboardEnabled) {
        sbItems.push(sbClients);
      }
      if (isOutreachListsEnabled) {
        sbItems.push(sbOutreachLists);
      }

      if (isGrowEnabled) {
        sbItems.push(sbStats);
      }

      updateMenu(sbItems);
    }
  }, [
    featureFlags,
    curUser,
    isErmDashboardEnabled,
    isOutreachListsEnabled,
    isSequencesEnabled,
    isGrowEnabled,
    updateMenu,
  ]);

  useEffect(() => {
    setUser((prevUser) => ({
      ...prevUser,
      name: curUser.name,
      type: curUser.userType === UserConst.UserTypes.TRIAL_USER ? 'Free Plan' : 'Premium',
    }));
  }, [curUser]);

  useEffect(() => {
    updateMenu(menuItems);
  }, [currentPage, menuItems, updateMenu]);

  const handlePauseToggle = useCallback(
    async (id, status) => {
      await pauseOrResumeAssistant(id, status);
    },
    [pauseOrResumeAssistant],
  );

  const handleDelete = useCallback(
    async (id) => {
      await handleAssistantDelete(id);
    },
    [handleAssistantDelete],
  );

  const handleAssistantClick = useCallback(
    (assistantId) => {
      dispatch(setSelectedAssistantId(assistantId));
      dispatch(setPage('acquisition'));
    },
    [dispatch],
  );

  const [expandedTypes, setExpandedTypes] = useState([]);

  useEffect(() => {
    if (groupByType) {
      setExpandedTypes(['type-Business Development']);
    } else {
      setExpandedTypes([]);
    }
  }, [groupByType]);

  const handleRefreshClientAcquisition = useCallback(() => {
    window.dispatchEvent(new Event('refreshClientAcquisition'));
  }, []);
  const getAssistantsSection = (strategy) => {
    if (!strategy.enabled) return null;

    switch (strategy.id) {
      case 'acquire':
        return (
          <AssistantsSection
            key={strategy._id}
            assistants={BDAssistants}
            isSequencesEnabled={isSequencesEnabled}
            typeLabel="Lead Assistant"
            typeIcon={Briefcase}
            desiredType={AssistantType.BUSINESS_DEVELOPMENT}
            expandedTypes={expandedTypes}
            setExpandedTypes={setExpandedTypes}
            onPauseToggle={handlePauseToggle}
            onDelete={handleAssistantDelete}
            onRename={handleAssistantRename}
            onAssistantClick={handleAssistantClick}
            currentPage={currentPage}
            onRefreshClientAcquisition={handleRefreshClientAcquisition}
            strategy={strategy}
          />
        );
      case 'retain':
        return (
          <AssistantsSection
            key={strategy._id}
            assistants={RGAssistants}
            isSequencesEnabled={isSequencesEnabled}
            typeLabel="Relationship Assistant"
            typeIcon={Users}
            desiredType={AssistantType.RELATIONSHIP_GROWTH}
            expandedTypes={expandedTypes}
            setExpandedTypes={setExpandedTypes}
            onPauseToggle={handlePauseToggle}
            onDelete={handleAssistantDelete}
            onRename={handleAssistantRename}
            onAssistantClick={handleAssistantClick}
            currentPage={currentPage}
            onRefreshClientAcquisition={handleRefreshClientAcquisition}
            strategy={strategy}
          />
        );
      case 'grow':
        return (
          <AssistantsSection
            key={strategy._id}
            assistants={socialMediaAssistants}
            isSequencesEnabled={isSequencesEnabled}
            typeLabel="Social Assistant"
            typeIcon={Share2}
            desiredType={AssistantType.SOCIAL_MEDIA}
            expandedTypes={expandedTypes}
            setExpandedTypes={setExpandedTypes}
            onPauseToggle={handlePauseToggle}
            onDelete={handleAssistantDelete}
            onRename={handleAssistantRename}
            onAssistantClick={handleAssistantClick}
            currentPage={currentPage}
            onRefreshClientAcquisition={handleRefreshClientAcquisition}
            strategy={strategy}
          />
        );
      default:
        return null;
    }
  };

  return (
    <PSidebar
      pVariant="primary"
      full={true}
      user={user}
      menuItems={menuItems}
      menuOnClick={(title, hasSubmenu, page) => dispatch(setPage(page))}
      assistantsSection={
        isSequencesEnabled && (
          <>
            {loading ? (
              <div>Loading...</div>
            ) : error ? (
              <div>Error loading assistants</div>
            ) : (
              strategies.map((strategy) => {
                return getAssistantsSection(strategy);
              })
            )}
          </>
        )
      }
    />
  );
};

export default Sidebar;
