import { PModal } from '@Library';
import { X, Clock } from 'lucide-react';
import { styled } from '@mui/material/styles';
import { Select, MenuItem, CircularProgress } from '@mui/material';
import { GetCookie } from '@/helper/cookieManager';
import { Button } from '@/components/ui/button';
import { DialogFooter } from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { Label } from '@/components/ui/label';
import { RelationshipPayload } from '@/constants/types/relationshipTypes';
import { sendEngagementEmail, generateEngagementEmail, generateCacheForEngagementEmails } from '@/helper/apiHelper';
import { useAvailableDelegations } from '@/hooks/CompanyHooks';
import { useEffect, useState, useMemo } from 'react';

const MENU_MAX_HEIGHT = 300; // Add this constant at the top with other constants

// Styled MUI Select to match shadcn/ui styling
const StyledSelect = styled(Select)({
  'height': '40px',
  'backgroundColor': 'hsl(var(--background))',
  'border': '1px solid hsl(var(--input))',
  'borderRadius': 'var(--radius)',
  'color': 'hsl(var(--foreground))',
  'fontSize': '14px',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&:hover': {
    borderColor: 'hsl(var(--input))',
  },
  '&.Mui-focused': {
    borderColor: 'hsl(var(--ring))',
    boxShadow: '0 0 0 1px hsl(var(--ring))',
  },
  '& .MuiSelect-select': {
    padding: '8px 12px',
  },
});

// Styled MenuItem to match shadcn/ui styling
const StyledMenuItem = styled(MenuItem)({
  'fontSize': '14px',
  'color': 'hsl(var(--foreground))',
  '&:hover': {
    backgroundColor: 'hsl(var(--accent))',
  },
  '&.Mui-selected': {
    'backgroundColor': 'hsl(var(--accent))',
    '&:hover': {
      backgroundColor: 'hsl(var(--accent))',
    },
  },
});

type EngagementType = 'meeting' | 'checkin' | 'news';

const engagementTypesStringMap: Record<EngagementType, string> = {
  meeting: 'Meeting',
  checkin: 'Check-in',
  news: 'Personalized News',
};

interface EngageModalProps {
  isOpen: boolean;
  onClose: () => void;
  targetContact: RelationshipPayload | null;
}

export function EngageModal({ isOpen, onClose, targetContact }: EngageModalProps) {
  // Get user from cookies
  const curUser = GetCookie('user');
  const currentUserId = curUser?._id;

  // Update delegation loading management
  const delegationData = useAvailableDelegations();

  const [emailData, setEmailData] = useState<Record<string, any>>({});
  const [subjectDrafts, setSubjectDrafts] = useState<{ [key in EngagementType]: string }>({
    meeting: '',
    checkin: '',
    news: '',
  });
  const [emailDrafts, setEmailDrafts] = useState<{ [key in EngagementType]: string }>({
    meeting: '',
    checkin: '',
    news: '',
  });
  const [isSending, setIsSending] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [selectedEngagementType, setSelectedEngagementType] = useState<EngagementType>('meeting');
  const [selectedEmail, setSelectedEmail] = useState(
    `${targetContact?.contactDetails?.name} <${targetContact?.contactDetails?.primaryEmail}>`,
  );
  const [selectedFrom, setSelectedFrom] = useState<string | null>(currentUserId);

  // Add new loading states for each type
  const [loadingStates, setLoadingStates] = useState<{ [key in EngagementType]: boolean }>({
    meeting: false,
    checkin: false,
    news: false,
  });

  // Add current user to delegation data if not already present
  const delegationOptions = useMemo(() => {
    const delegations = delegationData || [];
    const currentUserExists = delegations.some((delegation) => delegation.access[0].userId === currentUserId);

    if (!currentUserExists && currentUserId) {
      return [
        {
          access: [
            {
              userId: currentUserId,
              name: curUser?.name || 'Me',
              email: curUser?.email,
            },
          ],
        },
        ...delegations,
      ];
    }
    return delegations;
  }, [delegationData, currentUserId, curUser]);

  useEffect(() => {
    setSelectedEmail(`${targetContact?.contactDetails?.name} <${targetContact?.contactDetails?.primaryEmail}>`);
  }, [targetContact]);

  useEffect(() => {
    // Clear drafts and errors when targetContact changes
    setSubjectDrafts({
      meeting: '',
      checkin: '',
      news: '',
    });
    setEmailDrafts({
      meeting: '',
      checkin: '',
      news: '',
    });
    setLoadingStates({
      meeting: false,
      checkin: false,
      news: false,
    });
  }, [targetContact]);

  const handleEngagementTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedEngagementType(event.target.value as EngagementType);
  };

  const handleFromChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedFrom(event.target.value as string);
  };

  const handleToEmailChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedEmail(event.target.value as string);
  };

  useEffect(() => {
    if (!targetContact?.contactIds?.length) {
      return;
    }

    const contactId = targetContact.contactIds[0];
    const types: EngagementType[] = ['meeting', 'checkin', 'news'];
    let mounted = true;

    const loadEmailContent = async (type: EngagementType) => {
      if (!mounted) return;

      setLoadingStates((prev) => ({ ...prev, [type]: true }));

      try {
        const cacheKey = `${contactId}_${type}`;
        if (emailData && emailData[cacheKey]) {
          if (!mounted) return;
          setSubjectDrafts((prev) => ({
            ...prev,
            [type]: emailData[cacheKey].subject,
          }));
          setEmailDrafts((prev) => ({
            ...prev,
            [type]: emailData[cacheKey].message,
          }));
        } else {
          const response = await generateEngagementEmail({ type, contactId });
          if (!mounted) return;

          if (response?.data) {
            setSubjectDrafts((prev) => ({
              ...prev,
              [type]: response.data.subject,
            }));
            setEmailDrafts((prev) => ({
              ...prev,
              [type]: response.data.message,
            }));
          }
        }
      } catch (error) {
        console.error(`Error generating ${type} email:`, error);
      } finally {
        if (mounted) {
          setLoadingStates((prev) => ({ ...prev, [type]: false }));
        }
      }
    };

    // Load emails sequentially to avoid overwhelming the API
    const loadAllEmails = async () => {
      for (const type of types) {
        await loadEmailContent(type);
      }
    };

    loadAllEmails();

    return () => {
      mounted = false;
    };
  }, [targetContact, emailData]);

  const handleSendEmail = async () => {
    // TODO: "are you sure?" prompt

    setIsSending(true);

    try {
      const targetContactId = targetContact.contactIds[0];
      if (!targetContactId) {
        throw new Error('Target contact ID not found');
      }

      const emailAddress = selectedEmail.match(/<([^>]+)>/)?.[1];
      if (!emailAddress) {
        throw new Error('Email address not found');
      }

      const params = {
        fromUserId: selectedFrom, // from user id
        emailAddress: emailAddress, // To email address
        subject: subjectDrafts[selectedEngagementType],
        message: emailDrafts[selectedEngagementType],
        contactId: targetContactId,
      };

      const response = await sendEngagementEmail(params);
      if (response?.success === true) {
        // close the modal without re-enabling the button if sending succeeds
        onClose();
        setIsSending(false);
      } else {
        // only set isSending to false if sending fails
        // console.log('response', response);
        setIsSending(false);
      }
    } catch (error) {
      console.error('Error sending engagement email:', error);

      // only set isSending to false if sending fails
      setIsSending(false);
    }

    // we don't use a 'finally' block to set isSending to false because,
    // IF sending succeeds, we close the modal without re-enabling the 'send' button
  };

  return (
    <PModal
      floating
      ultraWideContent
      open={isOpen}
      onClose={onClose}
      floatingDimensions={{ width: '625px', maxWidth: '90vw', height: 'auto' }}
    >
      <div className="w-full gap-2 py-2">
        <div className="w-full space-y-1.5 flex justify-between items-center relative">
          <div>
            <span className="text-muted-foreground font-semibold text-lg mb-2">Compose Email</span>
          </div>
          <Button variant="ghost" size="sm" onClick={onClose}>
            <X className="h-4 w-4" />
          </Button>
        </div>

        <hr className="my-2 border-gray-300" />

        <div className="grid gap-4 py-4">
          <div className="flex gap-4">
            <Label htmlFor="engagementType" className="text-right" style={{ width: '100px', marginTop: '13px' }}>
              Type:
            </Label>
            <StyledSelect
              id="engagementType"
              value={selectedEngagementType}
              onChange={handleEngagementTypeChange}
              className="w-full"
            >
              <StyledMenuItem value="meeting">Meeting</StyledMenuItem>
              <StyledMenuItem value="checkin">Check-in</StyledMenuItem>
              <StyledMenuItem value="news">Personalized News</StyledMenuItem>
            </StyledSelect>
          </div>
          <div className="flex gap-4">
            <Label htmlFor="from" className="text-right" style={{ width: '100px', marginTop: '13px' }}>
              From:
            </Label>
            {delegationData?.length > 0 ? (
              <StyledSelect
                id="from"
                value={selectedFrom}
                onChange={handleFromChange}
                className="w-full"
                disabled={isSending || isGenerating}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: MENU_MAX_HEIGHT,
                    },
                  },
                }}
              >
                {delegationOptions?.map((delegation) => (
                  <StyledMenuItem key={delegation.access[0].userId} value={delegation.access[0].userId}>
                    {`${delegation.access[0].name} <${delegation.access[0].email}>`}
                  </StyledMenuItem>
                ))}
              </StyledSelect>
            ) : (
              <div
                id="from"
                className="w-full flex h-10 items-center rounded-md border border-input bg-background px-3 py-2 text-sm text-muted-foreground"
              >
                {`${curUser?.name || 'Me'} <${curUser?.email}>`}
              </div>
            )}
          </div>
          <div className="flex gap-4">
            <Label htmlFor="to" className="text-right" style={{ width: '100px', marginTop: '13px' }}>
              To:
            </Label>
            <StyledSelect
              id="to"
              value={selectedEmail}
              onChange={handleToEmailChange}
              className="w-full"
              disabled={isSending || isGenerating}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: MENU_MAX_HEIGHT,
                  },
                },
              }}
            >
              {targetContact?.contactDetails?.emails?.map((email) => (
                <StyledMenuItem key={email} value={`${targetContact?.contactDetails?.name} <${email}>`}>
                  {`${targetContact?.contactDetails?.name} <${email}>`}
                </StyledMenuItem>
              ))}
            </StyledSelect>
          </div>
          <div className="flex gap-4">
            <Label htmlFor="subject" className="text-right" style={{ width: '100px', marginTop: '13px' }}>
              Subject:
            </Label>
            <div className="relative w-full">
              <Input
                id="subject"
                value={selectedEngagementType ? subjectDrafts[selectedEngagementType] : ''}
                onChange={
                  isSending || loadingStates[selectedEngagementType]
                    ? undefined
                    : (e) => setSubjectDrafts({ ...subjectDrafts, [selectedEngagementType]: e.target.value })
                }
                className="w-full"
                readOnly={isSending || loadingStates[selectedEngagementType]}
                disabled={isSending || loadingStates[selectedEngagementType]}
              />
              {loadingStates[selectedEngagementType] && (
                <CircularProgress
                  size={20}
                  color="inherit"
                  className="absolute left-1/2"
                  style={{
                    marginTop: '-30px',
                  }}
                />
              )}
            </div>
          </div>
          <div className="flex gap-4">
            <Label htmlFor="message" className="text-right" style={{ width: '100px', marginTop: '13px' }}>
              Message:
            </Label>
            <div className="relative w-full">
              <Textarea
                id="message"
                value={selectedEngagementType ? emailDrafts[selectedEngagementType] : ''}
                onChange={
                  isSending || isGenerating
                    ? undefined
                    : (e) => setEmailDrafts({ ...emailDrafts, [selectedEngagementType]: e.target.value })
                }
                className="w-full"
                rows={10}
                readOnly={isSending || isGenerating}
                disabled={isSending || isGenerating}
              />
              {isGenerating && (
                <CircularProgress
                  size={20}
                  color="inherit"
                  className="absolute left-1/2 top-1/2"
                  style={{
                    marginTop: '-30px',
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <DialogFooter>
          <Button type="submit" onClick={handleSendEmail} disabled={isSending || isGenerating}>
            {(() => {
              if (isGenerating) return <CircularProgress size={20} color="inherit" />;
              if (isSending) return 'Sending...';
              return selectedFrom === currentUserId ? (
                'Send Email'
              ) : (
                <>
                  <Clock className="mr-2 h-4 w-4" />
                  Queue Email for{' '}
                  {delegationOptions.find((delegate) => delegate.access[0].userId === selectedFrom)?.access[0].name}
                </>
              );
            })()}
          </Button>
        </DialogFooter>
      </div>
    </PModal>
  );
}
