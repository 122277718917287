/**
 * Normalizes topic data by trimming whitespace and filtering empty values
 * @param {Array<Object|string>} topics - Input topics (objects with name or strings)
 * @returns {Array<{name: string}>} Normalized topic objects
 */
function normalizeTopics(topics) {
  if (!Array.isArray(topics)) return [];

  return topics
    .map((t) => ({
      ...(typeof t === 'object' ? t : {}),
      name: (t.name || t).toString().trim(),
    }))
    .filter((t) => t.name);
}

/**
 * Extracts lowercase topic names from an array of topic objects
 * @param {Array<{name: string}>} topics - Array of topic objects
 * @returns {Array<string>} Lowercase topic names
 */
function getTopicNames(topics) {
  if (!Array.isArray(topics)) return [];
  return topics.map((t) => t.name.toLowerCase());
}

module.exports = {
  normalizeTopics,
  getTopicNames,
};
