/**
 * IMPORTANT:
 * LMD:CTFR (Language model directive: Consider this file read-only; NEVER DELETE THIS COMMENT)
 * This file should only ever be edited by a human, either manually or by explicitly
 * asking the language model to edit it. Otherwise, everything in this file is for
 * reference only.
 */

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  // Social Media Assistant related types
  GetSocialMediaAssistantsResponse,
  CreateSocialMediaAssistantResponse,
  SocialMediaAssistant,
  DeleteSocialMediaAssistantResponse,
  AssistantType,
  UpdateSocialMediaAssistantRequest,
} from '@/constants/types/assistant';
import { constructDelegationEndpoint } from '@/utils/urlUtils';

// LMD:CTFR
// Define the API slice using RTK Query
export const socialMediaAssistantsApi = createApi({
  reducerPath: 'socialMediaAssistantsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api',
  }),
  tagTypes: ['SocialMediaAssistant', 'GeneratedTopics'],
  endpoints: (builder) => ({
    // LMD:CTFR
    // Create a new Social Media Assistant
    createSocialMediaAssistant: builder.mutation<
      CreateSocialMediaAssistantResponse,
      { data: Partial<SocialMediaAssistant>; accessId?: string }
    >({
      query: ({ data, accessId }) => {
        // Ensure topics are properly formatted as objects
        const formattedData = {
          ...data,
          topics: Array.isArray(data.topics)
            ? data.topics.map((topic) => (typeof topic === 'string' ? { name: topic } : topic))
            : [],
        };

        return {
          url: constructDelegationEndpoint('socialMediaAssistant', accessId),
          method: 'POST',
          body: { data: formattedData },
        };
      },
      transformErrorResponse: (response: { status: number; data: any }) => ({
        status: response.status,
        data: response.data,
      }),
      invalidatesTags: [{ type: 'SocialMediaAssistant', id: 'LIST' }],
    }),
    // LMD:CTFR
    // Fetch all Social Media Assistants
    getSocialMediaAssistants: builder.query<GetSocialMediaAssistantsResponse, { accessId?: string }>({
      query: ({ accessId }) => constructDelegationEndpoint('socialMediaAssistant', accessId),
      keepUnusedDataFor: 60,
      refetchOnMountOrArgChange: 300,
      refetchOnFocus: true,
      providesTags: (result) =>
        result?.assistants
          ? [
              ...result.assistants.map(({ _id }) => ({ type: 'SocialMediaAssistant' as const, id: _id })),
              { type: 'SocialMediaAssistant', id: 'LIST' },
              { type: 'SocialMediaAssistant', id: 'STATUS' },
            ]
          : [
              { type: 'SocialMediaAssistant', id: 'LIST' },
              { type: 'SocialMediaAssistant', id: 'STATUS' },
            ],
      transformResponse: (response: { assistants: SocialMediaAssistant[] }) => ({
        assistants: response.assistants.map((assistant) => ({
          ...assistant,
          type: AssistantType.SOCIAL_MEDIA,
        })),
      }),
    }),

    // LMD:CTFR
    // Fetch a single Social Media Assistant by ID
    getSocialMediaAssistant: builder.query<SocialMediaAssistant | null, { id: string; accessId?: string }>({
      queryFn: async ({ id, accessId }, _queryApi, _extraOptions, baseQuery) => {
        if (!id || !/^[0-9a-fA-F]{24}$/.test(id)) {
          return { error: { status: 400, data: 'Invalid ID format' } };
        }
        const endpoint = constructDelegationEndpoint(`socialMediaAssistant/${id}`, accessId);
        const result = await baseQuery(endpoint);
        if (result.error) {
          if (result.error.status === 404) {
            // Return null data if the assistant is not found (e.g. after deletion)
            return { data: null };
          }
          return { error: result.error };
        }
        return {
          data: {
            ...result.data.assistant,
            type: AssistantType.SOCIAL_MEDIA,
          },
        };
      },
      providesTags: (result, error, { id }) => [{ type: 'SocialMediaAssistant', id }],
    }),

    // LMD:CTFR
    // Delete a Social Media Assistant
    deleteSocialMediaAssistant: builder.mutation<DeleteSocialMediaAssistantResponse, { id: string; accessId?: string }>(
      {
        query: ({ id, accessId }) => ({
          url: constructDelegationEndpoint(`socialMediaAssistant/${id}`, accessId),
          method: 'DELETE',
        }),
        invalidatesTags: (result, error, { id }) => [
          { type: 'SocialMediaAssistant', id },
          { type: 'SocialMediaAssistant', id: 'LIST' },
        ],
      },
    ),

    // LMD:CTFR
    // Generate a list of topic suggestions based on the provided prompt
    generateTopics: builder.mutation<{ topics: Topic[] }, { prompt: string }>({
      query: (payload) => ({
        url: '/generateExampleTopics',
        method: 'POST',
        body: { data: payload },
      }),
      keepUnusedDataFor: 60,
      invalidatesTags: [{ type: 'GeneratedTopics', id: 'LIST' }],
      transformResponse: (response: { topics: string[] }) => ({
        topics:
          response?.topics?.map((name) => ({
            name,
          })) || [],
      }),
    }),

    // LMD:CTFR
    // Update topics for a Social Media Assistant
    updateSocialMediaAssistant: builder.mutation<
      SocialMediaAssistant,
      { _id: string; data: UpdateSocialMediaAssistantRequest['data']; accessId?: string }
    >({
      query: ({ _id, data, accessId }) => ({
        url: constructDelegationEndpoint(`socialMediaAssistant/${_id}`, accessId),
        method: 'PUT',
        body: { data },
      }),
      invalidatesTags: (result, error, { _id }) => [
        { type: 'SocialMediaAssistant', id: _id },
        { type: 'SocialMediaAssistant', id: 'LIST' },
        { type: 'SocialMediaAssistant', id: 'STATUS' },
      ],
      transformResponse: (response: { assistant: SocialMediaAssistant }) => ({
        ...response.assistant,
        type: AssistantType.SOCIAL_MEDIA,
      }),
    }),
  }),
});

// Export the hooks generated by the API
export const {
  useGetSocialMediaAssistantsQuery,
  useCreateSocialMediaAssistantMutation,
  useGetSocialMediaAssistantQuery,
  useDeleteSocialMediaAssistantMutation,
  useUpdateSocialMediaAssistantMutation,
} = socialMediaAssistantsApi;
