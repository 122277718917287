const TUTORIAL_TASK_NAMES = {
  createTopics: 'createTopics',
  connectSocial: 'connectSocial',
  downloadLinkedInChromeExtension: 'downloadLinkedInChromeExtension',
  downloadMobileApp: 'downloadMobileApp',
};

module.exports = {
  TUTORIAL_TASK_NAMES,
};
