import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled, useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { ReactComponent as User } from '../../assets/Icons/User.svg';
import { ReactComponent as Settings } from '../../assets/Icons/Settings.svg';
import { useNavigate } from 'react-router-dom';
import { TooltipProvider, Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';

import PTypography from './PTypography';
import PToolTip from './PToolTip';
import SettingsModal from '../SettingsModal';
import { useDispatch } from 'react-redux';
import { setPage } from '@/redux/authSlice';

const getVariantStyles = (theme, pVariant) => {
  let colorScheme = {};

  switch (pVariant) {
    case 'primary':
      colorScheme = {
        text: theme.palette.primaryCL.Blue100,
        background: theme.palette.primaryCL.Blue50,
        hoverText: theme.palette.primaryCL.Blue100,
        hoverBackground: theme.palette.primaryCL.Blue40,
        activeText: theme.palette.primaryCL.Blue110,
        activeBackground: theme.palette.primaryCL.Blue60,
      };
      break;
  }

  return colorScheme;
};

const StyledPSideMenuUser = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'pVariant',
})(({ theme, pVariant, active, showLabel }) => {
  const { background, text, hoverBackground, hoverText, activeBackground, activeText } = getVariantStyles(
    theme,
    pVariant,
  );

  return {
    ...theme.typography.bold.body2,
    'borderRadius': '14px',
    'padding': showLabel ? '16px 20px' : '16px',
    'fontFamily': 'Inter',
    'textTransform': 'none',
    'width': '100%',
    'color': active ? activeText : text,
    'justifyContent': showLabel ? 'flex-start' : 'center',
    'alignItems': 'flex-start',
    'backgroundColor': active ? activeBackground : background,
    '&:hover': {
      'backgroundColor': hoverBackground,
      'color': hoverText,
      '& .MuiButton-startIcon': {
        color: hoverText,
      },
    },
    '&:active': {
      'backgroundColor': activeBackground,
      'color': activeText,
      '& .MuiButton-startIcon': {
        color: activeText,
      },
    },
    '& .MuiButton-startIcon': {
      'margin': 0,
      'marginRight': showLabel ? '14px' : '0',
      'display': 'flex',
      'flexDirection': 'column',
      'alignItems': 'flex-start',
      'justifyContent': 'flex-start',
      '& > *': {
        margin: '0 !important',
      },
    },
  };
});

/**
 * PSideMenuUser is a styled button component for the side menu.
 * It can be used to create a user profile button or any other button that needs to be displayed in the side menu.
 * It supports a primary variant and can be customized with different icons and labels.
 * It also supports a tooltip that can be displayed when the button is hovered over.
 *
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The button label.
 * @param {React.ReactElement} props.Icon - The button icon.
 * @param {string} props.pVariant - The Postilize design variant (primary).
 * @param {function} props.onClick - The button click handler.
 * @param {boolean} props.active - Whether the button is active.
 * @param {boolean} props.showLabel - Whether to show the button label.
 * @param {string} props.subtitle - The button subtitle.
 */
function PSideMenuUser({ children, Icon, pVariant, onClick, active, showLabel, subtitle }) {
  const theme = useTheme();
  const [settingsOpen, setSettingsOpen] = React.useState(false);
  const iconFill =
    (active ? getVariantStyles(theme, pVariant).activeText : getVariantStyles(theme, pVariant).text) || null;
  const dispatch = useDispatch();

  const handleSettingsClick = (e) => {
    e.stopPropagation();
    dispatch(setPage('settings'));
  };

  const handleCloseSettings = () => {
    setSettingsOpen(false);
  };

  // Map our variant to the MUI variant
  const translatedVariant =
    {
      primary: 'contained',
    }[pVariant] || 'contained';

  const menuItem = (
    <>
      <TooltipProvider>
        <StyledPSideMenuUser
          variant={translatedVariant}
          disableElevation
          pVariant={pVariant}
          startIcon={<User fill={iconFill} />}
          onClick={onClick}
          showLabel={showLabel}
          sx={{ position: 'relative' }}
        >
          {showLabel && (
            <Stack alignItems="flex-start" gap="4px" sx={{ p: 0, m: 0, overflow: 'hidden' }}>
              <PTypography
                size="body2"
                weight="bold"
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '100%',
                }}
              >
                {children}
              </PTypography>
              {subtitle && (
                <PTypography size="footnote" weight="regular">
                  {subtitle}
                </PTypography>
              )}
            </Stack>
          )}
          <Tooltip>
            <TooltipTrigger asChild>
              <Settings
                fill={iconFill}
                style={{
                  position: 'absolute',
                  right: '16px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  cursor: 'pointer',
                  width: '20px',
                  height: '20px',
                }}
                onClick={handleSettingsClick}
                data-menu-item="Settings"
              />
            </TooltipTrigger>
            <TooltipContent side="top">Settings</TooltipContent>
          </Tooltip>
        </StyledPSideMenuUser>
        <SettingsModal open={settingsOpen} onClose={handleCloseSettings} />
      </TooltipProvider>
    </>
  );

  return showLabel ? (
    menuItem
  ) : (
    <PToolTip title={children} placement="right">
      {menuItem}
    </PToolTip>
  );
}

PSideMenuUser.propTypes = {
  /** React children */
  children: PropTypes.node,

  /** Postilize design variant, only primary for now, leaving in so we can easily update in future */
  pVariant: PropTypes.oneOf(['primary']),

  /** Click handler */
  onClick: PropTypes.func.isRequired,
};

PSideMenuUser.defaultProps = {
  Icon: null,
  pVariant: 'primary',
  showLabel: true,
  active: false,
};

export default PSideMenuUser;
