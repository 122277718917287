import { createContext, useContext } from 'react';
import useTopicSelection, { type UseTopicSelectionProps } from './useTopicSelection';

/**
 * Context type containing all topic selection state and handlers
 * @type {TopicSelectionContextType}
 */
export type TopicSelectionContextType = ReturnType<typeof useTopicSelection>;

/**
 * Context for managing topic selection state across components
 * @context
 */
const TopicSelectionContext = createContext<TopicSelectionContextType | undefined>(undefined);

/**
 * Custom hook to access the TopicSelection context
 *
 * @hook
 * @throws {Error} When used outside of TopicSelectionProvider
 * @returns {TopicSelectionContextType} Topic selection context value
 *
 * @example
 * ```tsx
 * const { selectedTopics, handleTopicSubmission } = useTopicSelectionContext();
 * ```
 */
export const useTopicSelectionContext = () => {
  const context = useContext(TopicSelectionContext);
  if (!context) {
    throw new Error('useTopicSelectionContext must be used within a TopicSelectionProvider');
  }
  return context;
};

/**
 * Props for the TopicSelectionProvider component
 * @interface TopicSelectionProviderProps
 * @property {React.ReactNode} children - Child components
 * @property {UseTopicSelectionProps} props - Topic selection configuration
 */
type TopicSelectionProviderProps = {
  children: React.ReactNode;
} & UseTopicSelectionProps;

/**
 * Provider component for topic selection context
 *
 * Wraps components that need access to topic selection state and handlers
 *
 * @component
 * @param {TopicSelectionProviderProps} props - Provider props
 * @returns {JSX.Element} Provider component
 *
 * @example
 * ```tsx
 * <TopicSelectionProvider onTopicsChange={handleChange}>
 *   <TopicSelection />
 * </TopicSelectionProvider>
 * ```
 */
export const TopicSelectionProvider = ({ children, ...props }: TopicSelectionProviderProps) => (
  <TopicSelectionContext.Provider value={useTopicSelection(props)}>{children}</TopicSelectionContext.Provider>
);
