import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material';
import { alpha } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import PTextfield from '@/components/library/PTextfield';
import Http from '../../../../http/httpClient';
import { useSelector, useDispatch } from 'react-redux';
import { setDelegationSequences } from '@/redux/authSlice';

import { ReactComponent as Delete } from '../../../../assets/Icons/Delete.svg';

export default function RenameModal(props) {
  const theme = useTheme();
  const { onClose, open, activeItem, setCreatedSequenceId, setSequenceList, sequenceList, forDelegation } = props;
  const [name, setName] = React.useState('');
  const delegationSequences = useSelector((state) => JSON.parse(JSON.stringify(state.auth.delegationSequences)));
  const delegationAccessId = useSelector((state) => state.auth.delegationAccessId);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (open) {
      setName(activeItem?.name);
    }
  }, [activeItem?.name, open]);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleRename = React.useCallback(() => {
    const endpoint =
      forDelegation && delegationAccessId ? `updateSequenceSettings/${delegationAccessId}` : 'updateSequenceSettings';

    Http.postData(endpoint, {
      type: 'Rename',
      sequenceData: {
        _id: activeItem._id,
        sequenceName: name,
      },
    })
      .then((res) => {
        if (res.status) {
          const newSequence = res.userSequence.sequences.find((item) => item._id === activeItem._id);

          if (newSequence) {
            if (forDelegation) {
              const updatedDelegationSequences = delegationSequences.map((item) =>
                item._id === activeItem._id ? { ...item, name: name } : item,
              );
              dispatch(setDelegationSequences(updatedDelegationSequences));
              setCreatedSequenceId(activeItem._id);
            } else {
              const updatedSequenceList = sequenceList.map((item) =>
                item._id === activeItem._id ? { ...item, name: newSequence.name } : item,
              );
              setCreatedSequenceId(activeItem._id);
              setSequenceList(updatedSequenceList);
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        onClose();
      });
  }, [
    activeItem?._id,
    delegationAccessId,
    delegationSequences,
    dispatch,
    forDelegation,
    name,
    onClose,
    sequenceList,
    setCreatedSequenceId,
    setSequenceList,
  ]);
  /*
  const handleSequenceNameChange = e => {
    const newName = e.target.value;
    setSequenceName(newName)

    setIsEmpty(newName.trim().length === 0);
  }
*/

  return (
    <>
      <Dialog
        onClose={onClose}
        open={open}
        fullScreen
        sx={{
          px: {
            xs: 0,
            md: 2,
          },
          maxWidth: '100vw',
        }}
      >
        <DialogTitle sx={{ p: 0 }}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={9} lg={10}>
              <IconButton aria-label="close" onClick={onClose} sx={{ pt: 2, pl: 2 }}>
                <Delete fill={theme.palette.primary.grey} />
              </IconButton>
            </Grid>
            <Grid item xs={3} lg={2}>
              <Button
                onClick={handleRename}
                sx={{
                  'maxWidth': {
                    xs: '100%',
                    md: '200px',
                  },
                  'backgroundColor': theme.palette.primary.blue,
                  'color': theme.palette.primary.white,
                  'textTransform': 'none',
                  'width': {
                    xs: 'auto',
                    sm: '167px',
                  },
                  'py': 1,
                  'px': 2,
                  'mr': 2,
                  'mt': 2,
                  'whiteSpace': 'nowrap',
                  'overflow': 'hidden',
                  'textOverflow': 'ellipsis',
                  'borderRadius': 2,
                  'boxShadow': '0px 4px 8px 0px #2C64E31F',
                  '&:hover': {
                    backgroundColor: alpha(theme.palette.primary.blue, 0.8),
                  },
                }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: theme.palette.primary.white }}>
          <Grid container justifyContent="space-between">
            <Grid item xs={0} md={3} />
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  fontSize: {
                    xs: '28px',
                    md: '35px',
                  },
                  fontWeight: 700,
                  color: theme.palette.primary.black,
                  pt: {
                    xs: 0,
                    md: 5,
                  },
                  pb: {
                    xs: 0,
                    md: 5,
                  },
                }}
              >
                Rename your assistant
              </Typography>
              <Typography sx={{ fontSize: '14px', fontWeight: 700, color: theme.palette.primary.black, pb: 1 }}>
                Assistant name
              </Typography>
              <PTextfield
                type="text"
                value={name}
                onChange={handleNameChange}
                onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                paperStyle={{ mx: 0 }}
              />
            </Grid>
            <Grid item xs={0} md={3} />
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
