/**
 *  This is a copy of the `ClientAcquisition` component,
 *  which is the primary component for editing sequences (aka assistants).
 *  This version has been adapted to work with the delegation sequence
 *  from the Redux store.
 */

import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { CssBaseline, Stack, Menu, MenuItem, Box, Divider, Container } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { setActionFlag } from '@/redux/authSlice';
import { ReactComponent as Close } from '@Assets/Icons/Delete.svg';

import { PTypography, PSectionNav, PDropdownVDeux, PButton, PModal } from '@Library';
import SequenceModal from '@/pages/ClientAcquisition/components/addSequenceModal/sequenceModal.js';
import PauseModal from '@/pages/ClientAcquisition/components/sequenceSettingsModal/pause.js';
import DeleteModal from '@/pages/ClientAcquisition/components/sequenceSettingsModal/delete.js';
import RenameModal from '@/pages/ClientAcquisition/components/sequenceSettingsModal/rename.js';
import EditModal from '@/pages/ClientAcquisition/components/sequenceSettingsModal/edit.js';
import {
  getDefaultStrategies,
  getDefaultTemplates,
  getAvailableList,
  cloneSequence,
  getSequenceContacts,
} from '@/helper/apiHelper.js';
import { GetCookie } from '@/helper/cookieManager.js';
import { Milestones, SEC_FILING_DESCRIPTIONS } from '@/constants/constant';
import { getSelectedAssistantsString } from '@/helper/utilities';

import Http from '@/http/httpClient.js';
import DelegationSequenceReview from '@/pages/ClientAcquisition/components/DelegationSequenceReview.js';

const DelegationSequences = ({ onClose }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const curUser = useMemo(() => GetCookie('user'), []);
  const [isEmpty, setIsEmpty] = useState(true);
  const [sequenceModalOpen, setSequenceModalOpen] = useState(false);
  const [createdSequenceId, setCreatedSequenceId] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [progress, setProgress] = useState({});
  const [sequenceContacts, setSequenceContacts] = useState([]);
  const [sequenceContacts2, setSequenceContacts2] = useState([]);
  const [stepTypes, setStepTypes] = useState([]);
  const [defaultTemplates, setDefaultTemplates] = useState([]);
  const [strategies, setStrategies] = useState([]);
  const [availableList, setAvailableList] = useState([]);
  const [targetAudience, setTargetAudience] = useState({});
  const [stepText, setStepText] = useState([]);
  const [initialLoading, setInitialLoading] = useState(true);
  const [strategyLoading, setStrategyLoading] = useState(true);
  const [initialListLoading, setInitialListLoading] = useState(true);
  const [userBio, setUserBio] = useState(null);
  const [selectedAssistants, setSelectedAssistants] = useState([]);

  const [renameOpen, setRenameOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [pauseOpen, setPauseOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const [sequenceList, setSequenceList] = useState([]);
  const allCompanySequences = useSelector((state) => state.auth.sequences);
  const parentRef = useRef(null);
  const [parentHeight, setParentHeight] = useState(0);

  useEffect(() => {
    if (parentRef.current) {
      setParentHeight(parentRef.current.clientHeight);
    }
  }, [parentRef]);

  const delegationAccessId = useSelector((state) => state.auth.delegationAccessId);
  const delegationSequences = useSelector((state) => state.auth.delegationSequences);

  const memoizedDelegationSequences = useMemo(
    () => JSON.parse(JSON.stringify(delegationSequences)),
    [delegationSequences],
  );

  useEffect(() => {
    if (memoizedDelegationSequences.length > 0 && !activeItem) {
      setActiveItem(memoizedDelegationSequences[0]);
    }
    if (memoizedDelegationSequences.length === 0 && activeItem) {
      setActiveItem(null);
    }
  }, [memoizedDelegationSequences, activeItem]);

  useEffect(() => {
    setSequenceList(memoizedDelegationSequences);
  }, [memoizedDelegationSequences]);

  const [selectedDelegates, setSelectedDelegates] = useState([]);

  const closeSequenceModal = useCallback(() => setSequenceModalOpen(false), []);

  const closeRename = useCallback(() => {
    setRenameOpen(false);
    dispatch(setActionFlag({ flag: 'refreshDelegationSequences', value: true }));
  }, [dispatch]);

  const closeEdit = useCallback(() => {
    setEditOpen(false);
    dispatch(setActionFlag({ flag: 'refreshDelegationSequences', value: true }));
  }, [dispatch]);

  const closePause = useCallback(() => {
    setPauseOpen(false);
    dispatch(setActionFlag({ flag: 'refreshDelegationSequences', value: true }));
  }, [dispatch]);

  const closeDelete = useCallback(() => {
    setDeleteOpen(false);
    dispatch(setActionFlag({ flag: 'refreshDelegationSequences', value: true }));
  }, [dispatch]);

  const handleClick = useCallback((item) => {
    item.template.steps = item?.template?.steps.map((step) => {
      if (step?.milestone === Milestones.SEC144) {
        return { ...step, desc: 'Spot large stock sales' };
      }
      if (step?.milestone === Milestones.FUNDING) {
        return { ...step, desc: 'New funding email' };
      }
      if (step?.milestone === Milestones.ACQUISITION) {
        return { ...step, desc: 'New acquisition email' };
      }
      if (step?.milestone === Milestones.SEC13D) {
        return { ...step, desc: SEC_FILING_DESCRIPTIONS.SEC13D };
      }
      return step;
    });
    setActiveItem(item);
  }, []);

  const handleAssistantDropdownItemClick = useCallback(
    (assistantName) => {
      // Temporary Disable Multi Select
      // if (selectedAssistants.includes(assistantName)) {
      //   // remove the assistant if it is already in the list;
      //   setSelectedAssistants(selectedAssistants.filter((assistant) => assistant !== assistantName));
      // } else {
      //   // add it if it is not in the list
      //   setSelectedAssistants([...selectedAssistants, assistantName]);
      // }

      // Enable Single Select
      if (selectedAssistants.includes(assistantName)) {
        setSelectedAssistants([]);
      } else {
        setSelectedAssistants([assistantName]);
      }
    },
    [selectedAssistants],
  );

  const handleCopyAssistantSubmit = useCallback(async () => {
    if (selectedAssistants.length > 0) {
      for (const assistant of selectedAssistants) {
        const seqId = Object.values(allCompanySequences).find((seq) => seq.name === assistant)?._id;
        await cloneSequence(seqId, delegationAccessId);
      }

      dispatch(setActionFlag({ flag: 'refreshDelegationSequences', value: true }));
      setCopyModalOpen(false);
    }
  }, [selectedAssistants, dispatch, allCompanySequences, delegationAccessId]);

  const fetchBio = useCallback(async () => {
    const response = await Http.getData('setup/getBio');
    setUserBio({ name: curUser.name, company: response?.bio?.companyName });
  }, [curUser.name]);

  const fetchSequenceData = useCallback(
    (sequenceId) => {
      if (!memoizedDelegationSequences || !delegationAccessId) {
        return;
      }

      dispatch(setActionFlag({ flag: 'refreshDelegationSequences', value: true }));

      setProgress((prev) => ({ ...prev, getSequence: true }));
      setSequenceList(memoizedDelegationSequences);
      const selectedItem = sequenceId
        ? memoizedDelegationSequences.find((item) => item._id.toString() === sequenceId.toString())
        : memoizedDelegationSequences[0];
      setActiveItem(selectedItem);
      setProgress((prev) => ({ ...prev, getSequence: false }));
      setIsEmpty(memoizedDelegationSequences.length === 0);
    },
    [memoizedDelegationSequences, delegationAccessId, dispatch],
  );

  const fetchDefaultTemplates = useCallback(() => {
    getDefaultTemplates()
      .then(function (response) {
        if (response && response.status) {
          setDefaultTemplates([...response.templates]);
          if (response.stepText) setStepText([...response.stepText]);
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .finally(() => {
        setInitialLoading(false);
      });
  }, []);

  const fetchStrategies = useCallback(async () => {
    getDefaultStrategies()
      .then((response) => {
        if (response && response.status) {
          setStrategies([...response.strategies]);
          setTargetAudience(response.targetAudience);
        }
      })
      .catch((error) => {
        console.error('Error : ', error);
      })
      .finally(() => {
        setStrategyLoading(false);
      });
  }, []);

  const fetchInitialLists = useCallback(async () => {
    getAvailableList()
      .then((response) => {
        if (response && response.status) {
          setAvailableList([...response.lists]);
        }
      })
      .catch((error) => {
        console.error('Error : ', error);
      })
      .finally(() => {
        setInitialListLoading(false);
      });
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const seqId = urlParams.get('seqId');

    fetchBio();
    fetchSequenceData(seqId);
    fetchDefaultTemplates();
    fetchStrategies();
    fetchInitialLists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchContacts = async () => {
      if (!activeItem) {
        return;
      }

      setProgress((prev) => ({ ...prev, getContacts: true }));
      try {
        const response = await getSequenceContacts(activeItem._id, delegationAccessId);
        if (response && response.contacts) {
          setSequenceContacts(response.contacts);
          setSequenceContacts2(response.contacts2);
          setStepTypes(response.steps);
          setProgress((prev) => ({ ...prev, getContacts: false }));
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchContacts();
  }, [activeItem, delegationAccessId]);

  useEffect(() => {
    if (activeItem) {
      const newActiveItem = sequenceList.find((item) => item._id.toString() === activeItem._id.toString());
      if (newActiveItem && newActiveItem._id !== activeItem._id) {
        setActiveItem({ ...newActiveItem });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sequenceList]);

  const handleSequenceDelete = useCallback(
    (sequenceId) => {
      try {
        Http.postData(`deleteSequenceData/${delegationAccessId}`, { seqId: sequenceId })
          .then(function (response) {
            if (response.status) {
              const filteredUserSequence = sequenceList.filter((seq) => seq._id !== sequenceId);
              const selectedItem = filteredUserSequence[0];
              setActiveItem(selectedItem);
              setSequenceList(filteredUserSequence);
              setIsEmpty(filteredUserSequence.length === 0);
            }
          })
          .finally(() => {
            dispatch(setActionFlag({ flag: 'refreshDelegationSequences', value: true }));
          });
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch, sequenceList],
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleRenameClick = useCallback(() => {
    setRenameOpen(true);
    handleMenuClose();
  }, [handleMenuClose]);

  const handleEditClick = useCallback(() => {
    setEditOpen(true);
    handleMenuClose();
  }, [handleMenuClose]);

  const handlePauseClick = useCallback(() => {
    setPauseOpen(true);
    handleMenuClose();
  }, [handleMenuClose]);

  const handleDeleteClick = useCallback(() => {
    setDeleteOpen(true);
    handleMenuClose();
  }, [handleMenuClose]);

  const handleDelegatesDropdownItemClick = useCallback(
    (delegateId) => {
      if (selectedDelegates.includes(delegateId)) {
        setSelectedDelegates(selectedDelegates.filter((id) => id !== delegateId));
      } else {
        setSelectedDelegates([...selectedDelegates, delegateId]);
      }
    },
    [selectedDelegates],
  );

  const [copyModalOpen, setCopyModalOpen] = useState(false);

  const StyledStack = ({ theme, children }) => {
    return (
      <Stack gap="16px" border={`1px solid ${theme.palette.primaryCL.Black70}`} borderRadius="4px" padding="16px">
        {children}
      </Stack>
    );
  };

  useEffect(() => {
    if (isEmpty && progress.getSequence === false) {
      setCopyModalOpen(true);
    }
  }, [isEmpty, progress.getSequence]);

  const closeCopyAssistantModal = useCallback(() => {
    setCopyModalOpen(false);
    // if the "No assistants..." text is shown and the user closes the modal,
    // we also need to call the onClose function passed as prop
    if (isEmpty && progress.getSequence === false && typeof onClose === 'function') {
      onClose();
    }
  }, [isEmpty, progress.getSequence, onClose]);

  const MemoizedCopyAssistantModal = useMemo(() => {
    if (!allCompanySequences || Object.keys(allCompanySequences).length === 0) {
      return null;
    }

    return (
      <PModal
        floating
        floatingDimensions={{ width: '45vw', height: 'auto' }}
        ultraWideContent
        inlineHeaderText
        open={copyModalOpen}
        headerText="Copy Assistant"
        pVariant="primary"
        onClose={closeCopyAssistantModal}
        actionButtons={[
          <PButton pVariant="plain" onClick={closeCopyAssistantModal}>
            <Close fill="black" />
          </PButton>,
        ]}
      >
        <Box theme={theme}>
          {isEmpty && progress.getSequence === false && (
            <PTypography size="h1" weight="regular" sx={{ mb: 1 }}>
              No assistants found for this user. Copy an existing assistant from another user?
            </PTypography>
          )}
          <PDropdownVDeux
            pVariant="grey"
            displayAs="text"
            buttonText="Choose Postilize AI Assistants"
            inputValue={getSelectedAssistantsString(Object.values(allCompanySequences), selectedAssistants)}
            menu={{
              headerText: 'Select assistant to copy',
              menuList: Object.values(allCompanySequences)?.map((sequence) => ({
                isEnabled: true,
                label: sequence.name,
                onSubmit: () => handleAssistantDropdownItemClick(sequence.name),
                isSelected: selectedAssistants.includes(sequence.name),
              })),
            }}
            autoCloseOnSelect={true}
          />
          <Box display="flex" justifyContent="flex-end" gap="8px" mt={16}>
            <PButton pVariant="outlined" color="inherit" onClick={closeCopyAssistantModal}>
              Cancel
            </PButton>
            <PButton pVariant="black" color="inherit" onClick={handleCopyAssistantSubmit}>
              Copy Assistant
            </PButton>
          </Box>
        </Box>
      </PModal>
    );
  }, [
    allCompanySequences,
    copyModalOpen,
    closeCopyAssistantModal,
    theme,
    isEmpty,
    progress.getSequence,
    selectedAssistants,
    handleCopyAssistantSubmit,
    handleAssistantDropdownItemClick,
  ]);

  const handleCopyAssistant = useCallback(() => {
    setCopyModalOpen(true);
  }, []);

  const processedMenuItems = useMemo(
    () =>
      sequenceList.map((item) => ({
        label: item.name,
        onClick: () => handleClick(item),
        badgeText: item.status === 'Paused' ? 'Paused' : null,
        badgeTheme: 'orange',
        active: activeItem._id === item._id,
      })),
    [activeItem, handleClick, sequenceList],
  );

  const MemoizedMenu = useMemo(
    () =>
      isMenuOpen && (
        <Menu
          anchorEl={anchorEl}
          open={isMenuOpen}
          onClose={handleMenuClose}
          PaperProps={{
            style: {
              borderRadius: '8px',
              elevation: 0,
              minWidth: '200px',
              boxShadow:
                '0px 1px 2px -1px rgba(0,0,0,0.1), ' +
                '0px 2px 3px 0px rgba(0,0,0,0.07), ' +
                '0px 1px 5px 0px rgba(0,0,0,0.06)',
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem
            onClick={handleRenameClick}
            sx={{
              fontFamily: 'Inter',
              fontWeight: 500,
              fontSize: '14px',
              color: theme.palette.primary.black,
            }}
          >
            Rename
          </MenuItem>
          <MenuItem
            onClick={handleEditClick}
            sx={{
              fontFamily: 'Inter',
              fontWeight: 500,
              fontSize: '14px',
              color: theme.palette.primary.black,
            }}
          >
            Edit steps
          </MenuItem>
          <MenuItem
            onClick={handlePauseClick}
            sx={{
              fontFamily: 'Inter',
              fontWeight: 500,
              fontSize: '14px',
              color: theme.palette.primary.black,
            }}
          >
            {activeItem.status === 'Active' ? 'Pause' : 'Unpause'}
          </MenuItem>
          <MenuItem
            onClick={handleDeleteClick}
            sx={{
              fontFamily: 'Inter',
              fontWeight: 500,
              fontSize: '14px',
              color: theme.palette.primary.orange,
            }}
          >
            Delete
          </MenuItem>
        </Menu>
      ),
    [
      activeItem,
      isMenuOpen,
      anchorEl,
      handleMenuClose,
      handleRenameClick,
      handleEditClick,
      handlePauseClick,
      handleDeleteClick,
      theme.palette.primary.black,
      theme.palette.primary.orange,
    ],
  );

  const MemoizedModals = useMemo(
    () => (
      <>
        {pauseOpen && (
          <PauseModal
            forDelegation
            open={pauseOpen}
            onClose={closePause}
            activeItem={activeItem}
            onRefresh={fetchSequenceData}
          />
        )}
        {deleteOpen && (
          <DeleteModal
            open={deleteOpen}
            onClose={closeDelete}
            handleSequenceDelete={() => handleSequenceDelete(activeItem._id)}
          />
        )}
        {renameOpen && (
          <RenameModal
            open={renameOpen}
            onClose={closeRename}
            activeItem={activeItem}
            setCreatedSequenceId={setCreatedSequenceId}
            setSequenceList={setSequenceList}
            sequenceList={sequenceList}
            forDelegation
          />
        )}
        {editOpen && (
          <EditModal
            open={editOpen}
            onClose={closeEdit}
            activeItem={activeItem}
            template={activeItem.template}
            onRefresh={fetchSequenceData}
          />
        )}
      </>
    ),
    [
      pauseOpen,
      deleteOpen,
      renameOpen,
      editOpen,
      closePause,
      closeDelete,
      closeRename,
      closeEdit,
      activeItem,
      fetchSequenceData,
      handleSequenceDelete,
      setCreatedSequenceId,
      setSequenceList,
      sequenceList,
    ],
  );

  const MemoizedSequenceModal = useMemo(
    () =>
      !initialLoading &&
      !strategyLoading &&
      !initialListLoading && (
        <SequenceModal
          open={sequenceModalOpen}
          onClose={closeSequenceModal}
          setCreatedSequenceId={setCreatedSequenceId}
          templates={defaultTemplates}
          templateSteps={stepText}
          strategies={strategies}
          targetAudience={targetAudience}
          availableList={availableList}
        />
      ),
    [
      initialLoading,
      strategyLoading,
      initialListLoading,
      sequenceModalOpen,
      closeSequenceModal,
      setCreatedSequenceId,
      defaultTemplates,
      stepText,
      strategies,
      targetAudience,
      availableList,
    ],
  );

  const MemoizedContent = useMemo(
    () => (
      <Box sx={{ height: 'calc(100vh - 60px)', overflow: 'hidden' }} ref={parentRef}>
        <CssBaseline />
        <Stack direction="row" overflow="hidden" maxHeight={`${parentHeight}px`}>
          <div
            style={{
              height: `${parentHeight}px`,
              maxWidth: '417px',
              width: '417px',
              overflow: 'scroll',
            }}
          >
            <Container
              style={{
                minWidth: '417px',
                width: '417px',
                maxWidth: '417px',
                height: '100%',
                paddingRight: '0px',
                paddingLeft: '0px',
                overflow: 'scroll',
              }}
            >
              {!isEmpty && (
                <Stack direction="row" sx={{ width: '100%', height: '100vh' }}>
                  <Box sx={{ flexGrow: 1, overflow: 'auto', width: 'fit-content' }}>
                    <PSectionNav
                      topFixed={true}
                      pVariant="primary"
                      headerText="Assistants"
                      width="417px"
                      menu={processedMenuItems}
                      headerButtonText="Edit"
                      buttonTheme="secondary"
                      headerOnClick={handleMenuOpen}
                      headerOnClick2={handleCopyAssistant}
                      headerButtonText2="Clone other"
                      buttonTheme2="secondary"
                    />
                  </Box>
                </Stack>
              )}
            </Container>
          </div>

          <Divider orientation="vertical" flexItem sx={{ height: `${parentHeight}px` }} />

          {activeItem && (
            <div style={{ height: `${parentHeight}px`, width: 'calc(95vw - 500px)', flexGrow: 1, overflow: 'scroll' }}>
              <Box sx={{ width: 'auto', flex: 3, display: 'flex', flexDirection: 'column' }}>
                <DelegationSequenceReview
                  listItem={activeItem}
                  contacts={sequenceContacts}
                  contacts2={sequenceContacts2}
                />
              </Box>
            </div>
          )}
        </Stack>
      </Box>
    ),
    [
      parentHeight,
      isEmpty,
      processedMenuItems,
      handleMenuOpen,
      handleCopyAssistant,
      activeItem,
      sequenceContacts,
      sequenceContacts2,
    ],
  );

  return (
    <>
      {MemoizedMenu}
      {MemoizedSequenceModal}
      {copyModalOpen && MemoizedCopyAssistantModal}
      {activeItem && MemoizedModals}
      {MemoizedContent}
    </>
  );
};

export default DelegationSequences;
