const { allAbbreviationReplacementMap, allAbbreviationsPattern } = require('../constants');

/**
 * Abbreviates job titles by replacing full phrases, then individual words, with their abbreviations.
 * Handles edge cases like extra spaces, hyphens, and ampersands.
 * @param {string} title - The job title to abbreviate
 * @returns {string} - The abbreviated job title
 */
function abbreviateJobTitle(title) {
  if (!title || typeof title !== 'string' || !title.trim()) return '';

  // Replace hyphens with spaces to standardize the title.
  let formattedTitle = title.trim().replace(/-/g, ' ');

  // Replace full phrases with abbreviations using the abbreviation map.
  formattedTitle = formattedTitle.replace(allAbbreviationsPattern, (match) => {
    // Normalize the match by removing punctuation, converting to lowercase,
    // and replacing hyphens and multiple spaces with a single space.
    const normalizedMatch = match
      .toLowerCase()
      .replace(/[^a-z0-9\s]/g, '') // Remove punctuation.
      .replace(/\s+/g, ' ');

    // Use the abbreviation map to replace the match.
    return allAbbreviationReplacementMap.get(normalizedMatch) || match;
  });

  // Normalize spaces and handle ampersands.
  formattedTitle = formattedTitle.replace(/\s+/g, ' ').trim();

  return formattedTitle;
}

/**
 * Normalizes a string by trimming whitespace and converting to lowercase
 * @param {string} str - String to normalize
 * @returns {string} Normalized string
 */
function normalizeString(str) {
  return str.trim().toLowerCase();
}

/**
 * Compares two strings for equality after normalization (trimming and converting to lowercase)
 * @param {string} str1 - First string to compare
 * @param {string} str2 - Second string to compare
 * @returns {boolean} True if the strings are equal after normalization
 */
function areStringsEqualIgnoreCase(str1, str2) {
  return normalizeString(str1) === normalizeString(str2);
}

/**
 * Precompiled regular expression for validating social media topics.
 * Allows Unicode letters, numbers, punctuation, symbols, and space characters.
 * The topic must be between 3 and 50 characters.
 * This regex is compiled once at module import time.
 *
 * @constant {RegExp}
 */
const COMPILED_TOPIC_REGEX = /^[\p{L}\p{N}\p{P}\p{S}\p{Zs}]{3,50}$/u;

/**
 * Checks if a topic string is valid according to the predefined criteria.
 *
 * @param {string} topic - The topic string to validate.
 * @returns {boolean} True if the topic is valid, false otherwise.
 */
function isValidTopicName(topic) {
  if (typeof topic !== 'string') return false;
  return COMPILED_TOPIC_REGEX.test(topic.trim());
}

module.exports = {
  abbreviateJobTitle,
  normalizeString,
  areStringsEqualIgnoreCase,
  isValidTopicName,
  COMPILED_TOPIC_REGEX,
};
