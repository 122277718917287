import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { getCompanyRelationships } from '@/helper/apiHelper';
import { INTERACTION_FILTER_OPTIONS } from '@postilize/shared';

// Utility Functions
const initializeFilters = (options: readonly string[]) =>
  options.reduce<Record<string, boolean>>((acc, option) => {
    acc[option] = true;
    return acc;
  }, {});

// Initial State
const defaultRelationshipTableState = {
  data: {
    cache: {} as Record<string, { [page: number]: any[]; total: number }>,
    total: 0,
    highestRelationshipScore: null as number | null,
  },
  queryParams: {
    page: 1,
    limit: 10,
    companyClientId: null as string | null,
    filters: {
      nameSearchTerm: '',
      jobTitleSearchTerm: '',
      lastInteraction: initializeFilters(INTERACTION_FILTER_OPTIONS.flatKeys),
      showHidden: false,
    },
    sortCriteria: { key: 'relationshipScore', direction: 'desc' as 'asc' | 'desc' },
  },
  loading: {
    loading: false,
    error: null,
  },
};

// Async Thunks

/** Relationship & Contact Tables */
export const fetchRelationships = createAsyncThunk(
  'relationshipTable/fetchRelationships',
  async ({ clientId }: { clientId?: string }, { getState, rejectWithValue }) => {
    const state: any = getState();
    const tableQueryParams = state.relationshipTable.queryParams;
    const { page } = tableQueryParams;
    const clientCache = state.relationshipTable.data.cache[clientId] || {};

    if (clientCache[page]) {
      return { cached: true, clientId, page };
    }

    try {
      const activeInteractionLabels = Object.keys(tableQueryParams.filters.lastInteraction).filter(
        (key) => tableQueryParams.filters.lastInteraction[key],
      );

      const interactionTypes = activeInteractionLabels.reduce((acc, label) => {
        const options = INTERACTION_FILTER_OPTIONS[label] || [];
        return acc.concat(options);
      }, []);

      const queryParams = {
        page: tableQueryParams.page,
        limit: tableQueryParams.limit,
        companyClientId: clientId,
        sortCriteria: tableQueryParams.sortCriteria,
        filters: {
          nameSearchTerm: tableQueryParams.filters.nameSearchTerm,
          jobTitleSearchTerm: tableQueryParams.filters.jobTitleSearchTerm,
          lastInteraction: interactionTypes,
          mineOnly: false,
          showHidden: tableQueryParams.filters.showHidden,
        },
      };

      const response = await getCompanyRelationships(queryParams);

      if (response?.relationships?.length > 0) {
        const augmentedRelationships = response.relationships.map((relationship: any) => ({
          ...relationship,
          lastInteractionDoc: {
            ...relationship.lastInteractionDoc,
            name: relationship?.lastInteractionDoc?.contacts?.find((c: any) => c.name)?.name,
            title: relationship?.lastInteractionDoc?.contacts?.find((c: any) => c.title)?.title || '',
            company: relationship?.lastInteractionDoc?.contacts?.find((c: any) => c.company)?.company || '',
          },
        }));

        return {
          relationships: augmentedRelationships,
          total: response.total,
          clientId,
          page,
        };
      }

      return rejectWithValue('No relationships found');
    } catch (error) {
      console.error('Error fetching relationships:', error);
      return rejectWithValue('Failed to load relationships');
    }
  },
);

// Slice
const relationshipTableSlice = createSlice({
  name: 'relationshipTable',
  initialState: defaultRelationshipTableState,
  reducers: {
    setRelationshipTableFilterNameSearchTerm: (state, action: PayloadAction<string>) => {
      state.queryParams.filters.nameSearchTerm = action.payload;
    },
    setRelationshipTableFilterStages: (state, action: PayloadAction<Record<string, boolean>>) => {
      state.queryParams.filters.stages = action.payload;
    },
    setRelationshipTableFilterLastInteraction: (state, action: PayloadAction<Record<string, boolean>>) => {
      state.queryParams.filters.lastInteraction = action.payload;
    },
    setAllRelationshipTableFilterLastInteractionValues: (state, action: PayloadAction<boolean>) => {
      const newLastInteractionFilters = Object.keys(state.queryParams.filters.lastInteraction).reduce((acc, key) => {
        acc[key] = action.payload;
        return acc;
      }, {});
      state.queryParams.filters.lastInteraction = newLastInteractionFilters;
    },
    setRelationshipTableSortCriteria: (state, action: PayloadAction<{ key: string; direction: 'asc' | 'desc' }>) => {
      state.queryParams.sortCriteria = action.payload;
    },
    setRelationshipTableShowHidden: (state, action: PayloadAction<boolean>) => {
      state.queryParams.filters.showHidden = action.payload;
    },
    setRelationshipTablePage: (state, action: PayloadAction<number>) => {
      state.queryParams.page = action.payload;
    },
    setRelationshipTableTotal: (state, action: PayloadAction<number>) => {
      state.data.total = action.payload;
    },
    setRelationshipTableCompanyClientId: (state, action: PayloadAction<string | null>) => {
      state.queryParams.companyClientId = action.payload;
    },
    invalidateRelationshipTableCacheForAllPages: (state) => {
      state.data.cache = {};
    },
    invalidateRelationshipTableCacheForClient: (state, action: PayloadAction<string>) => {
      state.data.cache[action.payload] = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRelationships.pending, (state) => {
      state.loading.loading = true;
      state.loading.error = null;
    });
    builder.addCase(fetchRelationships.fulfilled, (state, action) => {
      const { relationships, total, clientId, page } = action.payload;
      if (action.payload.cached) {
        state.loading.loading = false;
        state.loading.error = null;
        state.data.total = state.data.cache[clientId]?.total || 0;
        return;
      }
      state.data.cache[clientId] = state.data.cache[clientId] || { total: 0 };
      state.data.cache[clientId][page] = relationships;
      state.data.cache[clientId].total = total;
      state.data.total = total;
      state.queryParams.companyClientId = clientId;
      state.loading.loading = false;
      state.loading.error = null;
    });
    builder.addCase(fetchRelationships.rejected, (state, action) => {
      state.loading.loading = false;
      state.loading.error = action.payload as string;
    });
  },
});

export const {
  setRelationshipTableFilterNameSearchTerm,
  setRelationshipTableFilterStages,
  setRelationshipTableFilterLastInteraction,
  setAllRelationshipTableFilterLastInteractionValues,
  setRelationshipTableSortCriteria,
  setRelationshipTableShowHidden,
  setRelationshipTablePage,
  setRelationshipTableTotal,
  setRelationshipTableCompanyClientId,
  invalidateRelationshipTableCacheForAllPages,
  invalidateRelationshipTableCacheForClient,
} = relationshipTableSlice.actions;

export default relationshipTableSlice.reducer;

export const resetAndFetch = (clientId: string) => (dispatch) => {
  dispatch(setRelationshipTablePage(1));
  dispatch(invalidateRelationshipTableCacheForAllPages());
  dispatch(fetchRelationships({ clientId }));
};

export const clearQueryParamsResetAndFetch = (clientId: string) => (dispatch) => {
  dispatch(setRelationshipTableFilterNameSearchTerm(''));
  dispatch(setAllRelationshipTableFilterLastInteractionValues(true));
  dispatch(resetAndFetch(clientId));
};

// Action Creators
export const changeRelationshipFilterStages = (clientId, stages) => (dispatch) => {
  dispatch(setRelationshipTableFilterStages(stages));
  resetAndFetch(clientId)(dispatch);
};

export const changeRelationshipFilterLastInteraction = (clientId, lastInteraction) => (dispatch) => {
  dispatch(setRelationshipTableFilterLastInteraction(lastInteraction));
  resetAndFetch(clientId)(dispatch);
};

export const changeRelationshipSortCriteria = (clientId, sortCriteria) => (dispatch) => {
  dispatch(setRelationshipTableSortCriteria(sortCriteria));
  resetAndFetch(clientId)(dispatch);
};

export const changeRelationshipShowHidden = (clientId, showHidden) => (dispatch) => {
  dispatch(setRelationshipTableShowHidden(showHidden));
  resetAndFetch(clientId)(dispatch);
};

export const changeRelationshipPage = (clientId: string, newPage: number) => (dispatch) => {
  dispatch(setRelationshipTablePage(newPage || 1));
  dispatch(fetchRelationships({ clientId }));
};

export const changeRelationshipFilterSearchTerm = (clientId: string, term: string) => (dispatch) => {
  dispatch(setRelationshipTableFilterNameSearchTerm(term));
  resetAndFetch(clientId)(dispatch);
};
