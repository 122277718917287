import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
// import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material';
import dayjs from 'dayjs';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import PCheckbox from '../library/PCheckbox';
import PSingleSearch from '@Library/PSingleSearch';

import Timing from './timing';
import EmailModal from './emailModal';
import ResponsibilityStockSalesModal from '@CommonScreens/ResponsibilityStockSalesModal';
import Http from '../../http/httpClient';
import ChooseGiftModal from './chooseGiftModal';
import PToolTip from '../../components/library/PToolTip';
import PDropdownVDeux from '@Library/PDropdownVDeux';
import IdealCustomerProfileModal from './IdealCustomerProfileModal';
import { Plus } from 'lucide-react';
import { Button } from '@/components/ui/button';
import ICPSelect from './ICPSelect';
import { Input } from '@/components/ui/input';
import { getIdealCustomerProfileList } from '@/helper/apiHelper';
import {
  TwitterStepIds,
  Milestones,
  StepFrequencies,
  StepRecurringTimings,
  StepSaleValues,
  Country,
  FeatureFlags,
  SEC_FILING_DESCRIPTIONS,
} from '@/constants/constant';
import { ReactComponent as Duplicate } from '../../assets/Icons/Duplicate.svg';
import { ReactComponent as Delete } from '../../assets/Icons/Delete.svg';
import { ReactComponent as ArrowDown } from '../../assets/Icons/Arrow-Down.svg';
import { ReactComponent as Check } from '../../assets/Icons/Check.svg';
import { ReactComponent as DefaultIcon } from '../../assets/Icons/AIStar.svg';
import { ReactComponent as Acquisition } from '../../assets/Icons/Acquisition.svg';
import { ReactComponent as Funding } from '../../assets/Icons/Funding.svg';
import { truncateText } from '../../helper/utilities';
import { Industries } from '@/constants/industriesConstant';
import PPriceRangeSelector from '../library/PPriceRangeSelector';
import { useIsFeatureFlagEnabled } from '@Hooks/useFeatureFlags';

export function TimingTypeDropdown(props) {
  // console.log("TimingTypeDropdown rendered")
  const theme = useTheme();
  const { step, timingStepType, setSequenceItemTimingType, setIsChecked, sequenceData } = props;
  const [anchorEl, setAnchorEl] = React.useState(timingStepType);
  const featureFlags = useSelector((state) => state.auth.featureFlags);
  const [subject, setSubject] = useState('');
  const handleSubjectChange = (event) => setSubject(event.target.value);

  // This fixes the issue when we edit the sequence VS create a new one.
  const sequenceName = sequenceData.sequenceName || sequenceData.template.type;

  const isFeatureEnabled = (featureName) => {
    return featureFlags.some((flag) => flag.name === featureName && flag.isFeatureEnabled);
  };

  const isMessageApprovalEnabled = !!featureFlags?.find(
    (featureFlag) => featureFlag.name === 'enableMessageApprovalCheckboxOption',
  )?.isFeatureEnabled;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (item, value) => {
    if (item !== 'Close') {
      if (isStepSaleValues(step)) {
        setSequenceItemTimingType({ label: item, value });
      } else {
        setSequenceItemTimingType(item);
      }
      setIsChecked(true);
    }
    setAnchorEl(null);
  };

  const isEvent = (step) => {
    if (step.id === 'checkin.recurring') {
      return false;
    } else if (step.id === 'linkedInEmail') {
      return false;
    } else if (step.milestone === Milestones.ACQUISITION) {
      return false;
    }
    // The id check is for legacy support; we'll remove it in the future once it is no longer impacting
    return (
      step.milestone ||
      ['gift', 'email'].includes(step.type) ||
      ['gift', 'meeting', 'followup', 'email'].includes(step.id)
    );
  };

  const isFrequencyItem = (step) => {
    return ['like', 'share', 'comment'].includes(step.id);
  };

  const isStepSaleValues = (step) => {
    return [Milestones.SEC144].includes(step.milestone);
  };

  const isStepFundzValues = (step) => {
    return [Milestones.FUNDING].includes(step.milestone);
  };

  const isRecurringItem = (step) => {
    return step.id === 'checkin.recurring';
  };

  const disableRecurringElements = step.fromEdit && step.id === 'checkin.recurring'; // Disable recurring elements if we are editing an existing sequence

  const showStep = () => {
    let showStep = true;

    if (step.milestone === Milestones.SEC144) {
      return true;
    }

    if (step.milestone === Milestones.FUNDING) {
      return true;
    }

    // Hide for follow steps
    showStep &=
      step.id !== 'connect' &&
      step.id !== 'follow' &&
      step.milestone !== Milestones.SEC144 &&
      step.milestone !== Milestones.FUNDING;

    // Hide for recurring steps on the edit sequence screen.
    showStep &= !disableRecurringElements;

    return showStep;
  };

  return (
    <div style={{ visibility: showStep() ? 'visible' : 'hidden' }}>
      {(isFrequencyItem(step) || isRecurringItem(step)) && (
        <PToolTip title="Frequency" disabled={disableRecurringElements}>
          <IconButton aria-label="options" onClick={handleClick}>
            <ArrowDown fill={disableRecurringElements ? /* light grey */ '#D8D8D8' : theme.palette.primary.grey} />
          </IconButton>
        </PToolTip>
      )}
      {isStepSaleValues(step) && (
        <PToolTip title="Sales Value">
          <IconButton aria-label="options" onClick={handleClick}>
            <ArrowDown fill={disableRecurringElements ? '#D8D8D8' : theme.palette.primary.grey} />
          </IconButton>
        </PToolTip>
      )}
      {isEvent(step) && step.milestone !== Milestones.SEC144 && step.milestone !== Milestones.FUNDING && (
        <PToolTip title="Event">
          <IconButton aria-label="options" onClick={handleClick}>
            <ArrowDown fill={theme.palette.primary.grey} />
          </IconButton>
        </PToolTip>
      )}
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => handleClose('Close')}
      >
        {isFrequencyItem(step) &&
          StepFrequencies?.map((frequency, index) => (
            <MenuItem
              key={index}
              sx={{
                color: theme.palette.primary.black,
                fontSize: '16px',
                fontWeight: 500,
              }}
              onClick={() => handleClose(frequency)}
            >
              <Check
                fill={step.frequency === frequency ? theme.palette.primary.black : theme.palette.primary.white}
                style={{ marginRight: 3 }}
              />
              {frequency}
            </MenuItem>
          ))}
        {isStepSaleValues(step) &&
          Object.entries(StepSaleValues).map(([label, value], index) => (
            <MenuItem
              key={index}
              sx={{
                color: theme.palette.primary.black,
                fontSize: '16px',
                fontWeight: 500,
              }}
              onClick={() => handleClose(label, value)}
            >
              <Check
                fill={step.saleValueGreaterThan === value ? theme.palette.primary.black : theme.palette.primary.white}
                style={{ marginRight: 3 }}
              />
              {label}
            </MenuItem>
          ))}

        {isRecurringItem(step) &&
          StepRecurringTimings?.map((frequency, index) => (
            <MenuItem
              key={index}
              sx={{
                color: theme.palette.primary.black,
                fontSize: '16px',
                fontWeight: 500,
              }}
              onClick={() => handleClose(frequency)}
            >
              <Check
                fill={step.frequency === frequency ? theme.palette.primary.black : /* transparent */ '#FFFFFF00'}
                style={{ marginRight: 3 }}
              />
              {frequency}
            </MenuItem>
          ))}
        {isEvent(step) && step.milestone !== Milestones.SEC144 && step.milestone !== Milestones.FUNDING && (
          <div>
            <MenuItem
              sx={{ color: theme.palette.primary.black, fontSize: '16px', fontWeight: 500 }}
              onClick={() => handleClose('Day')}
            >
              <Check
                fill={
                  step.day && step.day > 0 && !step.milestone && !step.frequency
                    ? theme.palette.primary.black
                    : theme.palette.primary.white
                }
                style={{ marginRight: 3 }}
              />
              Day
            </MenuItem>
            <Divider />
            <Typography sx={{ color: theme.palette.primary.grey, fontSize: '14px', fontWeight: 500, pl: 1 }}>
              Milestone
            </Typography>
            <MenuItem
              sx={{ color: theme.palette.primary.black, fontSize: '16px', fontWeight: 500 }}
              onClick={() => handleClose('Winter Holidays')}
            >
              <Check
                fill={step.milestone === 'Winter Holidays' ? theme.palette.primary.black : theme.palette.primary.white}
                style={{ marginRight: 3 }}
              />
              Winter Holidays
            </MenuItem>
            <Divider />
            <MenuItem
              sx={{ color: theme.palette.primary.black, fontSize: '16px', fontWeight: 500 }}
              onClick={() => handleClose('Custom Date')}
            >
              <Check
                fill={!step.customDate ? theme.palette.primary.white : theme.palette.primary.black}
                style={{ marginRight: 3 }}
              />
              Custom Date
            </MenuItem>
          </div>
        )}
      </Menu>
    </div>
  );
}

export default function SequenceStep(props) {
  // console.log("SequenceStep rendered")
  const theme = useTheme();
  const { index, step, stepDetail, setSequenceItem, isLoading, setIsLoading, steps, setupList, sequenceData } = props;
  const Icon = stepDetail?.icon || DefaultIcon;
  const [emailOpen, setEmailOpen] = React.useState(false);
  const [sec144Open, setSec144Open] = React.useState(false);
  const [giftModalOpen, setGiftModalOpen] = React.useState(false);
  const [currentStep, setCurrentStep] = React.useState(null);
  const [timingStepType, setTimingStepType] = React.useState(null);
  const [isChecked, setIsChecked] = React.useState(false);
  const [isApprovalRequired, setIsApprovalRequired] = React.useState(step.approveRequired);
  const idealCustomerProfileEnabled = useIsFeatureFlagEnabled('ui.idealCustomerProfile');
  const [isICPModalOpen, setIsICPModalOpen] = useState(false);
  const [icpFetchTrigger, setIcpFetchTrigger] = useState(false);
  //Feature flags
  const featureFlags = useSelector((state) => state.auth.featureFlags);
  const geographicalFeatureFlag = featureFlags?.find(
    (featureFlag) => featureFlag.name === FeatureFlags.FUNDING_GEOGRAPHICAL,
  )?.isFeatureEnabled;
  const isMessageApprovalEnabled = !!featureFlags?.find(
    (featureFlag) => featureFlag.name === 'enableMessageApprovalCheckboxOption',
  )?.isFeatureEnabled;

  // here is one thing to look at
  const isEmail = (step) => {
    // The id check is for legacy support; we'll remove it in the future once it is no longer impacting
    return step.type === 'email' || ['meeting', 'followup', 'email', 'checkin.recurring'].includes(step.id);
  };

  const isNotAcquisition = (step) => !['Acquisition'].includes(step.milestone);

  const isDraftable = (step) => {
    return (
      step.milestone !== Milestones.AWARDS &&
      isNotAcquisition(step) &&
      isEmail(step) &&
      !step.emailTemplate &&
      !isLoading
    );
  };

  const showSeeEmailLabel = (step) => {
    return (
      isNotAcquisition(step) &&
      isEmail(step) &&
      step.emailTemplate &&
      !step.emailTemplate.genReqFailed &&
      !step.emailTemplate.requestDraft
    );
  };

  const hasMultipleEmails = (step) => {
    return step.id === 'checkin.recurring' && step.frequency !== 'Once a year';
  };

  const giftWasChosen = (step) => {
    return step.type === 'gift' && step.emailTemplate?.giftName;
  };

  const showAskApprovalLabel = (step) => {
    if (!isMessageApprovalEnabled) return; // If the feature flag is enabled, skip, we don't need to show the checkbox, all checkboxes are default to approval required

    const isApprovalRequired = () =>
      (!step.emailTemplate?.genReqFailed && !step.emailTemplate?.approval && !step.emailTemplate?.requestDraft) ||
      step.milestone === 'Acquisition' ||
      step.milestone === 'Funding';

    // For social
    if (step.approval && step.id === 'comment') return true;

    // For email types and gifts
    if (isEmail(step) || step.type === 'gift') return isApprovalRequired();

    return false;
  };

  const showDraftingEmailLabel = (step) => {
    if (!isNotAcquisition(step)) {
      return false;
    }
    if (isLoading && step.emailTemplate?.requestDraft) {
      if (isEmail(step)) return true;
      if (giftWasChosen(step)) return true;
    }
    return false;
  };

  const [icps, setIcps] = useState([]);
  useEffect(() => {
    const fetchICPs = async () => {
      try {
        const fetchedProfiles = await getIdealCustomerProfileList();

        if (Array.isArray(fetchedProfiles)) {
          const uniqueICPs = fetchedProfiles.filter(
            (profile, index, self) => index === self.findIndex((p) => p._id === profile._id && p.name === profile.name),
          );

          setIcps(uniqueICPs);
        }
      } catch (error) {
        console.error('Error fetching ICPs:', error);
      }
    };

    fetchICPs();
  }, []);

  const handleAddICP = () => {
    setIsICPModalOpen(true);
  };
  React.useEffect(() => {
    if (!timingStepType) return;
    if (typeof timingStepType === 'object' && timingStepType.value === step.saleValueGreaterThan) return;
    if (timingStepType === step.milestone) return;

    let modifiedStep = null;
    switch (typeof timingStepType === 'object' ? 'StepSaleValues' : timingStepType) {
      case 'StepSaleValues':
        modifiedStep = {
          ...step,
          saleValueGreaterThan: timingStepType.value,
          day: null,
          frequency: null,
          occasion: null,
          milestone: Milestones.SEC144,
          customDate: null,
          approveRequired: isApprovalRequired,
          stepSaleValues: timingStepType.label,
        };
        break;
      case 'Day':
        if (step.day && step.day > 0 && !step.milestone && !step.customDate && !step.frequency) {
          return;
        } // handling case if we click again same timing

        let maxStepDay = 0;
        for (const step of steps) {
          if (step.milestone || step.customDate || step.frequency) continue;

          if (parseInt(step.day) > maxStepDay) {
            maxStepDay = parseInt(step.day);
          }
        }
        modifiedStep = {
          ...step,
          day: maxStepDay + 1,
          frequency: null,
          occasion: null,
          milestone: null,
          customDate: null,
          approveRequired: isApprovalRequired,
        };
        break;
      case 'Birthday':
      case 'Anniversary':
      case 'Funding announcement gift':
      case 'Funding announcement email':
      case 'Acquisition announcement gift':
      case 'Acquisition announcement email':
      case 'Contract Renewal':
      case 'Winter Holidays':
        modifiedStep = {
          ...step,
          day: 0,
          frequency: null,
          occasion: 'on',
          milestone: timingStepType,
          customDate: null,
          approveRequired: isApprovalRequired,
        };
        break;
      case 'Custom Date':
        modifiedStep = {
          ...step,
          day: null,
          frequency: null,
          occasion: null,
          milestone: null,
          customDate: dayjs().add(1, 'day').startOf('day').format(),
          approveRequired: isApprovalRequired,
        };
        break;
      case 'Rarely':
      case 'Sometimes':
      case 'Often':
        modifiedStep = {
          ...step,
          frequency: timingStepType,
          approveRequired: isApprovalRequired,
        };
        break;
      case 'Each week':
      case 'Each month':
      case 'Each quarter':
      case 'Once a year':
      case '2 times a year':
      case '3 times a year':
      case '4 times a year':
      case '5 times a year':
      case '6 times a year':
      case 'Every 2 weeks':
      case 'Once a month':
      case 'Every quarter':
      case 'Twice a year':
        modifiedStep = {
          ...step,
          frequency: timingStepType,
          approveRequired: isApprovalRequired,
        };
        break;
      default:
    }

    if (modifiedStep) {
      let backupTemplate = { ...modifiedStep.emailTemplate };
      if (modifiedStep.id !== 'checkin.recurring') {
        modifiedStep.emailTemplate = null; // FUTURE: Consider using a backup copy if switching back to the last kind
      }

      // This helps address a usability problem where changing the event type is always forcing to re-choose a gift
      if (modifiedStep.type === 'gift') {
        modifiedStep.emailTemplate = {
          giftDetails: backupTemplate.giftDetails,
          giftName: backupTemplate.giftName,
          giftPrice: backupTemplate.giftPrice,
        };
      }

      setSequenceItem(index, modifiedStep);
    }
    setTimingStepType(null);
  }, [timingStepType]);

  const retryEmailGenerate = (step) => {
    setIsLoading(true);
    step.emailTemplate ? (step.emailTemplate.requestDraft = true) : (step.emailTemplate = { requestDraft: true });

    const req = { seqEmails: [step] };
    Http.postData('generateSequenceEmail', req)
      .then(function (response) {
        if (response[0]) {
          const item = response[0];
          step.emailTemplate = {
            subjectLine: item.subject,
            emailBody: item.email,
            aiPrompt: item.prompt,
            genReqFailed: item.genReqFailed,
            requestDraft: false,
          };
        }
        setIsLoading(false); // TODO: don't set it to false while others are still loading
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const closeEmail = () => {
    setEmailOpen(false);
  };
  const closeSec144 = () => {
    setSec144Open(false);
  };

  const closeGiftModal = () => {
    setGiftModalOpen(false);
  };

  const handleSequenceItemTiming = (step) => {
    setSequenceItem(index, step);
  };

  const handleSequenceItemTimingType = (timingType) => {
    setTimingStepType(timingType);
  };

  const handleCheckedStep = (step) => {
    setSequenceItem(index, step, 'checked');
    setIsChecked(false);
  };

  const emailSubject = (step) => {
    return step.emailTemplate?.subject ?? step.emailTemplate?.subjectLine;
  };

  const showTimingDropdown = (step) => {
    return ['like', 'share', 'comment', 'checkin.recurring'].includes(step.id) || step.milestone === Milestones.SEC144;
  };

  const subjectLabel = (step) => {
    if (['Acquisition'].includes(step.milestone)) {
      return '';
    }
    let label = truncateText(emailSubject(step), 30) || '';
    if (label && !step.emailTemplate?.reviewed) label += '· ';
    if (!step.emailTemplate?.genReqFailed && !step.emailTemplate?.reviewed && !step.emailTemplate?.requestDraft)
      label += 'Drafted by AI';
    return label;
  };

  const handleApprovalChange = (event) => {
    const isChecked = event.target.checked;
    setIsApprovalRequired((prev) => isChecked);

    let modifiedStep = { ...step, approveRequired: isChecked };
    setSequenceItem(index, modifiedStep);
  };

  React.useEffect(() => {
    setIsApprovalRequired((prev) => step.approveRequired);
  }, [step.approveRequired]);

  const getStepHeader = () => {
    if (step.id === 'followup') {
      if (step?.milestone === 'Job Change') return 'Job change email';
      if (step?.milestone === 'Job Promotion') return 'Job promotion email';
      if (step?.milestone === 'New Certification') return 'New certification email';
      if (step?.milestone === SEC_FILING_DESCRIPTIONS.SEC144) return SEC_FILING_DESCRIPTIONS.SEC144;
      if (step?.milestone === SEC_FILING_DESCRIPTIONS.SEC13D) return SEC_FILING_DESCRIPTIONS.SEC13D;
      if (step?.milestone === Milestones.AWARDS) return 'Recognize awards';
      if (step?.milestone === Milestones.FUNDING) return 'Funding email';
    }

    return step.desc;
  };

  // Handling multiple selections for geographical regions
  const [selectedRegions, setSelectedRegions] = useState(step.fundzGeographical || []);

  const handleDropdownChange = useCallback(
    (selectedLabel) => {
      const newSelectedRegions = selectedRegions.find((r) => r.label === selectedLabel)
        ? selectedRegions.filter((r) => r.label !== selectedLabel)
        : [...selectedRegions, Country.find((r) => r.label === selectedLabel)];

      setSelectedRegions(newSelectedRegions);

      // Extract labels from the selected regions
      const geographicalLabels = newSelectedRegions.map((region) => region.label);

      // Update the sequenceItem with the new selectedRegions, preserving other properties
      setSequenceItem(index, {
        ...step,
        fundzGeographical: geographicalLabels,
        fundzIndustry: step.fundzIndustry,
        idealCustomerProfileId: step.idealCustomerProfileId,
      });
    },
    [selectedRegions, step, index, setSequenceItem],
  );

  const shouldShowPersonalizeCheckbox = (step) => {
    const mileStonesToBePersonalized = [Milestones.SEC13D];

    return (
      step.id === 'linkedInEmail' ||
      step.id === 'meeting' ||
      (step.id === 'followup' && mileStonesToBePersonalized.includes(step.milestone))
    );
  };

  return (
    <>
      <Container
        style={{ paddingLeft: '8px', paddingRight: '8px' }}
        sx={{
          backgroundColor: theme.palette.primary.white,
          py: 2,
          my: 2,
          width: '110%',
          borderRadius: 3,
          border: '1px solid #E0E0E0',
        }}
      >
        <Grid container alignItems="center" spacing={1} sx={{ pl: 1 }}>
          <Grid item xs={4} sx={{ pl: 1 }}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Avatar
                variant="square"
                sx={{
                  borderRadius: 3,
                  backgroundColor: theme.palette.primary.lightGrey,
                }}
              >
                <Icon fill={theme.palette.primary.black} />
              </Avatar>

              <Stack>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 700,
                    color: theme.palette.primary.black,
                  }}
                  title={step.desc}
                >
                  {getStepHeader()}
                </Typography>
                {shouldShowPersonalizeCheckbox(step) && (
                  <Box sx={{ pl: '10px' }}>
                    <FormControlLabel
                      control={
                        <PCheckbox
                          checked={step.isPersonalized}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            const updatedStep = { ...step, isPersonalized: isChecked };
                            setSequenceItem(index, updatedStep);
                          }}
                        />
                      }
                      label="Personalize this email"
                      sx={{
                        '& .MuiTypography-root': {
                          color: theme.palette.primary.black,
                          fontSize: '12px',
                          fontWeight: 500,
                        },
                        'cursor': 'default',
                      }}
                    />
                  </Box>
                )}
                {isDraftable(step) && (
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: 500,
                      color: theme.palette.primary.black,
                    }}
                  >
                    Drafted by AI
                  </Typography>
                )}

                {isDraftable(step) && (
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: 500,
                      color: theme.palette.primary.grey,
                    }}
                  >
                    See the email after pressing 'draft'
                  </Typography>
                )}

                {step.milestone !== Milestones.AWARDS && showDraftingEmailLabel(step) && (
                  <Box display="flex" alignItems="center">
                    {!step.emailTemplate?.reviewed && (
                      <Typography
                        sx={{
                          fontSize: '12px',
                          fontWeight: 500,
                          color: theme.palette.primary.orange,
                        }}
                      >
                        Drafting email...
                      </Typography>
                    )}
                  </Box>
                )}
                {step.milestone !== Milestones.AWARDS && (step.emailTemplate || step.approval) && (
                  <>
                    {step.emailTemplate?.genReqFailed && !step.emailTemplate?.requestDraft && (
                      <Typography
                        sx={{
                          fontSize: '12px',
                          fontWeight: 500,
                          color: theme.palette.primary.orange,
                          mb: 1,
                        }}
                      >
                        Something went wrong generating the email.
                      </Typography>
                    )}
                    <Box display="flex" alignItems="center" flexWrap={'wrap'}>
                      {(step.ai || step.fromEdit) &&
                        step.emailTemplate &&
                        step.emailTemplate.emailBody &&
                        step.id !== 'gift' && (
                          <Typography
                            sx={{
                              fontSize: '12px',
                              fontWeight: 500,
                              color: theme.palette.primary.black,
                            }}
                          >
                            {subjectLabel(step)}
                          </Typography>
                        )}

                      {showSeeEmailLabel(step) && (
                        <Typography
                          onClick={() => {
                            setCurrentStep(step);
                            setEmailOpen(true);
                          }}
                          style={{ visibility: isLoading ? 'hidden' : 'visible' }}
                          sx={{
                            fontSize: '12px',
                            fontWeight: 500,
                            color: theme.palette.primary.blue,
                            marginLeft: 1,
                            cursor: 'pointer',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '100%',
                          }}
                        >
                          · {hasMultipleEmails(step) ? 'See emails' : 'See email'}
                        </Typography>
                      )}

                      {isEmail(step) && step.emailTemplate?.genReqFailed && !step.emailTemplate?.requestDraft && (
                        <>
                          <Button
                            onClick={() => {
                              retryEmailGenerate(step);
                            }}
                            sx={{
                              'backgroundColor': theme.palette.primary.orange,
                              'color': theme.palette.primary.white,
                              'textTransform': 'none',
                              'py': 1,
                              'px': 2,
                              'mr': 1,
                              'whiteSpace': 'nowrap',
                              'overflow': 'hidden',
                              'textOverflow': 'ellipsis',
                              'borderRadius': 3,
                              '&:hover': {
                                backgroundColor: theme.palette.primary.orange,
                                color: theme.palette.primary.white,
                                borderColor: theme.palette.primary.orange,
                              },
                            }}
                          >
                            Try again
                          </Button>
                          <Button
                            onClick={() => {
                              setCurrentStep(step);
                              setEmailOpen(true);
                            }}
                            sx={{
                              'backgroundColor': theme.palette.primary.white,
                              'color': theme.palette.primary.orange,
                              'border': '1px solid',
                              'borderColor': theme.palette.primary.orange,
                              'textTransform': 'none',
                              'py': 1,
                              'px': 2,
                              'whiteSpace': 'nowrap',
                              'overflow': 'hidden',
                              'textOverflow': 'ellipsis',
                              'borderRadius': 3,
                              '&:hover': {
                                backgroundColor: theme.palette.primary.orange,
                                color: theme.palette.primary.white,
                                borderColor: theme.palette.primary.orange,
                              },
                            }}
                          >
                            Write your own
                          </Button>
                        </>
                      )}
                    </Box>

                    <Box display="flex" alignItems="center">
                      {step.emailTemplate?.giftName && (
                        <Typography
                          sx={{
                            fontSize: '12px',
                            fontWeight: 500,
                            color: theme.palette.primary.black,
                          }}
                        >
                          {`${step.emailTemplate?.giftName} · $${step.emailTemplate?.giftPrice}`}
                        </Typography>
                      )}
                      {giftWasChosen(step) && (
                        <Typography
                          onClick={() => {
                            setCurrentStep(step);
                            setGiftModalOpen(true);
                          }}
                          sx={{
                            fontSize: '12px',
                            fontWeight: 500,
                            color: theme.palette.primary.blue,
                            marginLeft: 1,
                            cursor: 'pointer',
                          }}
                        >
                          · Change gift
                        </Typography>
                      )}

                      {giftWasChosen(step) && step.emailTemplate && (
                        <Typography
                          onClick={() => {
                            setCurrentStep(step);
                            setEmailOpen(true);
                          }}
                          sx={{
                            fontSize: '12px',
                            fontWeight: 500,
                            color: theme.palette.primary.blue,
                            marginLeft: 1,
                            cursor: 'pointer',
                          }}
                        >
                          · {hasMultipleEmails(step) ? 'See emails' : 'See email'}
                        </Typography>
                      )}
                    </Box>
                  </>
                )}

                {showAskApprovalLabel(step) && (
                  <div
                    style={{
                      paddingLeft: '10px',
                    }}
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={<PCheckbox checked={isApprovalRequired} onChange={handleApprovalChange} />}
                        label="Approve before sending"
                        sx={{
                          '& .MuiTypography-root': {
                            color: theme.palette.primary.black,
                            fontSize: '12px',
                            fontWeight: 500,
                          },
                          'cursor': 'default',
                        }}
                      />
                    </FormGroup>
                  </div>
                )}
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={8} sx={{ '&.MuiGrid-item': { paddingLeft: '8px' } }}>
            <Grid container direction="column" spacing={0}>
              {/* First Row: Existing Content */}
              <Grid item>
                <Box display="flex" alignItems="center" justifyContent="flex-end" width="100%">
                  {/* Restored section: Conditional rendering for 'Choose Gift' button */}
                  {step.type === 'gift' && !step.emailTemplate?.giftName ? (
                    <Button
                      onClick={() => {
                        setCurrentStep(step);
                        setGiftModalOpen(true);
                      }}
                      sx={{
                        'backgroundColor': theme.palette.primary.blue,
                        'color': theme.palette.primary.white,
                        'border': '1px solid',
                        'borderColor': theme.palette.primary.blue,
                        'textTransform': 'none',
                        'fontWeight': 500,
                        'fontSize': '14px',
                        'py': 1,
                        'px': 2,
                        'mx': 0,
                        'borderRadius': 2,
                        '&:hover': {
                          backgroundColor: theme.palette.primary.blue,
                          color: theme.palette.primary.white,
                        },
                      }}
                    >
                      Choose Gift
                    </Button>
                  ) : (
                    <>
                      {Boolean(step.milestone === Milestones.FUNDING) && (
                        <Box sx={{ my: 1.5 }}>
                          <PSingleSearch
                            options={Industries}
                            value={step.fundzIndustry}
                            placeholder="Select Industry"
                            onChange={(value) => setSequenceItem(index, { ...step, fundzIndustry: value })}
                            pSize={!geographicalFeatureFlag ? 'small' : ''}
                          />
                        </Box>
                      )}
                      {[Milestones.AWARDS].includes(step?.milestone) && (
                        <Box className="mr-2">
                          <Input
                            type="text"
                            placeholder="Award Name / Title"
                            value={step.awardNameFilter || ''}
                            onChange={(event) => {
                              const awardName = event.target.value;
                              const updatedStep = { ...step, awardNameFilter: awardName }; // Update awardNameFilter
                              setSequenceItem(index, updatedStep);
                            }}
                          />
                        </Box>
                      )}
                      {[Milestones.AWARDS, Milestones.SEC144].includes(step?.milestone) &&
                        idealCustomerProfileEnabled && (
                          <ICPSelect
                            onAddICP={handleAddICP}
                            addICPModalOpen={isICPModalOpen}
                            setAddICPModalOpen={setIsICPModalOpen}
                            icpFetchTrigger={icpFetchTrigger}
                            onSelectICP={(selectedICP) => {
                              const updatedStep = { ...step, idealCustomerProfileId: selectedICP };
                              setSequenceItem(index, updatedStep);
                            }}
                            step={step}
                          />
                        )}

                      {Boolean(geographicalFeatureFlag && step.milestone === Milestones.FUNDING) && (
                        <Box sx={{ my: 1.5, mr: 1, width: '100%' }}>
                          <PDropdownVDeux
                            pVariant="grey"
                            displayAs="text"
                            buttonText="Select Geographical Region"
                            inputValue={selectedRegions.map((region) => region.label).join(', ')}
                            menu={{
                              menuList: Country.map((region) => ({
                                isEnabled: true,
                                label: region.label,
                                onSubmit: () => handleDropdownChange(region.label),
                                isSelected: selectedRegions.some(
                                  (selectedRegion) => selectedRegion.label === region.label,
                                ),
                              })),
                            }}
                            multiple={true}
                            closeWhenClicked={true}
                          />
                        </Box>
                      )}
                      <Timing
                        step={step}
                        setSequenceItemTiming={handleSequenceItemTiming}
                        setIsChecked={setIsChecked}
                      />
                      {showTimingDropdown(step) && (
                        <TimingTypeDropdown
                          step={step}
                          timingStepType={timingStepType}
                          setSequenceItemTimingType={handleSequenceItemTimingType}
                          setIsChecked={setIsChecked}
                          sequenceData={sequenceData}
                        />
                      )}
                      {((!TwitterStepIds.includes(step.id) &&
                        step.id !== 'checkin.recurring' &&
                        Number.isInteger(step.day)) ||
                        step.customDate) &&
                        isChecked && (
                          <PToolTip title="Save">
                            <IconButton
                              onClick={() => {
                                handleCheckedStep(step);
                              }}
                            >
                              <Box
                                display="flex"
                                width="10px"
                                height="10px"
                                overflow="hidden"
                                alignItems="center"
                                justifyContent="center"
                              >
                                <Check
                                  fill={theme.palette.primary.grey}
                                  style={{ maxWidth: '100%', maxHeight: '100%' }}
                                />
                              </Box>
                            </IconButton>
                          </PToolTip>
                        )}
                    </>
                  )}
                  {/* Ensure the Remove button is always rendered */}
                  <PToolTip title="Remove">
                    <IconButton onClick={() => setSequenceItem(index, step, 'delete')}>
                      <Box
                        display="flex"
                        width="10px"
                        height="10px"
                        overflow="hidden"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Delete fill={theme.palette.primary.grey} style={{ maxWidth: '100%', maxHeight: '100%' }} />
                      </Box>
                    </IconButton>
                  </PToolTip>
                </Box>
              </Grid>

              {/* Second Row: Revenue Price Range Selector for Fundz */}
              {Boolean(step.milestone === Milestones.FUNDING) && (
                <Grid item>
                  <Box>
                    <PPriceRangeSelector
                      initialOption={step.fundzValueOption}
                      geoEnabled={geographicalFeatureFlag}
                      amount1={step.fundzValueGreaterThan ?? step.fundzValueLessThan ?? ''}
                      amount2={step.fundzValueLessThan ?? ''}
                      onChange={(option, amount1, amount2) => {
                        const modifiedStep = { ...step };

                        modifiedStep.fundzValueOption = option;

                        if (option === 'greater') {
                          modifiedStep.fundzValueGreaterThan = amount1;
                          modifiedStep.fundzValueLessThan = null;
                        } else if (option === 'less') {
                          modifiedStep.fundzValueLessThan = amount1;
                          modifiedStep.fundzValueGreaterThan = null;
                        } else if (option === 'between') {
                          modifiedStep.fundzValueGreaterThan = amount1;
                          modifiedStep.fundzValueLessThan = amount2;
                        } else {
                          modifiedStep.fundzValueGreaterThan = null;
                          modifiedStep.fundzValueLessThan = null;
                        }
                        setSequenceItem(index, modifiedStep);
                      }}
                    />
                  </Box>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
      {emailOpen && <EmailModal open={emailOpen} onClose={closeEmail} step={currentStep} setupList={setupList} />}
      {sec144Open && (
        <ResponsibilityStockSalesModal
          open={sec144Open}
          onClose={closeSec144}
          step={currentStep}
          setupList={setupList}
        />
      )}
      {giftModalOpen && <ChooseGiftModal open={giftModalOpen} onClose={closeGiftModal} step={currentStep} />}

      {isICPModalOpen && (
        <IdealCustomerProfileModal
          open={isICPModalOpen}
          onClose={() => {
            setIsICPModalOpen(false);
            setIcpFetchTrigger((prev) => !prev);
          }}
          onSave={(newProfileId) => {
            const modifiedStep = { ...step, idealCustomerProfileId: newProfileId };
            setSequenceItem(index, modifiedStep);
          }}
        />
      )}
    </>
  );
}
