//made in a rush :)

const POST_SOURCES = Object.freeze({
  TWITTER: 'twitter',
  LINKEDIN_COMMENT: 'linkedin-comment',
  SEQUENCE: 'sequence',
  CHROME_EXTENSION: 'chrome-extension',
  AI_NOTETAKER: 'ai-notetaker',
  ERM: 'ERM',
});

module.exports = {
  POST_SOURCES,
};
